/* eslint-disable jsx-a11y/alt-text */
import { Dialog } from "@headlessui/react";
import { icons8crossFilled, priceIcon, serviceIcon } from "../../../assets";
import PrimaryButton from "../../utils/buttons/primaryButton";
import { useEffect, useState } from "react";
import axiosInstance, { notifyError, notifySuccess } from "../../../utility/axiosInstance";
import { toast } from "react-toastify";

const ParkingLotModal = ({ info, setInfo, showHidePL, setShowHidePL }) => {
  const [enableBooking, setEnableBooking] = useState(true); // For enabling booking
  const [loading, setLoading] = useState(false); // For enabling booking
  const [lotSize, setLotSize] = useState({ parkingLot: 1, rampLot: 1 });
  // console.log("lotSize", lotSize);
  useEffect(() => {
    if (info?.parkingLot) {
      // console.log("info", info);
      const { availableStatus, parkingLotSize,rampLotSize } = info.parkingLot;

      setLotSize((state) => ({ ...state,rampLotSize:rampLotSize, parkingLot: parkingLotSize ,}));
      setEnableBooking(availableStatus);
    }
  }, [info]);

  const handleUpdatePKL = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.put(`services/UpdateServiceInfo`, {
        availableStatus: enableBooking,
        parkingLotSize: lotSize.parkingLot,
        rampLotSize:lotSize.rampLot
      });
      if (response?.status === 200) {
        notifySuccess("Edit slot updated Successfully")
      }
      console.log(response);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <Dialog
      open={showHidePL}
      onClose={() => {
        setShowHidePL(false);
        // resetForm();
      }}
      className="relative z-50"
    >
      <div className="fixed inset-0 bg-black/80 flex w-screen items-center justify-center p-4">
        <Dialog.Panel className="w-full flex justify-center rounded">
          <div className="h-auto w-[550px] xl:w-[750px] relative rounded-3xl bg-white px-5 py-6">
            <div className="">
              <div className="p-2">
                <img
                  src={icons8crossFilled}
                  onClick={() => {
                    setShowHidePL(false);
                    // resetForm();
                  }}
                  className="w-8 h-8 absolute flex justify-center items-center right-2 top-2 rounded-full cursor-pointer"
                />
              </div>
              <div className="pt-2 px-3">
                <div className="p-5 flex flex-col gap-5">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Parking Slot
                    </label>
                    <div className="relative">
                      <input
                        className="border-[1px] w-full h-[50px] mt-1 transition bg-white ease-in-out delay-150 border-[#ccc] truncate leading-[19.07px] text-gray-600 pr-[50px] text-lg py-[8px] px-2 lg:pl-3 rounded-md focus:outline-none focus:border-blue-300"
                        type="number"
                        value={lotSize.parkingLot}
                        onChange={(e) =>
                          setLotSize((state) => ({
                            ...state,
                            parkingLot: e.target.value >=1 ? e.target.value:1,
                          }))
                        }
                      />
                      <div className="absolute right-5 top-5">
                        <img src={serviceIcon} className="h-5 w-5" alt="" />
                      </div>
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Ramp Slot
                    </label>
                    <div className="relative">
                      <input
                        className="border-[1px] w-full h-[50px] mt-1 transition bg-white ease-in-out delay-150 border-[#ccc] truncate leading-[19.07px] text-gray-600 pr-[50px] text-lg py-[8px] px-2 lg:pl-3 rounded-md focus:outline-none focus:border-blue-300"
                        type="number"
                        value={lotSize.rampLot}
                        onChange={(e) =>
                          setLotSize((state) => ({
                            ...state,
                            rampLot:  e.target.value >=1 ? e.target.value:1,
                          }))
                        }
                      />
                      <div className="absolute right-5 top-5">
                        <img src={serviceIcon} className="h-5 w-5" alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="relative">
                    <div className="flex justify-between">
                      <label className="block text-sm font-medium text-gray-700">
                        Enable for Booking
                      </label>
                      <input
                        type="checkbox"
                        checked={enableBooking}
                        onChange={(e) => setEnableBooking(e.target.checked)}
                        className="mt-1 block w-6 h-6 text-blue-600 border-gray-300 rounded focus:ring-blue-500 focus:border-blue-500"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex justify-center py-5">
                  <PrimaryButton
                    width="150px"
                    title={"Update"} // Update button title based on mode
                    onClick={handleUpdatePKL}
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default ParkingLotModal;
