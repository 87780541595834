import React, { useEffect, useState } from "react";
import axiosInstance, {
  notifyError,
  notifySuccess,
  notifyWarning,
} from "../../utility/axiosInstance";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import AcceptButton from "../staticButtons/acceptButton";
import RejectButton from "../staticButtons/rejectButton";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

import {
  leftIcon,
  rightIcon,
  disabledLeftIcon,
  disabledRightIcon,
} from "../../assets";
import {
  getCurrentUser,
  serviceRequests,
  viewProfile,
} from "../../utility/api";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";

import useSocket from "../../hooks/useSocket";

import {
  fetchNotifications,
  clearNotifications,
} from "../../redux/notificationsThunk";
import RejectionModel from "./component/RejectionModel";
import BookingModal from "./component/BookingModal";
import { fetchServices } from "../../redux/serviceThunk";
import { AiOutlineLoading } from "react-icons/ai";

const fetchPeople = async () => {
  try {
    const response = await axiosInstance.get(
      serviceRequests.getAllServiceRequest
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data", error);
    return [];
  }
};

const ServiceRequestTable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });
  const [sorting, setSorting] = useState([]);
  const [serviceStatus, setServiceStatus] = useState({
    isActive: false,
    data: "",
    _id: "",
  });
  const [isBookModal, setBookModal] = useState(false);
  const [isReject, setIsReject] = useState(false);
  const dispatch = useDispatch();
  const searchTabs = useSelector((state) => state.search);
  const [currentStepInfo, setCurrentStepInfo] = useState(0);

  const currentStep = (info) => {
    setCurrentStepInfo(info);
  };
  const fetchData = async () => {
    setLoading(true);
    const allData = await fetchPeople();
    const searchText = searchTabs.searchText.toLowerCase();
    const filteredData = allData.filter((item) => {
      const fullName =
        `${item.user.firstName} ${item.user.lastName}`.toLowerCase();
      return (
        fullName?.includes(searchText) ||
        item?.user?.email.toLowerCase().includes(searchText) ||
        item?.service?.serviceName.toLowerCase().includes(searchText)
      );
    });
    const currentDate = new Date();
    // console.log(currentDate.setHours(0, 0, 0, 0));
    // const pendingData = filteredData.filter(({status,requestedAt}) => {
    // //  console.log("requestedAt",new Date(requestedAt).setHours(0,0,0,0));
    // //  new Date(requestedAt).setHours(0,0,0,0)
    // //  if(){

    // //   }
    //  return (status === "pending")
    // });
    setData(filteredData);
    console.log(filteredData);
    // setData([]);
    setLoading(false);
  };
  // console.log(data);
  // useSocket();

  // const { list } = useSelector((state) => state.notifications);
  const serviceRequests = useSelector((state) => state.services.serviceRequest);

  useEffect(() => {
    fetchData();
    // dispatch(fetchServices());
    // dispatch(fetchNotifications());
  }, [dispatch, searchTabs.searchText, serviceRequests]);

  // useEffect(() => {
  //   fetchData();
  //   // dispatch(fetchServices());
  // }, [dispatch, serviceRequests]);

  const handleSwitchStatus = async (info, status) => {
    try {
      const response = await axiosInstance.put(
        `${serviceRequests.updateServiceRequest}/${info.row.original._id}`,
        {
          status: status ? "accepted" : "rejected",
        }
      );
      if (response.status === 200) {
        if (response.data?.status === "accepted") {
          notifySuccess("Service request has been accepted");
        } else {
          notifyWarning("Service request has been rejected");
        }
        await fetchData();
      } else {
        console.error("Error updating status", response);
      }
    } catch (error) {
      notifyError(`Error updating status ${error}`);
    }
  };

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor("_id", {
      cell: (info) => info.row.index + 1,
      header: "Sr no.",
    }),
    columnHelper.accessor(
      (row) => row.user.firstName + " " + row.user.lastName,
      {
        header: () => "Name",
        id: "name",
        cell: (info) => <i>{info.getValue()}</i>,
      }
    ),
    columnHelper.accessor((row) => row.requestedAt.date, {
      header: () => "Booking Date",
      id: "bookingDate",
      cell: (info) => <i>{new Date(info.getValue()).toLocaleDateString()}</i>,
    }),
    columnHelper.accessor((row) => row?.car?.brand + " " + row?.car?.model, {
      header: () => "Car",
      id: "serviceName",
      cell: (info) => <i>{info.getValue()}</i>,
    }),
    columnHelper.accessor((row) => row?.bookingType, {
      header: () => "Booking Type",
      id: "bookingType",
      cell: (info) => <i>{info.getValue()}</i>,
    }),
    columnHelper.accessor("status", {
      header: () => "Status",
      cell: (info) => (
        <div className="flex gap-3 justify-center">
          {info.getValue() === "accepted" || info.getValue() === "completed" ? (
            <i
              style={{ cursor: "pointer" }}
              onClick={() => {
                setServiceStatus((state) => ({
                  ...state,
                  _id: info.row.original._id,
                }));
                setBookModal(true);
              }}
            >
              {info.getValue() === "completed"
                ? "Completed"
                : info.getValue() === "accepted" &&
                  info.row.original.requestedAt.status === "scheduled"
                ? info.row.original.payment.paymentStatus === "requested"
                  ? "Payment Requested"
                  : "Accepted"
                : "Rescheduled"}
            </i>
          ) : info.getValue() === "rejected" ? (
            <span>Rejected</span>
          ) : (
            <>
              <AcceptButton
                accept={() => {
                  setServiceStatus((state) => ({
                    ...state,
                    _id: info.row.original._id,
                  }));
                  setBookModal(true);
                  // handleSwitchStatus(info, true);
                }}
              />
              <RejectButton
                reject={() => {
                  console.log(info.row.original._id);
                  setServiceStatus((state) => ({
                    ...state,
                    _id: info.row.original._id,
                  }));
                  setIsReject(true);
                }}
              />
            </>
          )}
        </div>
      ),
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    onPaginationChange: setPagination,
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <div className="">
      <RejectionModel
        isReject={isReject}
        setIsReject={setIsReject}
        setData={setData}
        serviceStatus={serviceStatus}
        setServiceStatus={setServiceStatus}
      />
      <BookingModal
        serviceStatus={serviceStatus}
        setServiceStatus={setServiceStatus}
        isBookModal={isBookModal}
        setBookModal={setBookModal}
        currentStatus={currentStepInfo}
        data={data}
        setData={setData}
      />
      {loading ? (
        <div>
          <AiOutlineLoading className="h-6 w-6 animate-spin m-auto" />
        </div>
      ) : data?.length == 0 ? (
        <div className="text-center p-8">No Service request yet</div>
      ) : (
        <table className="w-full border-borderColor border-y-[1px]">
          <thead className="w-full">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    className="border-borderColor font-semibold text-base border-x-[1px] py-2 px-1"
                    key={header.id}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="text-center">
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td
                    className="border-borderColor text-base font-medium border-[1px] px-1 py-2 m-auto"
                    key={cell.id}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <div className="flex justify-center mt-5 gap-10">
        <button
          className="px-4 py-2 border-gray-300 rounded-sm flex items-center border-[1px]"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {!table.getCanPreviousPage() ? (
            <img src={disabledLeftIcon} alt="" />
          ) : (
            <img src={leftIcon} alt="" />
          )}
        </button>
        <div className="flex gap-5">
          {Array.from({ length: table.getPageCount() }, (_, index) => (
            <button
              key={index}
              className={`px-4 py-2 border rounded-sm ${
                index === pagination?.pageIndex
                  ? "border-redBackground text-redBackground"
                  : "border-gray-200"
              }`}
              onClick={() => setPagination({ ...pagination, pageIndex: index })}
            >
              {index + 1}
            </button>
          ))}
        </div>
        <button
          className="px-4 py-2 border-gray-300 rounded-sm flex items-center border-[1px]"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          {!table.getCanNextPage() ? (
            <img src={disabledRightIcon} alt="" />
          ) : (
            <img src={rightIcon} alt="" />
          )}
        </button>
      </div>
    </div>
  );
};

export default ServiceRequestTable;
