import React, { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import CalenderView from "./CalenderView";
import PrimaryButton from "../../utils/buttons/primaryButton";
import TimeView from "./TimeView";
import { setUsedCarSettingInfo } from "../../../redux/usedCarsSlice";
import axiosInstance, { notifySuccess } from "../../../utility/axiosInstance";

export const UsedCarSettingContext = createContext({});
const weekdays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
const wkDays = {
  Monday: false,
  Tuesday: false,
  Wednesday: false,
  Thursday: false,
  Friday: false,
  Saturday: false,
  Sunday: false,
};
export default function UsedCarSettingIndex({ tab, setTab }) {
  const { list: usedCars, usedCarSettingInfo } = useSelector(
    (state) => state.usedCars
  );
  const dispatch = useDispatch();
  const [weeklyCheckbox, setWeeklyCheckbox] = useState(wkDays);
  const [workingTime, setWorkingTime] = useState({
    start: "9:00",
    end: "18:00",
  });
  const [tempTimeSave, setTempTimeSave] = useState([]);

  const [workingDayTime, setWorkingDayTime] = useState([]);
  const [availableWorkingDays, setAvailableWorkingDays] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [dateValue, onDateChange] = useState(new Date());
  const [currentMonth, onCurrentMonth] = useState(
    new Date(new Date().setDate(1))
  );
  // const [tab, setTab] = useState(0);
  const [multiType, setmultiType] = useState(true);

  const [available, setAvailable] = useState([]);
  const [carList, setCarList] = useState([]);
  const [selectedCars, setSelectedCars] = useState([]);
  const [selectedCar, setSelectedCar] = useState(null);

  const [existingCarList, setExistingCarList] = useState([]);

  const compareTwoDate = (date1, date2) => {
    const d1 = new Date(date1).setHours(0, 0, 0, 0);
    const d2 = new Date(date2).setHours(0, 0, 0, 0);

    return d1 === d2;
  };
  const compareMonthAndYear = (date1, date2) => {
    const d1 = new Date(date1);
    const d2 = new Date(date2);

    // Compare month and year
    return (
      d1.getMonth() === d2.getMonth() && d1.getFullYear() === d2.getFullYear()
    );
  };

  const CustomDatePicker = () => {
    return (
      <span className="text-xl flex items-center gap-5">
        Date:
        {/* <MdOutlineCalendarMonth className="ml-2 mr-1" /> */}
        <span className="text-xl cursor-pointer">
          <input
            type="date"
            // style={{ display: "none" }}
            className="w-64"
            value={
              !!available.find((date) => compareTwoDate(date, dateValue))
                ? new Date(dateValue).toISOString().split("T")[0]
                : new Date(
                    new Date(dateValue).setDate(
                      new Date(dateValue).getDate() + 1
                    )
                  )
                    .toISOString()
                    .split("T")[0]
            } // Correct date formatting for input value
            onChange={(e) => {
              const newDateValue = e.target.value;
              if (
                !available.find((date) => compareTwoDate(date, newDateValue))
              ) {
                toast.warn("This Date is not available");
                return;
              }
              onDateChange(new Date(newDateValue)); // Trigger date change callback
            }}
          />
        </span>
      </span>
    );
  };
  useEffect(() => {
    if (usedCars?.length > 0) {
      const filtered = usedCars.filter(({ status }) => status == "available");
      setCarList(filtered);
    }
  }, [usedCars]);

  // const onBack = () => {
  //   setTab((count) => (count == 0 ? count : count - 1));
  // };
  const onNext = () => {
    setTab((count) => (count == 1 ? count : count + 1));
  };
  useEffect(() => {
    if (selectedCar) {
      const exist = usedCarSettingInfo?.find(({ car }) => selectedCar == car);

      if (exist) {
        const { workingTime, weeklySetting } = exist.workingDetails;
        if (workingTime && Object.keys(workingTime).length !== 0) {
          setWorkingTime(workingTime);
        }
        if (weeklySetting && Object.keys(weeklySetting).length) {
          setWeeklyCheckbox(weeklySetting);
        }

        if (exist.holidays) {
          setHolidays(exist.holidays);
        }
      }
    } else if (selectedCars.length > 0 && usedCarSettingInfo.length > 0) {
      const exist = usedCarSettingInfo[0];
      if (exist) {
        const { workingTime, weeklySetting } = exist.workingDetails;

        if (workingTime && Object.keys(workingTime).length !== 0) {
          setWorkingTime(workingTime);
        }
        if (weeklySetting && Object.keys(weeklySetting).length) {
          setWeeklyCheckbox(weeklySetting);
        }

        if (exist.holidays) {
          setHolidays(exist.holidays);
        }
      }
    } else {
      setHolidays([]);
      setWorkingTime({
        start: "9:00",
        end: "18:00",
      });
      setWeeklyCheckbox(wkDays);
    }
  }, [selectedCar, selectedCars, usedCarSettingInfo]);

  const handleMonthDateUpdate = async (body) => {
    try {
      if (body.usedCars.length < 1) {
        toast.warn("No Vehicles is Selected");
        return;
      }
      const response = await axiosInstance.put(
        "usedCarSetting/updateUsedCarSettingInfo",
        {
          ...body,
        }
      );
      // console.log(response);
      if (response?.status === 200) {
        console.log(response?.data.usedCarSettings);
        dispatch(setUsedCarSettingInfo(response?.data.usedCarSettings));
        notifySuccess("Updated suceccesfully");

        // toast.success("Changed the update");
      }
    } catch (err) {
      console.log(err);
    } finally {
    }
  };
  const handleSingleUpdate = async (body) => {
    try {
      if (body.usedCars.length < 1) {
        toast.warn("No Vehicles is Selected");
        return;
      }
      const response = await axiosInstance.put(
        "usedCarSetting/updateUsedCarSettingInfoWithDate",
        {
          ...body,
        }
      );

      if (response?.status === 200) {
        console.log(response?.data.usedCarSettings);
        dispatch(setUsedCarSettingInfo(response?.data.usedCarSettings));
        notifySuccess("Updated sucessfully");
        // toast.success("Changed the update");
      }
    } catch (err) {
      console.log(err);
    } finally {
    }
  };
  const onHandleWOS = async (body) => {
    try {
      const response = await handleMonthDateUpdate({
        weeklySetting: weeklyCheckbox,
        usedCars: selectedCar ? [selectedCar] : selectedCars,
      });
    } catch (err) {
      console.log(err);
    } finally {
    }
  };
  useEffect(() => {
    const tempAllDate = [];

    if (available.length > 0) {
      available.forEach((element) => {
        tempAllDate.push({
          date: element,
          timeSlots: workingDayTime,
        });
      });

      setAvailableWorkingDays(tempAllDate);
    }
  }, [available, workingDayTime]);

  // console.log("availableWorkingDays",availableWorkingDays);
  return (
    <UsedCarSettingContext.Provider
      value={{
        handleSingleUpdate,
        handleMonthDateUpdate,
        CustomDatePicker,
        weeklyCheckbox,
        weekdays,
        setWeeklyCheckbox,
        dateValue,
        onDateChange,
        workingTime,
        setWorkingTime,
        workingDayTime,
        setWorkingDayTime,
        availableWorkingDays,
        setAvailableWorkingDays,
        selectedCars,
        setSelectedCars,
        selectedCar,
        setSelectedCar,
        carList,
        setCarList,
        compareTwoDate,
        holidays,
        setHolidays,
        onHandleWOS,
        currentMonth,
        onCurrentMonth,
        available,
        setAvailable,
        compareMonthAndYear,
        tempTimeSave,
        setTempTimeSave,
      }}
    >
      <div className="py-8 flex flex-col  w-[100%] gap-10">
        {tab === 0 ? (
          <CalenderView />
        ) : (
          <div className="flex flex-col gap-5 ">
            <TimeView />
            {/* <WeekSettingComponent />
            <ServiceListComponent /> */}
          </div>
        )}

        <div className="flex gap-1 justify-end px-10">
          {/* <PrimaryButton
            width="120px"
            className={`bg-white border border-redBackground `}
            title={"Back"}
            textClass={"text-redBackground "}
            onClick={onBack}
            // loading={isMLoading}
          /> */}
          {tab < 1 && (
            <PrimaryButton
              width="140px"
              title={"Next"}
              onClick={onNext}
              // onClick={() => updateHoliday(dateValue)}
              // loading={isLoading}
            />
          )}
        </div>
      </div>
    </UsedCarSettingContext.Provider>
  );
}
