import React, { useState } from "react";
import { icons8crossFilled } from "../../assets";
import { Dialog } from "@headlessui/react";
import axiosInstance from "../../utility/axiosInstance";
import PrimaryButton from "../utils/buttons/primaryButton";

export const RescheduleForm = ({
  isReschedule,
  setIsReschedule,
  data,
  handleUpdate,
}) => {
  const { _id } = data;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [proposedDate, setProposedDate] = useState("");

  const handleReschedule = async () => {
    if (!proposedDate) {
      setError("Please provide the proposed date.");
      return;
    }
    setLoading(true);
    setError(null);
    try {
      await handleUpdate(
        {
          status: "rescheduleProposed",
          proposedDate,
        },
        {
          _id,
        }
      );
    } catch (error) {
      setError(error.message || "Failed to update reschedule");
    } finally {
      setLoading(false);
      setIsReschedule(!isReschedule);
    }
  };

  return (
    <Dialog
      open={isReschedule}
      onClose={() => setIsReschedule(false)}
      className="relative z-50"
    >
      <div className="fixed inset-0 bg-black/70 flex w-screen items-center justify-center p-4">
        <Dialog.Panel className="w-full flex justify-center items-center rounded">
          <div className="max-h-[40vh] w-[400px] rounded-md relative bg-[#F5F5F5] p-4">
            <div className="p-2">
              <img
                src={icons8crossFilled}
                onClick={() => setIsReschedule(false)}
                className="w-8 h-8 absolute right-2 top-2 cursor-pointer"
                alt="Close"
              />
            </div>

            <div className="mt-0 p-4">
              <div className="flex justify-center items-center min-w-full">
                <div>
                  <label>Select your proposed date</label>
                  <input
                    type="datetime-local"
                    value={proposedDate}
                    onChange={(e) => setProposedDate(e.target.value)}
                    className="w-full border border-gray-300 rounded mt-2 mb-4"
                  />
                  {error && <p className="text-red-600 mt-2">{error}</p>}
                  <PrimaryButton
                    type="button"
                    onClick={handleReschedule}
                    disabled={loading}
                    loading={loading}
                    className={`${
                      loading ? "bg-gray-500" : "bg-[#990000]"
                    } hover:bg-[#990000] text-white font-semibold w-full`}
                    title={"Submit"}
                  />
                </div>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};
