import React, { useEffect, useState } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import ToggleButton from "../utils/buttons/toggleButton";

import { RiEditBoxFill } from "react-icons/ri";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { Tooltip } from "flowbite-react";

const ServiceTable = ({
  services,
  onToggle,
  setFormData,
  onEdit,
  onDelete,
  searchText,
}) => {
  const [data, setData] = useState([]);

  const columnHelper = createColumnHelper();

  useEffect(() => {
    // const textToSearch = searchText.toLowerCase();
    // const filteredData = services?.filter((ele) => {
    //   return ele?.serviceName?.toLowerCase()?.includes(textToSearch);
    // });
    setData(services);
    // console.log(filteredData, "filteredData");
  }, [services]);

  const columns = [
    columnHelper.accessor("serviceName", {
      header: () => "Service Name",
      cell: (info) => info.getValue(),
    }),

    columnHelper.accessor((row) => row.price, {
      id: "price",
      cell: (info) => `CA${info.getValue()}`,
      header: () => "Price",
      footer: (info) => info.column.id,
    }),

    columnHelper.accessor((row) => row?.estimatedTime, {
      id: "estimatedTime",
      cell: (info) => `${info.getValue()} min`,
      header: () => "Estimated Time",
      footer: (info) => info.column.id,
    }),

    columnHelper.accessor("status", {
      header: () => "Status",
      cell: (info) => (
        <ToggleButton
          onClick={() => {
            handleSwitchStatus(info.row.original);
          }}
          checked={info.row.original.status == "active"}
        />
      ),
    }),

    // {
    //   header: "Enable for Booking",
    //   accessorKey: "enableBooking",
    //   cell: (info) => (
    //     <ToggleButton
    //       onClick={() => {
    //         handleSwitchStatus(info.row.original, "enableBooking");
    //       }}
    //       checked={info.row.original.enableBooking == true}
    //     />
    //   ),
    // },
    // {
    //   header: "Available Slots",
    //   accessorKey: "availableSlots",
    //   cell: (info) => info.getValue(),
    // },

    columnHelper.accessor("status", {
      header: () => "Action",
      cell: (info) => (
        <div className="flex justify-around w-[80%] m-auto">
          {
            <Tooltip content={"Edit Service"}>
              <RiEditBoxFill
                className="cursor-pointer"
                onClick={() => onEdit(info.row.original)}
                size={24}
              />
            </Tooltip>
          }
          {
            <Tooltip content={"Delete Service"}>
              <RiDeleteBin5Fill
                size={24}
                onClick={() => onDelete(info.row.original)}
                className="text-[#990000] cursor-pointer"
              />
            </Tooltip>
          }
        </div>
      ),
    }),
  ];

  const handleSwitchStatus = (info, type) => {
    // setFormData((prev)=>({
    //     ...prev,
    //     status : "inactive"
    // }))
    onToggle(info, type);
  };

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div>
      <div className="w-full">
        <table className="w-full border-borderColor border-y-[1px] text-center">
          <thead className="w-full text-center py-2">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    className="border-borderColor border-x-[1px] text-center py-2"
                    key={header.id}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="text-center">
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td
                    className="border-borderColor border-[1px] pl-2 py-2"
                    key={cell.id}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <div className="h-4" />
      </div>
    </div>
  );
};

export default ServiceTable;
