import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchCurrentUser } from "../../redux/authThunk";
import { getCurrentUser } from "../../utility/api";
const ProtectedTabs = ({ children }) => {
  const [user, setUser] = useState();

  useEffect(() => {
    const fetchUser = async () => {
      const response = await getCurrentUser();
      setUser(response?.data);
    };
    fetchUser();

    // console.log(children.props.to, "debug");
  }, []);

  if (user?.role === "admin") {
    return <>{children}</>;
  }

  if (user?.role === "carSeller") {
    return (
      <>
        {(children?.props?.to == "/usedCars" ||
          children?.props?.to == "/home") &&
          children}
      </>
    );
  }

  return null;
};

export default ProtectedTabs;
