export const InputFields = ({
  type,
  title,
  name,
  placeholder,
  value,
  onChange,
  ...props
}) => {
  return (
    <div className="flex flex-col">
      <label htmlFor={name} className="mb-1 text-sm font-medium">
        {title}
      </label>
      <input
        type={type}
        id={name}
        name={name}
        {...props}
        value={value || ""} // Ensure it is a string
        onChange={onChange}
        props
        className=" border-[1px] w-full h-[50px] mt-1 transition bg-white ease-in-out delay-150 border-[#ccc] truncate leading-[19.07px] text-gray-600 text-lg py-[8px] px-2 lg:pl-3 placeholder:text-base  rounded-md  focus:outline-none focus:border-blue-300"
      />
    </div>
  );
};
