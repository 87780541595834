import React, { useContext, useEffect, useState } from "react";
import { UsedCarSettingContext } from "./UsedCarSettingIndex";
import { toast } from "react-toastify";
import WeeklyOffSetting from "../../Common/settingComponet/WeeklyOffSetting";
import CalenderViewComponent from "../../Common/settingComponet/CalenderViewComponent";
import CarListView from "./CarListView";
import PrimaryButton from "../../utils/buttons/primaryButton";
import axiosInstance, { notifySuccess } from "../../../utility/axiosInstance";
import HolidayListComponent from "../../Common/settingComponet/HolidayListComponent";
import { useDispatch } from "react-redux";
import { setUsedCarSettingInfo } from "../../../redux/usedCarsSlice";

function CalenderView() {
  const dispatch = useDispatch();
  const {
    calenderView,
    dateValue,
    workingDayTime,
    onDateChange,
    workingTime,
  
    weekdays,
    weeklyCheckbox,
    available,
    setAvailable,
    availableWorkingDays,
    setAvailableWorkingDays,
    handleMonthDateUpdate,
    isLoading: isMLoading,
    tempTimeSave,
    compareTwoDate,
    holidays,
    currentMonth,
    onCurrentMonth,
    selectedCars,
    selectedCar,
  } = useContext(UsedCarSettingContext);

  const [allDate, setAllDate] = useState({
    minDate: new Date(),
    maxDate: new Date(),
  });
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    try {
      let today = new Date(currentMonth);

      const currentMonthDate = new Date(currentMonth);
      let count = 30;
      const daysInMonth = new Date(
        currentMonthDate.getFullYear(),
        currentMonthDate.getMonth() + 1,
        0
      ).getDate();

      count = daysInMonth;

      if (weeklyCheckbox) {
        onDateChange();
        let allDates = [];
        for (let index = 0; index < count; index++) {
          let tempDate = new Date(today);
          tempDate.setDate(tempDate.getDate() + index);
          const weekday = tempDate.toLocaleDateString("en-US", {
            weekday: "long",
          });
          if (tempDate.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) {
            continue;
          }
          // console.log(tempDate);
          if (weeklyCheckbox[weekday]) {
            allDates.push(tempDate);
          }
        }
        let lastDay = new Date(today);
        if (holidays) {
          if (holidays.length > 0) {
            allDates = allDates.filter((tempDate) => {
              const exist = holidays.find(({ date }) =>
                compareTwoDate(date, tempDate)
              );
              if (exist) {
                return false;
              }
              return true;
            });
          }
        }
        setAvailable(allDates);

        lastDay.setDate(lastDay.getDate() + count - 1);

        onDateChange(today);
        setAllDate((state) => ({ minDate: today, maxDate: lastDay }));
      }
    } catch (error) {
      console.log(error);
    }
  }, [
    weeklyCheckbox,
    calenderView,
    weekdays,
    onDateChange,
    setAvailableWorkingDays,
    // serviceSettingInfo,
    currentMonth,
  ]);
  console.log(holidays);
  useEffect(() => {
    if (available.length > 0) {
      onDateChange(available[0]);
    }
  }, [available, onDateChange]);

  const updateHoliday = async (selectedDate, deleteDate = false) => {
    try {
      try {
        if (!selectedCar && selectedCars.length < 0) {
          toast.warn("No Vehicles is Selected");
          return;
        }
        setIsLoading(true);
        let holidayBody = [...holidays];

        if (deleteDate) {
          if (selectedDate?.length <= 0) {
            toast.error("Please Select Date");
            return;
          }
          holidayBody = holidayBody.filter(
            ({ date }) =>
              !selectedDate.includes(new Date(date).setHours(0, 0, 0, 0))
          );
        } else {
          if (!selectedDate) {
            toast.error("Please Select Date");
            return;
          }
          const exist = holidays.find(({ date }) =>
            compareTwoDate(date, selectedDate)
          );
          if (exist) {
            toast.error("Date already Exist in Holiday List");
            return;
          }
          holidayBody = [...holidays, { date: selectedDate }];
        }

        const temp = availableWorkingDays.find(({ date }) => {
          return compareTwoDate(date, dateValue);
        });

        const response = await axiosInstance.put(
          "usedCarSetting/updateUsedCarSettingHolidays",
          {
            usedCars: selectedCar ? [selectedCar] : selectedCars,
            holidays: holidayBody,
          }
        );

        if (response?.status === 200) {
          console.log(response?.data.usedCarSettings);
          dispatch(setUsedCarSettingInfo(response?.data.usedCarSettings));
          // tempTimeSave([]);
          notifySuccess("Updated suceccesfully");

          // toast.success("Changed the update");
          // setInfo(response.data.parkingLot);
        } else {
          toast.warn("");
        }
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="flex  flex-row  md:flex-col first-line:flex-wrap gap-5 justify-center sm:justify-normal">
      <CarListView />
      <WeeklyOffSetting
        SettingContextData={useContext(UsedCarSettingContext)}
      />
      <CalenderViewComponent
        allDate={allDate}
        handleMonthDateUpdate={async () => {
          await handleMonthDateUpdate({
            monthYearDate: currentMonth,
            usedCars: selectedCars,

            usedCarMonthSetting: {
              monthYearDate: currentMonth,
              usedCarDateSettings: availableWorkingDays,
            },
          });
        }}
        // button={() => {
        //   return (
        //     <PrimaryButton
        //       width="120px"
        //       className={""}
        //       title={"Update"}
        //       onClick={async () => {
        //         try {
        //           setIsLoading(true);

        //           await handleMonthDateUpdate({
        //             monthYearDate: currentMonth,
        //             usedCars: [selectedCar],

        //             usedCarMonthSetting: {
        //               monthYearDate: currentMonth,
        //               usedCarDateSettings: availableWorkingDays,
        //             },
        //           });
        //         } catch (err) {
        //         } finally {
        //           setIsLoading(false);
        //         }
        //       }}
        //       loading={isLoading}
        //     />
        //   );
        // }}
        updateHoliday={updateHoliday}
        SettingContextData={useContext(UsedCarSettingContext)}
      />
      <HolidayListComponent
        isLoading={isLoading}
        updateHolidayFunc={updateHoliday}
        holidays={holidays}
      />
    </div>
  );
}

export default CalenderView;
