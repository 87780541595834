import React from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import {
  disabledLeftIcon,
  disabledRightIcon,
  leftIcon,
  rightIcon,
} from "../../assets";

const Pagination = ({
  currentPageIndex,
  totalPages,
  handlePageChange,
  canPreviousPage,
  canNextPage,
}) => {

  console.log(canNextPage,'next')
  return (
    <div className="flex justify-center gap-10">
      {/* Previous Button */}
      <button
        className="px-4 py-2 border-gray-300 rounded-sm flex items-center border-[1px]"
        onClick={() => handlePageChange(currentPageIndex - 1)}
        disabled={!canPreviousPage}
      >
        {!canPreviousPage ? (
          <img src={disabledLeftIcon} alt="Disabled left icon" />
        ) : (
          <img src={leftIcon} alt="Left icon" />
        )}
      </button>

      {/* Page Numbers */}
      <div className="flex gap-5">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            className={`px-4 py-2 border rounded-sm ${
              index === currentPageIndex
                ? "border-redBackground text-redBackground"
                : "border-gray-200"
            }`}
            onClick={() => handlePageChange(index)}
          >
            {index + 1}
          </button>
        ))}
      </div>

      {/* Next Button */}
      <button
        className="px-4 py-2 border-gray-300 rounded-sm flex items-center border-[1px]"
        onClick={() => handlePageChange(currentPageIndex + 1)}
        disabled={!canNextPage}
      >
        {!canNextPage ? (
          <img src={disabledRightIcon} alt="Disabled right icon" />
        ) : (
          <img src={rightIcon} alt="Right icon" />
        )}
      </button>
    </div>
  );
};

export default Pagination;
