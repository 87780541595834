import React, { useState } from "react";
import { resetPasssWord } from "../../assets";

const OtpVeify = ({
  otp,
  setOtp,
  onVerifyOtp,
  onResendOtp,
  timer,
  isResend,
  errorMessage,
}) => {
  const [error, setError] = useState(false);

  const handleChange = (e, index) => {
    const value = e.target.value;

    if (/[^0-9]/.test(value)) {
      return;
    }

    const newOtp = [...otp];
    newOtp[index] = value;

    setOtp(newOtp);

    if (value !== "") {
      const nextInput = document.getElementById(`otp-input-${index + 1}`);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "") {
      const previousInput = document.getElementById(`otp-input-${index - 1}`);
      if (previousInput) {
        previousInput.focus();
      }
    }
  };

  return (
    <div className="flex flex-col items-center justify-center bg-zinc-100">
      <div className="bg-white p-6 w-full max-w-md mx-auto">
        <img
          src={resetPasssWord}
          alt="Reset Password Illustration"
          className="w-full h-auto mb-6"
        />

        {errorMessage && (
          <div className="bg-[#F2030B1A] text-white text-center py-0.5 rounded-lg m-6">
            <p className="text-lg text-red-500">{errorMessage}</p>
          </div>
        )}

        <div className="flex justify-center space-x-2 mb-6">
          {otp?.map((data, index) => (
            <input
              key={index}
              type="text"
              maxLength="1"
              className="w-12 h-12 text-center text-xl bg-[#D9D9D9] dark:border-zinc-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-300"
              value={data}
              onChange={(e) => handleChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              id={`otp-input-${index}`}
            />
          ))}
        </div>

        <div className="text-center">
          <button
            onClick={onVerifyOtp}
            className="w-1/2 text-center m-auto bg-[#990000] text-white py-2 rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 dark:focus:ring-red-300 mb-4"
          >
            Verify
          </button>
        </div>

        <div className="text-center text-zinc-600 dark:text-zinc-400 cursor-pointer">
          Didn't Receive Passkey?{" "}
          <button
            disabled={!isResend}
            onClick={isResend ? onResendOtp : null}
            href=""
            className="text-[#990000] hover:underline block m-auto"
          >
            {isResend ? "Resend" : `Resend Passkey in ${timer}s`}
          </button>
        </div>
      </div>
    </div>
  );
};

export default OtpVeify;
