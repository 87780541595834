import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Layout from "./layout";
import { getCurrentUser } from "../utility/api";
import { useSelector } from "react-redux";
import { useCallback } from "react";

const PrivateRoute = ({ allowedRoles }) => {
  const [user, setUser] = useState(null);
  const userData = useSelector((state) => state.user.list);

  const [loading, setLoading] = useState(true);

  const fetchUser = useCallback(async () => {
    try {
      const response = await getCurrentUser();
      setUser(response?.data);
    } catch (error) {
      console.error("Failed to fetch user:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchUser();
  }, []);

  const token = localStorage.getItem("token");

  if (!token) {
    return <Navigate to="/" />;
  }

  if (!loading) {
    if (!user || !allowedRoles.includes(user?.role)) {
      return <Navigate to="/unauthorized" />;
    }
    if (user.role === "carSeller") {
      if (
        window.location.pathname !== "/usedCars" &&
        window.location.pathname !== "/home"
      ) {
        // console.log(window.location.pathname !== "/home", "debugggggggggg");
        return <Navigate to="/unauthorized" />;
      }
    }
  }

  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

export default PrivateRoute;
