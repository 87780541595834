import { createAsyncThunk } from "@reduxjs/toolkit";
import { customers } from "../utility/api";
import { setCustomers, setLoading } from "./customersSlice";
import axiosInstance, { notifyError } from "../utility/axiosInstance";

export const fetchCustomers = createAsyncThunk(
  "customers/fetchCustomers",
  async ({ currentPage, limit , search}, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axiosInstance.get(
        `${customers.getAllCustomers}?page=${currentPage}&limit=${limit}${search && `&search=${search}`}`
      );
      dispatch(setCustomers(response.data));
    } catch (error) {
      notifyError(`Failed to fetch notifications:, ${error}`);
    } finally {
      dispatch(setLoading(false));
    }
  }
);
