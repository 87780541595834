import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../utility/axiosInstance";

// Async thunk to fetch used cars enquiries
export const fetchUsedCarsEnquiries = createAsyncThunk(
  "usedCarsEnquiries/fetchUsedCarsEnquiries",
  async ({ page, limit }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `/inquiry${`?page=${page}&limit=${limit}`}`
      ); // Replace with actual API endpoint
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "An error occurred");
    }
  }
);

// Slice to manage used cars enquiries state
const usedCarsEnquiriesSlice = createSlice({
  name: "usedCarsEnquiries",
  initialState: {
    enquiries: [],
    status: "idle", // idle, loading, succeeded, failed
    error: null,
    meta: null,
  },
  reducers: {
    // You can define additional reducers here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsedCarsEnquiries.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUsedCarsEnquiries.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.enquiries = action.payload.data; // Assuming the payload is an array of enquiries
        state.meta = action.payload.meta; // Assuming the meta is additional information about the fetched enquiries
      })
      .addCase(fetchUsedCarsEnquiries.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "Failed to load enquiries";
      });
  },
});

// Exporting the reducer to be used in the store
export default usedCarsEnquiriesSlice.reducer;
