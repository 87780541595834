import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  setUsedCars,
  setLoading,
  addUsedCarsSuccess,
  deleteUsedCarsSuccess,
  updateUsedCarsSuccess,
  setUsedCarSettingInfo,
} from "./usedCarsSlice";
import { usedCars } from "../utility/api";
import axiosInstance, {
  notifyError,
  notifySuccess,
} from "../utility/axiosInstance";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

export const fetchUsedCars = createAsyncThunk(
  "usedCars/fetchUsedCars",
  async ({ page, limit, search }, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      console.log(page, limit);
      const response = await axiosInstance.get(
        `${usedCars.getAllUsedCars}?page=${page}&limit=${limit} ${
          search && `&search=${search}`
        }`
      );

      return response.data;
    } catch (error) {
      notifyError(`Failed to fetch used cars:, ${error}`);
    } finally {
      dispatch(setLoading(false));
    }
  }
);
export const fetchUsedCarsSettingInfo = createAsyncThunk(
  "usedCars/fetchUsedCarsSettingInfo",
  async ({ dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axiosInstance.get(
        `/usedCarSetting/getAllUsedSettingInfo`
      );
      console.log("response",response);
      if (response.status == 200) {
        dispatch(setUsedCarSettingInfo(response.data.usedCarSettings));
      }
      return response.data;
    } catch (error) {
      notifyError(`Failed to fetch used cars:, ${error}`);
    } finally {
      dispatch(setLoading(false));
    }
  }
);
export const addUsedCars = createAsyncThunk(
  "usedCars/addUsedCars",
  async (newUsedCars, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axiosInstance.post(
        usedCars.postUsedCars,
        newUsedCars
      );
      dispatch(fetchUsedCars());
      notifySuccess("Used car has been added");
      return response?.data;
    } catch (error) {
      notifyError(`Failed to add used cars:, ${error}`);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const updateUsedCars = createAsyncThunk(
  "usedCars/updateUsedCars",
  async ({ id, updatedUsedCars }, { dispatch }) => {
    dispatch(setLoading(true));
    console.log(updateUsedCars, "updateUsedCars");
    try {
      const response = await axiosInstance.put(
        `${usedCars?.updateUsedCars}/${id}`,
        updatedUsedCars
      );
      dispatch(fetchUsedCars());
      notifySuccess("Used car has been updated");

      return response?.data;
      //   dispatch(updateSparePartSuccess(response.data));
    } catch (error) {
      notifyError(`Failed to update used cars:, ${error}`);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const deleteUsedCars = createAsyncThunk(
  "usedCars/deleteUsedCars",
  async (id, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axiosInstance.delete(
        `${usedCars?.postUsedCars}/${id}`
      );
      notifySuccess("Used car has been deleted");
      return response?.data;
    } catch (error) {
      notifyError(`Failed to delete used cars:, ${error}`);
    } finally {
      dispatch(setLoading(false));
    }
  }
);
