import React, { useState } from "react";

interface userProp {
  onClick: any;
  checked: boolean;
}

const ToggleButton = ({ onClick, checked }: userProp) => {
  const [isActive, setActive] = useState(checked);

  return (
    <div>
      <button
        onClick={() => {
          setActive(!isActive);
          onClick();
        }}
        className={`
        ${isActive ? "justify-end" : "justify-start"} ${
          isActive ? "bg-[#54CF54]" : "bg-[#FF0000]"
        } w-[60px] relative h-6 p-[1px] m-auto rounded-full flex transition duration-500 shadow-2xl`}
      >
        <span
          className={`bg-white transition duration-500 rounded-full w-[20px] h-full m-0 shadow-xl`}
        ></span>
        <p
          className={`absolute ${
            isActive ? "" : "hidden"
          } left-4 text-sm text-white top-0.5`}
        >
          ON
        </p>
        <p
          className={`absolute ${
            isActive ? "hidden" : ""
          } left-7 text-sm text-white top-0.5`}
        >
          OFF
        </p>
      </button>
    </div>
  );
};
const ToggleAButton = ({ onClick, checked }: userProp) => {
  return (
    <div>
      <button
        onClick={onClick}
        className={`
        ${checked ? "justify-end" : "justify-start"} ${
          checked ? "bg-[#54CF54]" : "bg-[#FF0000]"
        } w-[60px] relative h-6 p-[1px] m-auto rounded-full flex transition duration-500 shadow-2xl`}
      >
        <span
          className={`bg-white transition duration-500 rounded-full w-[20px] h-full m-0 shadow-xl`}
        ></span>
        <p
          className={`absolute ${
            checked ? "" : "hidden"
          } left-4 text-sm text-white top-0.5`}
        >
          ON
        </p>
        <p
          className={`absolute ${
            checked ? "hidden" : ""
          } left-7 text-sm text-white top-0.5`}
        >
          OFF
        </p>
      </button>
    </div>
  );
};
export { ToggleAButton };
export default ToggleButton;
