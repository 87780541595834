import React, { useContext, useEffect } from "react";
import WorkTimeSettingComponent from "../../Common/settingComponet/WorkTimeSettingComponent";
import { UsedCarSettingContext } from "./UsedCarSettingIndex";
import TimeSlotComponent from "../../Common/settingComponet/TimeSlotComponent";
import { useSelector } from "react-redux";

export default function TimeView() {
  const {
    compareMonthAndYear,
    selectedCar,
    workingDayTime,
    selectedCars,
    currentMonth,
    handleMonthDateUpdate,
    workingTime,
    setWorkingDayTime,
    compareTwoDate,
    dateValue,

    handleSingleUpdate,
  } = useContext(UsedCarSettingContext);
  const { list: usedCars, usedCarSettingInfo } = useSelector(
    (state) => state.usedCars
  );
  useEffect(() => {
    try {
      if (Object.keys(workingTime).length !== 0) {
        let currentTime = workingTime.start;
        let endTime = workingTime.end;
        let startDate = new Date();

        let endDate = new Date();
        let [startHour, startMinute] = currentTime.split(":");
        startDate.setHours(startHour, startMinute, 0, 0);

        let [endStartHour, endStartMinute] = endTime.split(":");
        endDate.setHours(endStartHour, endStartMinute - 15, 0, 0);
        let min = 0;
        let end = 15;
        const allTime = [];
        let dateData = null;

        if (selectedCar && usedCarSettingInfo?.length > 0) {
          const exist = usedCarSettingInfo.find(
            ({ car }) => car === selectedCar
          );
          if (exist) {
            const tempMonth = exist?.usedCarMonthSetting.find(
              ({ monthYearDate }) => {
                return compareMonthAndYear(monthYearDate, currentMonth);
              }
            );

            if (tempMonth) {
              dateData = tempMonth?.usedCarDateSettings?.find(({ date }) => {
                return compareTwoDate(date, dateValue);
              });
              console.log(tempMonth?.usedCarDateSettings);

              // if (exist) {
              //   dateData = exist;
              // }
            }

       
          }
        }
        if (selectedCars.length > 0 && usedCarSettingInfo?.length > 0) {
          const exist = usedCarSettingInfo[0];
          if (exist) {
            const tempMonth = exist?.usedCarMonthSetting.find(
              ({ monthYearDate }) => {
                return compareMonthAndYear(monthYearDate, currentMonth);
              }
            );

            if (tempMonth) {
              dateData = tempMonth?.usedCarDateSettings?.find(({ date }) => {
                return compareTwoDate(date, dateValue);
              });
              console.log(tempMonth?.usedCarDateSettings);

              // if (exist) {
              //   dateData = exist;
              // }
            }

       
          }
        }
        // console.log(dateData);
        while (startDate < endDate) {
          startDate.setHours(startHour, startMinute + min, 0, 0);

          const tempTime = startDate.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          });

          let status = false;
          if (dateData) {
            const exist = dateData.timeSlots.find(
              ({ time }) => time === tempTime
            );
            status = exist ? exist.status : false;
          }
          allTime.push({
            time: tempTime,
            status: status,
          });

          min += 15;
          end += 15;
        }
        console.log(allTime);
        setWorkingDayTime(allTime);
      }
    } catch (error) {
      console.log(error);
    }
  }, [setWorkingDayTime, workingTime, dateValue, usedCarSettingInfo]);

  return (
    <div className="flex flex-col gap-5">
      <WorkTimeSettingComponent
        SettingContextData={useContext(UsedCarSettingContext)}
        handleMonthDateUpdate={async () => {
          await handleMonthDateUpdate({
            usedCars: selectedCars,
            monthYearDate: currentMonth,
            workingTime,
          });
        }}
      />
      <TimeSlotComponent
        SettingContextData={useContext(UsedCarSettingContext)}
        handleSingleUpdate={async () =>
          await handleSingleUpdate({
            usedCars: selectedCars,
            workingDayTime,
            currentDate: dateValue,
            monthYearDate: currentMonth,
          })
        }
        handleMonthDateUpdate={async () =>
          await handleMonthDateUpdate({
            usedCars: selectedCars,
            monthYearDate: currentMonth,
            workingDayTime,
          })
        }
      />
    </div>
  );
}
