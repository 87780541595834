import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import PrimaryButton from "../utils/buttons/primaryButton";
import { CancellationReason } from "./CancellationReason";
import { Dialog } from "@headlessui/react";
import { icons8crossFilled } from "../../assets";
import { RescheduleForm } from "./RescheduleForm";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { Button, Tooltip } from "flowbite-react";
import { AiOutlineLoading } from "react-icons/ai";
import axiosInstance, {
  notifySuccess,
  notifyWarning,
} from "../../utility/axiosInstance";

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const BookingStepsUpdateCard = ({
  currentStepInfo,
  onUpdate,
  data,
  setData,
  description,
  isCancellation,
  setIsReject,
  isReject,
  setCurrentStepInfo,
  loading,
  buttonLoading,
}) => {
  const [current, setCurrent] = useState(0);
  const [cancellationReason, setCancellationReason] = useState(false);
  const [isReschedule, setIsReschedule] = useState(false);
  const [isAddRemarks, setIsAddRemarks] = useState({
    isActive: false,
    remarks: "",
  });
  const [isPaymentOffline, setIsPaymentOffline] = useState({
    isActive: false,
    data: "",
  });

  const [basePrice, setBasePrice] = useState(data?.booking?.basePrice || "");
  const [debouncedBasePrice, setDebouncedBasePrice] = useState(basePrice);

  // Debounced value to avoid frequent API calls
  // To avoid API call on initial render
  // To avoid multiple API calls, we track if the fetch is in progress
  const isFetching = useRef(false);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedBasePrice(basePrice);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [basePrice]);

  // Memoized fetchTotalAmount function
  const fetchTotalAmount = useCallback(
    async (price) => {
      if (!price) return null;

      try {
        const response = await axiosInstance.get(
          `/usedCars/check-total-amount`,
          {
            params: {
              id: data?.booking?._id,
              basePrice: price,
            },
          }
        );

        if (response.data) {
          // notifySuccess("Amount calculated successfully");
          return response.data?.amountToBePaid;
        }
      } catch (error) {
        console.error("Error fetching total amount:", error);
        notifyWarning("Error fetching total amount");
        return null;
      }
    },
    [data?.booking?._id]
  );

  // Effect to call fetchTotalAmount when debouncedBasePrice changes
  useEffect(() => {
    const calculateAmount = async () => {
      if (debouncedBasePrice && !isFetching.current) {
        const amountToBePaid = await fetchTotalAmount(debouncedBasePrice);
        if (amountToBePaid !== null) {
          setIsPaymentOffline((prev) => ({
            ...prev,
            data: {
              ...prev.data,
              amountPaid: amountToBePaid,
              basePrice: debouncedBasePrice,
            },
          }));
        }
      }
    };

    if (debouncedBasePrice !== data?.booking?.basePrice) {
      calculateAmount();
    }
  }, [debouncedBasePrice, fetchTotalAmount, data?.booking?.basePrice]);

  // Handle base price change
  const handleBasePriceChange = (e) => {
    setBasePrice(e.target.value);
  };

  useEffect(() => {
    setCurrent(currentStepInfo);
  }, [currentStepInfo, data]);

  const handleUpdate = (status) => {
    onUpdate(status, data);
  };

  useEffect(() => {
    const remarks = data?.booking?.statusHistory?.find(
      (ele) => ele?.remarks != ""
    );
    setIsAddRemarks({ ...isAddRemarks, remarks: remarks?.remarks });
    setBasePrice(data?.booking?.basePrice);
  }, [data]);

  useEffect(() => {
    setIsPaymentOffline((prev) => {
      return {
        ...prev,
        data: data?.booking?.offlinePaymentDetails,
      };
    });
  }, [data]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name == "paymentDate") {
      const isoFormattedDate = new Date(value).toISOString();
      setIsPaymentOffline((prevData) => ({
        ...prevData,
        data: {
          ...prevData.data,
          [name]: isoFormattedDate,
        },
      }));
    } else {
      setIsPaymentOffline((prevData) => ({
        ...prevData,
        data: {
          ...prevData.data,
          [name]: value,
        },
      }));
    }
  };

  return (
    <>
      <div className="rounded-lg p-6 min-w-[350px] mx-auto bg-[#EDEDEDED]">
        <div className="space-y-4 mb-4 p-3 bg-white rounded-lg shadow-md max-w-full">
          <div className="flex justify-between items-center border-b pb-2">
            <span className="font-semibold text-gray-800 text-sm">Name:</span>
            <span className="text-gray-600 text-sm">
              {data?.buyerDetail?.firstName + " " + data?.buyerDetail?.lastName}
            </span>
          </div>

          <div className="flex justify-between items-center border-b pb-3">
            <span className="font-semibold text-gray-800 text-sm">
              Email Id:
            </span>
            <span className="text-gray-600 text-sm">
              {data?.buyerDetail?.email}
            </span>
          </div>

          <div className="flex justify-between items-center border-b pb-3">
            <span className="font-semibold text-gray-800 text-sm">
              Phone Number:
            </span>
            <span className="text-gray-600 text-sm">
              {data?.buyerDetail?.phone}
            </span>
          </div>

          <div className="flex justify-between items-center border-b pb-3">
            <span className="font-semibold text-gray-800 text-sm">Model:</span>
            <span className="text-gray-600 text-sm">
              {data?.carDetail?.overview?.carName}
            </span>
          </div>

          <div className="flex justify-between items-center border-b pb-3">
            <span className="font-semibold text-gray-800 text-sm">Price:</span>
            <span className="text-gray-600 text-sm">
              {`$${data?.carDetail?.overview?.price}`}
            </span>
          </div>

          <div className="flex justify-between items-center border-b pb-3">
            <span className="font-semibold text-gray-800 text-sm">
              Appointment Date:
            </span>
            <span className="text-gray-600 text-sm">
              {new Date(data?.booking?.testDrive?.date).toLocaleString()}
            </span>
          </div>
          <div className="flex justify-between items-center border-b pb-3">
            <span className="font-semibold text-gray-800 text-sm">
              Appointment Time:
            </span>
            <span className="text-gray-600 text-sm">
              {data?.booking?.testDrive?.timeSlot}
            </span>
          </div>
          <div className="flex justify-between items-center border-b pb-3">
            <span className="font-semibold text-gray-800 text-sm">
              Payment Status:
            </span>
            <span className="text-gray-600 text-sm">
              {data?.booking?.paymentStatus}
              {data?.booking?.paymentDetails?.totalAmount > 0
                ? ` $${data.booking.paymentDetails.totalAmount} Online`
                : data?.booking?.offlinePaymentDetails?.amountPaid > 0
                ? ` $${data.booking.offlinePaymentDetails.amountPaid} Offline`
                : ""}
            </span>
          </div>

          {/* <div className="flex justify-between items-center border-b pb-3">
            <span className="font-semibold text-gray-800 text-sm">
              Added Remark:
            </span>
            <span className="text-gray-600 text-sm">
              {isAddRemarks?.remarks || "No added remarks"}
            </span>
          </div> */}

          {(currentStepInfo == 2 || currentStepInfo == 1) && (
            <div className="flex justify-between items-center border-b pb-3">
              <span className="font-semibold text-gray-800 text-sm">
                Remarks:
              </span>
              <span
                onClick={() =>
                  setIsAddRemarks({ ...isAddRemarks, isActive: true })
                }
                className="text-blue-600 text-sm cursor-pointer underline"
              >
                Add remarks
              </span>

              {isAddRemarks.isActive && (
                <>
                  <Dialog
                    open={isAddRemarks.isActive}
                    onClose={() =>
                      setIsAddRemarks({ ...isAddRemarks, isActive: false })
                    }
                    className="relative z-50"
                  >
                    <div className="fixed inset-0 bg-black/70 flex w-screen items-center justify-center p-4">
                      <Dialog.Panel className="w-full flex justify-center items-center rounded">
                        <div className="max-h-[40vh] w-[400px] rounded-md relative bg-[#F5F5F5] p-4">
                          <div className="p-2">
                            <img
                              src={icons8crossFilled}
                              onClick={() =>
                                setIsAddRemarks({
                                  ...isAddRemarks,
                                  isActive: false,
                                })
                              }
                              className="w-8 h-8 absolute right-2 top-2 cursor-pointer"
                              alt="Close"
                            />
                          </div>
                          <div className="mt-0 p-4">
                            <div className="flex justify-center items-center min-w-full">
                              <div>
                                <label>Enter your remarks</label>
                                <textarea
                                  type="text"
                                  name="remarks"
                                  id="remarks"
                                  value={isAddRemarks?.remarks}
                                  onChange={(e) =>
                                    setIsAddRemarks({
                                      ...isAddRemarks,
                                      remarks: e.target.value,
                                    })
                                  }
                                  className="w-full border border-gray-300 rounded mt-2 mb-4"
                                />
                                <PrimaryButton
                                  type="button"
                                  onClick={() =>
                                    onUpdate(
                                      {
                                        remarks: isAddRemarks.remarks,
                                      },
                                      {
                                        _id: data?.booking?._id,
                                      }
                                    ).then(() => {
                                      setIsAddRemarks({
                                        ...isAddRemarks,
                                        isActive: false,
                                      });
                                    })
                                  }
                                  disabled={loading}
                                  loading={loading}
                                  className={`${
                                    loading ? "bg-gray-500" : "bg-[#990000]"
                                  } hover:bg-[#990000] text-white font-semibold w-full`}
                                  title={"Add Remarks"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Dialog.Panel>
                    </div>
                  </Dialog>
                </>
              )}
            </div>
          )}

          {data?.booking?.testDrive?.proposedDate && (
            <div className="flex justify-between items-center border-b pb-3">
              <span className="font-semibold text-gray-800 text-sm">
                Proposed Date:
              </span>
              <span className="text-gray-600 text-sm flex items-center gap-2">
                {new Date(
                  data?.booking?.testDrive?.proposedDate
                ).toLocaleString()}
                {data?.booking?.testDrive?.status === "rescheduleConfirmed" && (
                  <span className="text-green-600">
                    <IoIosCheckmarkCircle />
                  </span>
                )}
              </span>
            </div>
          )}

          <div className="text-center mt-4">
            <p className="text-gray-700 italic text-sm">{description?.data}</p>
          </div>

          {current == 3 && (
            <>
              {data?.currentStatus == "dealClosed" ? (
                <h1 className="text-center font-medium">
                  Deal has been already closed
                </h1>
              ) : (
                <>
                  <div className="flex justify-center space-x-4">
                    <PrimaryButton
                      onClick={() => handleUpdate({ status: "dealClosed" })}
                      title="Close Deal"
                      loading={loading}
                      disabled={loading}
                      className="w-36"
                    />

                    <button
                      onClick={() => setCurrentStepInfo()}
                      className="w-36 bg-transparent border border-gray-700 text-gray-700 px-4 py-2 rounded hover:bg-gray-400"
                    >
                      Cancel
                    </button>
                  </div>
                </>
              )}
            </>
          )}

          {current == 2 && (
            <>
              <div className="flex justify-center space-x-4">
                {data?.currentStatus == "reserved" ||
                data?.currentStatus == "dealClosed" ? (
                  <h1 className="font-medium">Already reserved</h1>
                ) : (
                  <>
                    <PrimaryButton
                      onClick={() => handleUpdate({ status: "reserved" })}
                      title="Reserve"
                      // loading={loading}
                      loading={
                        buttonLoading?.status &&
                        buttonLoading?.hasOwnProperty("reserved")
                      }
                      disabled={loading}
                    />

                    <PrimaryButton
                      onClick={() => setIsReschedule(!isReschedule)}
                      title="Propose new date"
                    />
                  </>
                )}
              </div>
            </>
          )}

          {current == 5 && (
            <>
              <PrimaryButton
                type="button"
                onClick={() =>
                  handleUpdate({
                    checkPaymentStatus: true,
                  })
                }
                disabled={loading}
                loading={loading}
                className={`${
                  loading ? "bg-gray-500" : "bg-[#990000]"
                } hover:bg-[#990000] text-white font-semibold w-full`}
                title={"Check Payment Status"}
              />
            </>
          )}

          {current == 6 && (
            <div className="flex flex-col gap-2 items-center">
              <PrimaryButton
                type="button"
                onClick={() =>
                  handleUpdate({
                    checkPaymentStatus: true,
                  })
                }
                disabled={
                  buttonLoading?.status &&
                  buttonLoading?.hasOwnProperty("checkPaymentStatus")
                }
                loading={
                  buttonLoading?.status &&
                  buttonLoading?.hasOwnProperty("checkPaymentStatus")
                }
                className={`${
                  loading ? "bg-gray-500" : "bg-[#990000]"
                } hover:bg-[#990000] text-white w-80 h-18`}
                title={"Check Status"}
              />
              <PrimaryButton
                onClick={() =>
                  handleUpdate(
                    {
                      generatePaymentLink: {
                        amount: +data?.carDetail?.overview?.price,
                      },
                    },
                    null
                  )
                }
                title="Send Payment Request"
                loading={
                  buttonLoading?.status &&
                  buttonLoading?.hasOwnProperty("generatePaymentLink")
                }
                className={`${
                  loading ? "bg-gray-500" : "bg-[#990000]"
                } hover:bg-[#990000] text-white w-80 h-18`}
                disabled={
                  buttonLoading?.status &&
                  buttonLoading?.hasOwnProperty("generatePaymentLink")
                }
              />

              <PrimaryButton
                onClick={() =>
                  setIsPaymentOffline((prev) => {
                    return { ...prev, isActive: true };
                  })
                }
                title="Pay by cash"
                className={`${
                  loading ? "bg-gray-500" : "bg-[#990000]"
                } hover:bg-[#990000] text-white w-80 h-18`}
                disabled={loading}
              />
            </div>
          )}

          {current == 4 && (
            <>
              <div className="flex justify-center space-x-4">
                <div className="w-full">
                  <textarea
                    value={data?.cancellationReason}
                    onChange={(e) =>
                      setData({ ...data, cancellationReason: e.target.value })
                    }
                    placeholder="Enter rejection reason"
                    className="w-full border border-gray-300 rounded mt-2"
                    rows="4"
                  />

                  <PrimaryButton
                    type="button"
                    onClick={() =>
                      handleUpdate({
                        status: "rejected",
                        cancellationReason: data?.cancellationReason,
                      })
                    }
                    disabled={loading}
                    loading={loading}
                    className={`${
                      loading ? "bg-gray-500" : "bg-[#990000]"
                    } hover:bg-[#990000] text-white font-semibold w-full`}
                    title={"Submit"}
                  />
                </div>
              </div>
            </>
          )}
        </div>

        {isReschedule && (
          <RescheduleForm
            isReschedule={isReschedule}
            setIsReschedule={setIsReschedule}
            data={data}
            handleUpdate={onUpdate}
          />
        )}

        {isPaymentOffline && (
          <Dialog
            open={isPaymentOffline.isActive}
            onClose={() =>
              setIsPaymentOffline((prev) => {
                return { ...prev, isActive: false };
              })
            }
            className="relative z-50"
          >
            <div className="fixed inset-0 bg-black/70 flex w-screen items-center justify-center p-4">
              <Dialog.Panel className="w-full flex justify-center items-center rounded">
                <div className="max-h-[60vh] w-[400px] rounded-md relative bg-[#F5F5F5] p-4">
                  <div className="p-2">
                    <img
                      src={icons8crossFilled}
                      onClick={() =>
                        setIsPaymentOffline((prev) => {
                          return { ...prev, isActive: false };
                        })
                      }
                      className="w-8 h-8 absolute right-2 top-2 cursor-pointer"
                      alt="Close"
                    />
                  </div>

                  <div className="mt-0 p-4">
                    <p className="p-1 pb-4 text-center m-auto font-semibold">
                      Offline Payment Details
                    </p>

                    <div className="justify-center items-center min-w-full">
                      <form className="space-y-4 mb-6">
                        <div>
                          <label className="block mb-1 font-medium">
                            Payment Method
                          </label>
                          <select
                            name="paymentMethod"
                            value={isPaymentOffline?.data?.paymentMethod}
                            onChange={handleInputChange}
                            className="border p-2 rounded-md w-full"
                          >
                            <option value="">Select Method</option>
                            <option value="cash">Cash</option>
                            <option value="card">Card</option>
                            <option value="bankTransfer">Bank Transfer</option>
                          </select>
                        </div>

                        <div>
                          <label className="block mb-1 font-medium">
                            Payment Date
                          </label>
                          <input
                            type="date"
                            name="paymentDate"
                            value={
                              isPaymentOffline?.data?.paymentDate?.split(
                                "T"
                              )[0] || ""
                            }
                            onChange={handleInputChange}
                            className="border p-2 rounded-md w-full"
                            required
                          />
                        </div>

                        <div className="mb-4">
                          <label className="block mb-1 font-medium">
                            Base Price
                          </label>
                          <input
                            type="number"
                            value={basePrice}
                            onChange={handleBasePriceChange}
                            className="border p-2 rounded-md w-full"
                            placeholder="Enter base price"
                            required
                            min={0}
                            step={1}
                            onKeyDown={(e) => {
                              if (e.code === "Minus") {
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>

                        <div>
                          <label className="block mb-1 font-medium">
                            Amount Paid
                          </label>
                          <input
                            type="number"
                            name="amountPaid"
                            value={isPaymentOffline?.data?.amountPaid}
                            // value={paymentDetails.amountPaid}
                            onChange={handleInputChange}
                            className="border p-2 rounded-md w-full"
                            placeholder="Enter amount"
                            required={true}
                            min={0}
                            step={1}
                            onKeyDown={(e) => {
                              if (e.code === "Minus") {
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                      </form>

                      <Button
                        type="submit"
                        onClick={() => {
                          if (
                            !isPaymentOffline?.data?.paymentMethod ||
                            !isPaymentOffline?.data?.paymentDate ||
                            !isPaymentOffline?.data?.amountPaid
                          ) {
                            notifyWarning("Please fill all required fields");
                            return;
                          }

                          setIsPaymentOffline((prevData) => {
                            return {
                              ...prevData,
                              data: {
                                ...prevData.data,
                                basePrice: basePrice,
                              },
                            };
                          });

                          handleUpdate({
                            offlinePaymentConfirmed: true,
                            offlinePaymentDetails: isPaymentOffline.data,
                          });
                        }}
                        disabled={loading}
                        className={`${
                          loading ? "bg-gray-500" : "bg-[#990000]"
                        } hover:bg-[#990000] text-white font-semibold h-11 w-full`}
                        title={"Submit"}
                      >
                        {loading ? (
                          <AiOutlineLoading className="h-6 w-6 animate-spin m-auto" />
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </div>
          </Dialog>
        )}

        {/**/}
      </div>
    </>
  );
};
