import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  setSpareParts,
  setLoading,
  addSparePartSuccess,
  deleteSparePartSuccess,
  updateSparePartSuccess,
} from "./sparePartsSlice";
import { spareParts } from "../utility/api";
import axiosInstance, {
  notifyError,
  notifySuccess,
} from "../utility/axiosInstance";
import { toast } from "react-toastify";

export const fetchSpareParts = createAsyncThunk(
  "spareParts/fetchSpareParts",
  async (_, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axiosInstance.get(`${spareParts.getAllSpareParts}/admin`);

      console.log(response.data.data,'responseresponse')

      dispatch(setSpareParts(response.data.data));
    } catch (error) {
      notifyError(`Failed to fetch spare part:, ${error}`);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const addSparePart = createAsyncThunk(
  "spareParts/addSparePart",
  async (newSparePart, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axiosInstance.post(
        spareParts.createSparePart,
        newSparePart
      );

      dispatch(addSparePartSuccess(response.data));
    } catch (error) {
      notifyError(`Failed to add spare part:, ${error}`);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const deleteSparePart = createAsyncThunk(
  "spareParts/deleteSparePart",
  async (id, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      await axiosInstance.delete(`${spareParts?.deleteSparePart}/${id}`);
      dispatch(fetchSpareParts());
    } catch (error) {
      notifyError(`Failed to delete spare part:, ${error}`);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const updateSparePart = createAsyncThunk(
  "spareParts/updateSparePart",
  async ({ id, updatedSparePart }, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axiosInstance.put(
        `${spareParts?.updateSpareParts}/${id}`,
        updatedSparePart
      );
      dispatch(fetchSpareParts());
      notifySuccess("Spare part has been updated");
      //   toast.success("Spare part has been updated", {
      //     theme: "colored",
      //     style: {
      //       backgroundColor: "#990000",
      //     },
      //   });
      //   dispatch(updateSparePartSuccess(response.data));
    } catch (error) {
      notifyError(`Failed to update spare part:,${error}`);
    } finally {
      dispatch(setLoading(false));
    }
  }
);
