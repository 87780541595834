import React from "react";
import PrimaryButton from "../utils/buttons/primaryButton";
import OrderHistoryTable from "./OrderHistoryTable";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  fetchOrders,
  deliverOrder,
  updateOrder,
} from "../../redux/ordersThunk";

export const OrderHistory = () => {
  const dispatch = useDispatch();
  const { list, loading } = useSelector((state) => state?.orders);
  const [data, setData] = useState([]);
  const [mappedData, setMappedData] = useState([]);

  useEffect(() => {
    dispatch(
      fetchOrders({
        filterBy: "accepted",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (list) {
      setData(list);
    }
  }, [list]);

  useEffect(() => {
    const mapped = data?.map((ele) => {
      return {
        _id: ele?._id,
        name: ele?.user?.firstName + " " + ele?.user?.lastName,
        date: ele?.requestedAt?.split("T")[0],
        price: ele?.item?.price,
        orderStatus: ele?.deliveryStatus,
        location: ele?.deliveryDetails?.deliveryAddress,
        quantity: ele?.quantity,
        totalPrice: ele?.totalPrice,
      };
    });

    setMappedData(mapped);
  }, [data]);

  const handleClick = (status, id) => {
    const updatedOrder = {
      deliveryStatus: status,
      deliveredBy: "6683ae5bcead352f68e98f20",
    };
    dispatch(deliverOrder({ id: id, updatedOrder }));
  };
  return (
    <div className="h-[calc(100vh-120px)] overflow-y-auto">
      <div className="flex px-5 py-2 h-[80px] justify-between items-center border-b-[2px] border-lightBorderColor">
        <p className="text-black text-2xl">Orders</p>
      </div>
      <div className="p-5">
        <OrderHistoryTable data={mappedData} handleClick={handleClick} />
      </div>
    </div>
  );
};
