import React, { useEffect, useState } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";

export const CarImagePreview = ({ images = {}, url = "" }) => {
  //   const [modified, setModified] = useState([]);

  //   useEffect(() => {
  //     let arr = [];
  //     for (let key in images) {
  //       if (
  //         (key == "front" && images[key] != null) ||
  //         (key == "back" && images[key] != null) ||
  //         (key == "left" && images[key] != null) ||
  //         (key == "right" && images[key] != null)
  //       ) {
  //         arr.push(`${url}/` + images[key]);
  //       }
  //     }
  //     setModified(arr);
  //   }, [images]);
  const [modified, setModified] = useState([]);

  useEffect(() => {
    const validKeys = ["front", "back", "left", "right"];
    const arr = Object.entries(images)
      .filter(([key, value]) => validKeys.includes(key) && value)
      .map(([_, value]) => `${url}/${value}`);
    setModified(arr);
  }, [images, url]);

  return (
    <div>
      <Splide
        options={{
          perPage: 1, // Displays 3 cars per slide
          perMove: 1, // Move one slide at a time
          gap: "0rem", // Space between slides
          pagination: false, // Disable pagination indicators
          arrows: true, // Enable left/right arrows
          breakpoints: {
            768: {
              perPage: 1, // Show 1 card per slide on small screens
            },
            1024: {
              perPage: 1, // Show 2 cards per slide on tablets
            },
          },
        }}
        aria-label="Used Cars"
      >
        {modified?.map((src, index) => (
          <SplideSlide
            key={index}
            className={"text-center p-0 flex justify-center items-center"}
          >
            <img
              src={src}
              alt={`Car view ${index + 1}`}
              className="m-auto w-[700px] h-auto object-cover"
            />
          </SplideSlide>
        ))}
      </Splide>
    </div>
  );
};
