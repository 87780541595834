import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { lockPassword, setPassword } from "../../assets";
import { checkPasswordPolicy } from "../../utility/utils";

export const PasswordReset = ({
  newPasswordProp,
  setForgetPassword,
  onSubmit,
  newPasswordValue,
}) => {
  const [newPassword, setNewPassword] = useState(newPasswordProp || "");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordPolicy, setPasswordPolicy] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    numeric: false,
    specialChar: false,
  });

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  useEffect(() => {
    if (
      newPasswordValue &&
      confirmPassword &&
      newPasswordValue !== confirmPassword
    ) {
      setErrorMessage("Passwords do not match");
    } else {
      setErrorMessage("");
    }
  }, [newPasswordValue, confirmPassword]);

  const handleSave = () => {
    if (!newPasswordValue) {
      setErrorMessage("Please enter a new password");
      return;
    }

    if (!confirmPassword) {
      setErrorMessage("Please confirm your new password");
      return;
    }

    if (newPasswordValue !== confirmPassword) {
      console.log(newPasswordValue, confirmPassword, "debug");

      setErrorMessage("Passwords do not match");
      return;
    }

    if (
      !passwordPolicy.length ||
      !passwordPolicy.lowercase ||
      !passwordPolicy.uppercase ||
      !passwordPolicy.numeric
    ) {
      setErrorMessage(
        "Password must contain at least 6 characters, including uppercase, lowercase, numbers"
      );
      return;
    }

    onSubmit();
    // setNewPassword("");
    // setConfirmPassword("");
    setErrorMessage("");
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <h2 className="font-bold text-xl">Reset Password</h2>
      <div className="bg-white p-6 w-full max-w-md mx-auto">
        <img
          src={setPassword}
          alt="Reset Password Illustration"
          className="w-full h-auto mb-6"
        />

        {errorMessage && (
          <div className="bg-[#F2030B1A] text-white text-center py-0.5 rounded-lg m-6">
            <p className="text-lg text-red-500">{errorMessage}</p>
          </div>
        )}

        <div className="flex justify-center space-x-2 mb-6">
          <div className="flex flex-col space-y-4 w-full max-w-md">
            <div className="relative">
              <div className="absolute top-0 my-2.5 pl-2">
                <img src={lockPassword} className="h-5 w-4 top-0" />
              </div>
              <input
                id="password"
                type={showPassword ? "text" : "password"}
                className="px-8 py-2 rounded-md border border-gray-300 focus:outline-none w-full"
                placeholder="Password"
                value={newPasswordValue}
                onChange={(e) => {
                  setForgetPassword((prevState) => ({
                    ...prevState,
                    newPassword: e.target.value,
                  }));

                  const {
                    hasLength,
                    hasSpecialChar,
                    hasNumber,
                    hasUppercase,
                    hasLowercase,
                  } = checkPasswordPolicy(e.target.value);

                  setPasswordPolicy({
                    length: hasLength?.status,
                    uppercase: hasUppercase?.status,
                    lowercase: hasLowercase?.status,
                    numeric: hasNumber?.status,
                    specialChar: hasSpecialChar?.status,
                  });
                }}
              />

              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute top-0 end-0 p-3.5 rounded-e-md"
              >
                <svg
                  className="flex-shrink-0 size-3.5 text-gray-400 dark:text-neutral-600"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path
                    className={
                      !showPassword
                        ? "hs-password-active:hidden"
                        : "hs-password-active:block"
                    }
                    d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"
                  />
                  <circle
                    className={
                      !showPassword
                        ? "hs-password-active:hidden"
                        : "hs-password-active:block"
                    }
                    cx="12"
                    cy="12"
                    r="3"
                  />
                  <path
                    className={
                      !showPassword
                        ? "hidden hs-password-active:block"
                        : "hs-password-active:hidden"
                    }
                    d="M9.88 9.88a3 3 0 1 0 4.24 4.24"
                  />
                  <path
                    className={
                    !showPassword
                        ? "hidden hs-password-active:block"
                        : "hs-password-active:hidden"
                    }
                    d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68"
                  />
                  <path
                    className={
                      !showPassword
                        ? "hidden hs-password-active:block"
                        : "hs-password-active:hidden"
                    }
                    d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61"
                  />
                  <line
                    className={
                      !showPassword
                        ? "hidden hs-password-active:block"
                        : "hs-password-active:hidden"
                    }
                    x1="2"
                    x2="22"
                    y1="2"
                    y2="22"
                  />
                </svg>
              </button>
            </div>

            <div className="mb-6 relative">
              <label
                htmlFor="confirmPassword"
                className="block text-sm dark:text-white"
              >
                Confirm Password
              </label>
              <div className="absolute my-2.5 pl-2">
                <img src={lockPassword} className="h-5 w-4 top-6" />
              </div>
              <input
                id="confirmPassword"
                type={showPassword ? "text" : "password"}
                className="px-8 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 w-full"
                placeholder="Confirm new password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              {/* {errorMessage && (
                <label className="block text-sm text-[#F2030B]">{errorMessage}</label>
              )} */}
            </div>

            <p className="text-sm font-semibold list-none">
              Password Security Policy:
            </p>

            <ul className="list-disc pl-4 text-gray-500 text-sm">
              <li
                className={`${
                  passwordPolicy?.length && "text-green-500 font-semibold"
                }`}
              >
                At Least 6 Characters
              </li>
              <li
                className={`${
                  passwordPolicy?.lowercase && "text-green-500 font-semibold"
                }`}
              >
                At Least One Lowercase Letter (a-z)
              </li>
              <li
                className={`${
                  passwordPolicy?.uppercase && "text-green-500 font-semibold"
                }`}
              >
                At Least One Uppercase Letter (A-Z)
              </li>
              <li
                className={`${
                  passwordPolicy?.numeric && "text-green-500 font-semibold"
                }`}
              >
                At Least One Number (0-9)
              </li>
            </ul>
          </div>
        </div>

        <div className="text-center">
          <button
            onClick={handleSave}
            className="w-full text-center m-auto p-4 bg-[#990000] text-white py-2 rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 dark:focus:ring-red-300 mb-4"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

PasswordReset.propTypes = {
  newPasswordProp: PropTypes.string, // Optional: Pass initial new password value from parent
  newPasswordValue: PropTypes.string, // Optional:
};
