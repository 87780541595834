import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance, {
  notifyError,
  notifySuccess,
} from "../utility/axiosInstance";
import {
  setServices,
  addService,
  deleteService,
  updateService,
  setLoading,
  setServiceSettingInfo,
  setAdditionServiceInfo,
} from "./serviceSlice";
import { services } from "../utility/api";
import { toast } from "react-toastify";

export const fetchServicesSettingInfo = createAsyncThunk(
  "servicesSetting/getAllServiceSettingInfo",
  async (_, { dispatch }) => {
    // dispatch(setLoading(true));
    try {
      const response = await axiosInstance.get(
        "servicesSetting/getAllServiceSettingInfo"
      );
      dispatch(setServiceSettingInfo(response?.data?.ServiceRequestSettings));
    } catch (error) {
      console.error("Failed to fetch services:", error);
    } finally {
      // dispatch(setLoading(false));
    }
  }
);
export const fetchAdditionServicesInfo = createAsyncThunk(
  "serviceRequest/getAdditionServiceInfo",
  async (_, { dispatch }) => {
    // dispatch(setLoading(true));
    try {
      const response = await axiosInstance.get(
        "serviceRequest/getAdditionServiceInfo"
      );
      dispatch(setAdditionServiceInfo(response?.data));
    } catch (error) {
      console.error("Failed to fetch services:", error);
    } finally {
      // dispatch(setLoading(false));
    }
  }
);
export const fetchServices = createAsyncThunk(
  "services/fetchServices",
  async ({ currentPage, limit, search }, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axiosInstance.get(
        `${services.getAllServices}?page=${currentPage}&limit=${limit}${
          search && `&search=${search}`
        }`
      );
      dispatch(setServices(response?.data));
      return response?.data;
    } catch (error) {
      console.error("Failed to fetch services:", error);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const createService = createAsyncThunk(
  "services/createService",
  async (newService, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axiosInstance.post(
        services.createService,
        newService
      );
      // dispatch(addService(response?.data?.service));
      dispatch(fetchServices());
      notifySuccess("Service has been created");
    } catch (error) {
      notifyError(`Failed to create service:, ${error}`);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const modifyService = createAsyncThunk(
  "services/modifyService",
  async (updatedService, { dispatch }) => {
    console.log(updatedService, "updated");
    dispatch(setLoading(true));
    try {
      await axiosInstance.put(
        `${services.updateServices}/${updatedService._id}`,
        updatedService
      );

      // dispatch(fetchServices())

      notifySuccess("Service has been updated");
    } catch (error) {
      notifyError(`Failed to update service:, ${error}`);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const deleteServices = createAsyncThunk(
  "services/deleteService",
  async (_id, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      await axiosInstance.delete(`${services.updateServices}/${_id}`);
      dispatch(fetchServices());
      notifySuccess("Service has been deleted");
    } catch (error) {
      notifyError(`Failed to delete service:, ${error}`);
    } finally {
      dispatch(setLoading(false));
    }
  }
);
