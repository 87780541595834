import { Steps } from "rsuite";
import "rsuite/Steps/styles/index.css";
import { useState, useEffect } from "react";
import { BookingStepsUpdateCard } from "./BookingStepsUpdateCard";
import "./css/custom-steps.css";
import { formatString } from "../../utility/api";

const bookingSteps = {
  requested: 0,
  accepted: 1,
  reserved: 2,
  //   reserveCompleted: 3, // New status for Reserve Completion
  dealClosed: 3,
  rejected: 4,
};

const BookingSteps = ({
  currentStatus,
  onUpdate,
  data,
  setData,
  isCancellation,
  setIsReject,
  isReject,
  loading,
  buttonLoading,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [currentStepInfo, setCurrentStepInfo] = useState(0);

  useEffect(() => {
    if (bookingSteps[currentStatus] !== undefined) {
      setCurrentStep(bookingSteps[currentStatus]);
      setCurrentStepInfo(bookingSteps[currentStatus]);
    }
  }, [currentStatus]);

  const handleStepClick = (index) => {
    setCurrentStepInfo(index);
  };

  const getStatusDescription = (status, secondStatus) => {
    const historyEntry = data?.booking.statusHistory.find(
      (entry) => entry.status === status || entry.status === secondStatus
    );

    const isDealClosed = currentStatus == "dealClosed";

    return {
      data: historyEntry
        ? `${formatString(status)} at ${new Date(
            historyEntry.timestamp
          ).toLocaleString()}`
        : isDealClosed
        ? "Deal Closed"
        : "Not yet completed",

      status: historyEntry ? status : false,
    };
  };

  const statusHistory = data?.booking?.statusHistory || [];

  const hasPaymentStatus = statusHistory.some(
    (ele) =>
      ele.status === "paymentRequested" || ele.status === "paymentCompleted"
  );

  const isPaymentCompleted = statusHistory.some(
    (ele) => ele.status === "paymentCompleted"
  );

  const isCancelledByUser = data?.booking?.status === "cancelled";

  return (
    <div className="flex gap-8 justify-around items-center">
      <div className="max-h-[65vh] overflow-auto no-scrollbar p-2">
        <Steps
          current={currentStep}
          vertical
          className="custom-step rs-theme-light"
        >
          <Steps.Item
            className="cursor-pointer"
            onClick={() => handleStepClick(0)}
            title="Request"
            description={getStatusDescription("Requested")?.data}
            status={
              currentStatus === "accepted" ||
              currentStatus === "reserved" ||
              currentStatus === "dealClosed" ||
              currentStatus === "rejected" ||
              currentStatus == "cancelled"
                ? "finish"
                : "wait"
            }
          />

          <Steps.Item
            className="cursor-pointer"
            onClick={() => handleStepClick(1)}
            title="Acceptance"
            description={getStatusDescription("Accepted")?.data}
            status={
              getStatusDescription("Accepted").status == "Accepted" ||
              currentStatus == "dealClosed" ||
              currentStatus == "reserved"
                ? "finish"
                : "wait"
            }
          />

          {(data?.booking?.testDrive?.status == "rescheduleProposed" ||
            data?.booking?.testDrive?.status == "rescheduleConfirmed") && (
            <>
              <Steps.Item
                className="cursor-pointer"
                title="Awaiting Confirmation"
                description={formatString(data?.booking?.testDrive?.status)}
                status={
                  data?.booking?.testDrive?.status == "rescheduleConfirmed"
                    ? "finish"
                    : "wait"
                }
              />
            </>
          )}

          {!isCancelledByUser && (
            <Steps.Item
              className="cursor-pointer"
              onClick={() => handleStepClick(2)}
              title="Reservation"
              description={getStatusDescription("Reserved")?.data}
              status={
                currentStatus == "dealClosed" || currentStatus == "reserved"
                  ? "finish"
                  : "wait"
              }
            />
          )}

          {!isCancelledByUser && data?.booking?.status == "reserved" && (
            <Steps.Item
              className="cursor-pointer"
              onClick={() => handleStepClick(6)}
              title="Payment"
              description={
                getStatusDescription("paymentRequested", "paymentCompleted")
                  ?.data
              }
              status={
                // getStatusDescription("Accepted").status == "Accepted" ||
                hasPaymentStatus ? "finish" : "wait"
              }
            />
          )}

          {hasPaymentStatus && (
            <>
              <Steps.Item
                className="cursor-pointer"
                onClick={() => handleStepClick(5)}
                title="Payment Confirmation"
                description={
                  isPaymentCompleted
                    ? "Payment completed"
                    : "Payment request intiated but not yet completed"
                }
                status={isPaymentCompleted ? "finish" : "wait"}
              />
            </>
          )}

          {!isCancelledByUser && (
            <Steps.Item
              className="cursor-pointer"
              onClick={() => handleStepClick(3)}
              title="Completion"
              description={getStatusDescription("dealClosed")?.data}
              status={
                getStatusDescription("dealClosed").status == "dealClosed"
                  ? "finish"
                  : "wait"
              }
            />
          )}

          {currentStatus !== "dealClosed" &&
            !isCancelledByUser &&
            !isPaymentCompleted && (
              <Steps.Item
                className="cursor-pointer"
                onClick={() => handleStepClick(4)}
                title="Rejection"
                description={getStatusDescription("Rejected")?.data}
                status={currentStatus === "rejected" ? "error" : "wait"}
              />
            )}

          {data?.booking?.status == "cancelled" && (
            <Steps.Item
              className="cursor-pointer"
              // onClick={() => handleStepClick(4)}
              title="Cancelled"
              description={"Booking has been cancelled by user"}
              status={data?.booking?.status == "cancelled" && "finish"}
            />
          )}
        </Steps>
      </div>
      <div>
        <div className="w-full bg-[#EDEDEDED]">
          {/* Dynamic rendering of the update card based on the current step */}
          {currentStepInfo === 0 && (
            <BookingStepsUpdateCard
              currentStepInfo={currentStepInfo}
              onUpdate={onUpdate}
              data={data}
            />
          )}
          {currentStepInfo === 1 && (
            <div>
              <h2 className="text-center text-lg p-2">Booking Acceptance</h2>
              <BookingStepsUpdateCard
                currentStepInfo={currentStepInfo}
                onUpdate={onUpdate}
                data={data}
                description={
                  (currentStatus == "accepted" ||
                    currentStatus == "reserved" ||
                    currentStatus == "dealClosed") &&
                  getStatusDescription("Accepted")
                }
                loading={loading}
              />
            </div>
          )}
          {currentStepInfo === 2 && (
            <div>
              <h2 className="text-center text-lg p-2">Reserve Status</h2>
              <BookingStepsUpdateCard
                onUpdate={onUpdate}
                data={data}
                currentStepInfo={currentStepInfo}
                description={
                  (currentStatus == "reserved" ||
                    currentStatus == "dealClosed") &&
                  getStatusDescription("Reserved")
                }
                loading={loading}
              />
            </div>
          )}
          {currentStepInfo === 3 && (
            <div>
              <h2 className="text-center text-lg p-2">Completion Status</h2>
              <BookingStepsUpdateCard
                onUpdate={onUpdate}
                data={data}
                currentStepInfo={currentStepInfo}
                description={
                  currentStatus == "dealClosed" &&
                  getStatusDescription("dealClosed")
                }
                isReject={isReject}
                setIsReject={setIsReject}
                setCurrentStepInfo={() => handleStepClick(4)}
                loading={loading}
              />
            </div>
          )}

          {currentStepInfo == 4 && (
            <div>
              <h2 className="text-center text-lg p-2">Rejection Status</h2>
              <BookingStepsUpdateCard
                onUpdate={onUpdate}
                data={data}
                currentStepInfo={currentStepInfo}
                setData={setData}
                loading={loading}
              />
            </div>
          )}

          {currentStepInfo == 5 && (
            <div>
              <h2 className="text-center text-lg p-2">Payment Status</h2>
              <BookingStepsUpdateCard
                onUpdate={onUpdate}
                data={data}
                currentStepInfo={currentStepInfo}
                setData={setData}
                loading={loading}
                buttonLoading={buttonLoading}
              />
            </div>
          )}

          {currentStepInfo == 6 && (
            <div>
              <h2 className="text-center text-lg p-2">Payment Status</h2>
              <BookingStepsUpdateCard
                onUpdate={onUpdate}
                data={data}
                currentStepInfo={currentStepInfo}
                setData={setData}
                loading={loading}
                buttonLoading={buttonLoading}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BookingSteps;
