// @ts-nocheck

import BarCharts from "./graph/chart/BarChart";
import PieCharts from "./graph/chart/PieChart";
import { calenderIcon, statIcon } from "../../../assets";
// import HomeTable from "./table/ServiceRequestTable";
import { useEffect, useMemo, useState } from "react";
import AcceptButton from "../../staticButtons/acceptButton";
import OrderTable from "./table/OrderTable";
// import ServiceRequestTable from "./table/ServiceRequestTable";
import { AppointmentStatistics } from "../../AppointmentStatistics/AppointmentStatistics";
import ServiceRequestTable from "../../Services/ServiceRequestTable";
import { OrderRequestTable } from "../../Orders/OrderRequestTable";
import { useDispatch, UseDispatch, useSelector } from "react-redux";
import {
  setServiceRequestSelected,
  setOrderSelected,
  setUsedCarSelected,
} from "../../../redux/searchSlice";
import UsedCarsRequestTable from "../../UsedCars/UsedCarsRequestTable";
import { UsedCarPieChart } from "../../AppointmentStatistics/UsedCarsPieChart";
import { fetchUserDetails } from "../../../redux/userThunk";
import { UsedCarsEnquiriesTabel } from "../../UsedCars/UsedCarsEnquiriesTabel";

const appointmentData = {
  totalAppointments: 50,
  cancelledAppointments: 5,
  appointmentsByWeek: [
    { day: "Monday", appointments: 10, cancelled: 1 },
    { day: "Tuesday", appointments: 8, cancelled: 1 },
    { day: "Wednesday", appointments: 7, cancelled: 0 },
    { day: "Thursday", appointments: 6, cancelled: 1 },
    { day: "Friday", appointments: 9, cancelled: 1 },
    { day: "Saturday", appointments: 5, cancelled: 1 },
    { day: "Sunday", appointments: 5, cancelled: 0 },
  ],
};

const HomePage = () => {
  const { role } = useSelector((state) => state.user.list);
  const [usedCarsReq, setUsedCarsReq] = useState([]);

  const [activeTab, setActiveTab] = useState<number>(
    role == "carSeller" ? 3 : 1
  );
  const [timePeriod, setTimePeriod] = useState<string>("week");
  const [timePeriodForPie, setTimePeriodForPie] = useState<string>("week");
  const [pieData, setPieData] = useState(appointmentData);

  const [totalAppointments, setTotalAppointments] = useState<number>(0);
  const [totalCancelled, setTotalCancelled] = useState<number>(0);

  const [data, setData] = useState(appointmentData);

  const dispatch = useDispatch();

  const handleTimePeriodChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTimePeriod(e.target.value);
  };

  const handleTimePeriodChange2 = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTimePeriodForPie(e.target.value);
  };

  useEffect(() => {
    if (role === "carSeller") {
      setActiveTab(3);
    } else {
      setActiveTab(1);
    }
  }, [role]);

  useEffect(() => {
    const fetchData = (period: string) => {
      const mockData = {
        week: {
          totalAppointments: 50,
          cancelledAppointments: 5,
          appointmentsByWeek: [
            { day: "Monday", appointments: 10, cancelled: 1 },
            { day: "Tuesday", appointments: 8, cancelled: 1 },
            { day: "Wednesday", appointments: 7, cancelled: 0 },
            { day: "Thursday", appointments: 6, cancelled: 1 },
            { day: "Friday", appointments: 9, cancelled: 1 },
            { day: "Saturday", appointments: 5, cancelled: 1 },
            { day: "Sunday", appointments: 5, cancelled: 0 },
          ],
        },
        month: {
          totalAppointments: 200,
          cancelledAppointments: 20,
          appointmentsByWeek: [
            { day: "Week 1", appointments: 50, cancelled: 5 },
            { day: "Week 2", appointments: 60, cancelled: 6 },
            { day: "Week 3", appointments: 40, cancelled: 4 },
            { day: "Week 4", appointments: 50, cancelled: 5 },
          ],
        },
        year: {
          totalAppointments: 2400,
          cancelledAppointments: 240,
          appointmentsByWeek: [
            { day: "Jan", appointments: 200, cancelled: 20 },
            { day: "Feb", appointments: 180, cancelled: 18 },
            { day: "Mar", appointments: 220, cancelled: 22 },
            { day: "Dec", appointments: 210, cancelled: 21 },
          ],
        },
      };

      setData(mockData[period]);
    };

    fetchData(timePeriod);
  }, [timePeriod]);

  useEffect(() => {
    const fetchPieData = (period: string) => {
      const mockData = {
        week: {
          appointmentsByWeek: [
            { day: "Monday", appointments: 10, cancelled: 1 },
            { day: "Tuesday", appointments: 8, cancelled: 1 },
            { day: "Wednesday", appointments: 7, cancelled: 0 },
            { day: "Thursday", appointments: 6, cancelled: 1 },
            { day: "Friday", appointments: 9, cancelled: 1 },
            { day: "Saturday", appointments: 5, cancelled: 1 },
            { day: "Sunday", appointments: 5, cancelled: 0 },
          ],
        },
        month: {
          appointmentsByWeek: [
            { day: "Week 1", appointments: 50, cancelled: 5 },
            { day: "Week 2", appointments: 60, cancelled: 6 },
            { day: "Week 3", appointments: 40, cancelled: 4 },
            { day: "Week 4", appointments: 50, cancelled: 5 },
          ],
        },
        year: {
          appointmentsByWeek: [
            { day: "Jan", appointments: 200, cancelled: 100 },
            { day: "Feb", appointments: 180, cancelled: 18 },
            { day: "Mar", appointments: 220, cancelled: 22 },
            { day: "Dec", appointments: 210, cancelled: 21 },
          ],
        },
      };

      setPieData(mockData[period]);
    };

    fetchPieData(timePeriodForPie);
  }, [timePeriodForPie]);

  useEffect(() => {
    activeTab == 1
      ? dispatch(setServiceRequestSelected({ isActive: true, searchText: "" }))
      : dispatch(
          setServiceRequestSelected({ isActive: false, searchText: "" })
        );

    activeTab == 2
      ? dispatch(setOrderSelected({ isActive: true, searchText: "" }))
      : dispatch(setOrderSelected({ isActive: false, searchText: "" }));

    activeTab == 3
      ? dispatch(
          setUsedCarSelected({
            isActive: true,
            searchText: "",
          })
        )
      : dispatch(setUsedCarSelected({ isActive: false, searchText: "" }));

    return () => {
      dispatch(setServiceRequestSelected({ isActive: false, searchText: "" }));
      dispatch(setOrderSelected({ isActive: false, searchText: "" }));
      dispatch(setUsedCarSelected({ isActive: false, searchText: "" }));
    };
  }, [dispatch, activeTab]);

  // const fetchCarBookings = useMemo(
  //   () => async () => {
  //     try {
  //       const response = await axiosInstance.get("usedCars/admin/bookings");
  //       return response.data;
  //     } catch (error) {
  //       console.error("Error fetching data", error);
  //       return [];
  //     }
  //   },
  //   []
  // );

  useEffect(() => {
    console.log("rerendered");
  }, []);

  return (
    <div>
      <div className="overflow-y-auto h-[calc(100vh-120px)]">
        <div>
          {role == "carSeller" ? (
            <div>
              {" "}
              <UsedCarPieChart />{" "}
            </div>
          ) : (
            <AppointmentStatistics />
          )}
        </div>

        <div className="mx-5 mt-5">
          <div className="flex gap-4">
            {role !== "carSeller" && (
              <>
                <p
                  onClick={() => {
                    setActiveTab(1);
                  }}
                  className={`px-3 py-1 ${
                    activeTab === 1 ? "bg-redBackground text-white" : "bg-white"
                  } border-borderColor border-[1px] min-w-[100px] flex justify-center items-center rounded-md cursor-pointer z-40`}
                >
                  Services
                </p>
                {/* <p
                  className={`px-3 py-1 ${
                    activeTab === 2 ? "bg-redBackground text-white" : "bg-white"
                  } text-primaryDark border-borderColor border-[1px] min-w-[100px] flex justify-center items-center rounded-md cursor-pointer z-40`}
                  onClick={() => {
                    setActiveTab(2);
                  }}
                >
                  Orders
                </p> */}
              </>
            )}

            <p
              className={`px-3 py-1 ${
                activeTab === 3 ? "bg-redBackground text-white" : "bg-white"
              } text-primaryDark border-borderColor border-[1px] min-w-[100px] flex justify-center items-center rounded-md cursor-pointer z-40`}
              onClick={() => {
                setActiveTab(3);
              }}
            >
              Used Cars
            </p>

            {/* <p
              className={`px-3 py-1 ${
                activeTab === 4 ? "bg-redBackground text-white" : "bg-white"
              } text-primaryDark border-borderColor border-[1px] min-w-[100px] flex justify-center items-center rounded-md cursor-pointer z-40`}
              onClick={() => {
                setActiveTab(4);
              }}
            >
              Used Cars Enquiries
            </p> */}
          </div>
          <div className="w-full py-3">
            {activeTab === 1 ? (
              <ServiceRequestTable />
            ) : activeTab === 2 ? (
              <OrderRequestTable />
            ) : activeTab == 4 ? (
              <UsedCarsEnquiriesTabel />
            ) : (
              (role == "carSeller" || activeTab == 3) && (
                <UsedCarsRequestTable />
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
