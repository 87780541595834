// @ts-nocheck
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Layout from "./component/layout";
import {
  HomePage,
  NotFoundPage,
  ReminderPage,
  ServicePage,
  SparePartPage,
  UsedCarsPage,
} from "./component/pages";
import PrivateRoute from "./component/PrivateRoute";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
// import { login } from "./redux/authSlice";
import Login from "./component/auth/Login";
import { login } from "./redux/authSlice";
import { useNavigate } from "react-router-dom";
import { Services } from "./component/Service/Services";
import { SpareParts } from "./component/SpareParts/SpareParts";
import { UsedCars } from "./component/UsedCars/UsedCars";
import { Chat } from "./component/Chat/Chat";
import { Customers } from "./component/Customers/Customers";
import { Offers } from "./component/Offers/Offers";
import { OrderHistory } from "./component/OrderHistory/OrderHistory";
import { Reminder } from "./component/Reminder/Reminder";
import Privacy from "./component/Privacy/Privacy";
import { UsedCarsEnquiriesTabel } from "./component/UsedCars/UsedCarsEnquiriesTabel";

function App() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);


  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      dispatch(login());
    }
  }, [dispatch, isAuthenticated]);

  

  return (
    <div className="">
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="" element={<NotFoundPage />} />

          <Route
            path="/unauthorized"
            element={
              <div className="m-auto text-center min-h-screen">
                <h1>Unauthorized Access</h1>
                <p>You do not have permission to view this page.</p>
              </div>
            }
          />

          <Route
            element={<PrivateRoute allowedRoles={["admin", "carSeller"]} />}
          >
            <Route path="/home" element={<HomePage />} />
            <Route path="/services" element={<Services />} />
            <Route path="/usedCars" element={<UsedCars />} />
            <Route path="/sparePart" element={<SpareParts />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="/offers" element={<Offers />} />
            <Route path="/reminder" element={<Reminder />} />
            <Route path="/customers" element={<Customers />} />
            <Route path="/orderHistory" element={<OrderHistory />} />
            <Route path="/enquiries" element = {<UsedCarsEnquiriesTabel/>} />
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
