import { Dialog } from "@headlessui/react";
import { useEffect, useRef, useState } from "react";
import PrimaryButton from "../utils/buttons/primaryButton";
import UsedCarCard from "../utils/card/usedCars/usedCarCard";
import ImageUploadButton from "../utils/buttons/imageUploadButton";
import { icons8crossFilled } from "../../assets";
import InputField from "../form/input";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSpareParts,
  addSparePart,
  updateSparePart,
  deleteSparePart,
} from "../../redux/sparePartsThunk";
import { toast } from "react-toastify";
import { InputFields } from "../InputField/InputFIelds";
import { setSparePartsOptionSelected } from "../../redux/searchSlice";
import NoDataFound from "../NoDataFound/NoDataFound";

export const SpareParts = () => {
  const dispatch = useDispatch();
  const { list: spareParts, loading } = useSelector(
    (state) => state.spareParts
  );

  const initialFormData = {
    firstImage: "",
    secondImage: "",
    thirdImage: "",
    productName: "",
    price: "",
    soldBy: "",
    brand: "",
    size: "",
    type: "",
    model: "",
    sectionWidth: "",
  };

  const [addSparePartDialogOpen, setAddSparePartDialogOpen] = useState(false);
  const [editSparePartDialogOpen, setEditSparePartDialogOpen] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [selectedSparePart, setSelectedSparePart] = useState(null);
  const searchTabs = useSelector((state) => state.search);

  const [errors, setErrors] = useState({});

  const firstImageRef = useRef(null);
  const secondImageRef = useRef(null);
  const thirdImageRef = useRef(null);

  useEffect(() => {
    dispatch(fetchSpareParts());
  }, [dispatch]);

  const resetForm = () => {
    setFormData(initialFormData);
    // firstImageRef.current?.clear();
    // secondImageRef.current?.clear();
    // thirdImageRef.current?.clear();
  };

  useEffect(() => {
    dispatch(setSparePartsOptionSelected({ isActive: true, searchText: "" }));
    return () => {
      dispatch(
        setSparePartsOptionSelected({ isActive: false, searchText: "" })
      );
    };
  }, []);

  const getChangedFields = (originalData, formData) => {
    const changedFields = {};

    for (const key in formData) {
      if (formData[key] !== originalData[key]) {
        changedFields[key] = formData[key];
      }
    }

    return changedFields;
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.productName.trim())
      newErrors.productName = "Product Name is required.";
    if (!formData.price.trim() || isNaN(formData.price))
      newErrors.price = "Valid Price is required.";
    if (!formData.soldBy.trim())
      newErrors.soldBy = "Sold By field is required.";
    if (!formData.brand.trim()) newErrors.brand = "Brand is required.";
    if (!formData.size.trim() || isNaN(formData.size))
      newErrors.size = "Valid Size is required.";
    if (!formData.type.trim()) newErrors.type = "Type is required.";
    if (!formData.model.trim()) newErrors.model = "Model is required.";
    if (!formData.sectionWidth.trim())
      newErrors.sectionWidth = "Section Width is required.";

    const images = [
      formData.firstImage,
      formData.secondImage,
      formData.thirdImage,
    ].filter(Boolean);
    if (images.length === 0)
      newErrors.images = "Please upload at least one image.";

    setErrors(newErrors);

    Object.keys(newErrors).forEach((key) => {
      toast.error(newErrors[key]);
    });

    return Object.keys(newErrors).length === 0;
  };

  const handleAddSparePartConfirm = () => {
    if (!validateForm()) return;

    const formData2 = new FormData();

    if (formData?.firstImage) {
      formData2.append("firstImage", formData?.firstImage);
    }
    if (formData?.secondImage) {
      formData2.append("secondImage", formData?.secondImage);
    }
    if (formData?.thirdImage) {
      formData2.append("thirdImage", formData?.thirdImage);
    }

    formData2.append("productName", formData.productName);
    formData2.append("price", formData.price);
    formData2.append("soldBy", formData.soldBy);
    formData2.append("brand", formData.brand);
    formData2.append("size", formData.size);
    formData2.append("type", formData.type);
    formData2.append("model", formData.model);
    formData2.append("sectionWidth", formData.sectionWidth);

    dispatch(addSparePart(formData2)).then(() => {
      setAddSparePartDialogOpen(false);
      resetForm();
    });
  };

  const handleDeleteSparePart = (id) => {
    dispatch(deleteSparePart(id));
  };

  const handleEditSpare = (part) => {
    setFormData({
      firstImage: part?.firstImage || "",
      secondImage: part?.secondImage || "",
      thirdImage: part?.thirdImage || "",
      productName: part.productName,
      price: part.price,
      soldBy: part.soldBy,
      brand: part.brand,
      size: part.size,
      type: part.type,
      model: part.model,
      sectionWidth: part.sectionWidth,
    });
    setSelectedSparePart(part);
    setEditSparePartDialogOpen(true);
  };

  const handleUpdateSparePart = () => {
    // if (!validateForm()) return;

    const changes = getChangedFields(selectedSparePart, formData);

    const formData2 = new FormData();
    formData2.append("_id", selectedSparePart._id);

    if (formData?.firstImage) {
      formData2.append("firstImage", changes?.firstImage);
    }
    if (formData?.secondImage) {
      formData2.append("secondImage", changes?.secondImage);
    }
    if (formData?.thirdImage) {
      formData2.append("thirdImage", changes?.thirdImage);
    }

    if (changes) {
      for (let key in changes) {
        if (
          key !== "firstImage" &&
          key !== "secondImage" &&
          key !== "thirdImage"
        ) {
          formData2.append(key, changes[key]);
        }
      }
    }

    dispatch(
      updateSparePart({
        id: selectedSparePart._id,
        updatedSparePart: formData2,
      })
    ).then(() => {
      setEditSparePartDialogOpen(false);
      resetForm();
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const filteredSpareParts = spareParts?.filter((sparePart) =>
    sparePart?.productName
      ?.toLowerCase()
      .includes(searchTabs?.searchText.toLowerCase())
  );

  return (
    <div>
      <div className="h-[calc(100vh-120px)] overflow-y-auto">
        <div className="flex px-5 py-2 h-[80px] justify-between items-center border-b-[2px] border-lightBorderColor">
          <p className="text-black text-2xl">All Spare Parts</p>
          <PrimaryButton
            onClick={() => setAddSparePartDialogOpen(true)}
            title={"Add Spare"}
          />
        </div>

        {filteredSpareParts?.length == 0 && (
          <div className="h-[100%]">
            {<NoDataFound message={"No Spare Parts found"} />}
          </div>
        )}

        <div className={`flex p-5 w-full ${filteredSpareParts?.length == 0 && "hidden"}`}>
          <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 w-full gap-10">
            {filteredSpareParts.map((item) => (
              <UsedCarCard
                key={item._id}
                item={item}
                onEdit={() => handleEditSpare(item)}
                onDelete={() => handleDeleteSparePart(item._id)}
              />
            ))}
          </div>
        </div>

        {/* Add Spare Part Dialog */}
        <Dialog
          open={addSparePartDialogOpen}
          onClose={() => setAddSparePartDialogOpen(false)}
          className="relative z-50"
        >
          <div className="fixed inset-0 bg-black/30 flex items-center justify-center p-4">
            <Dialog.Panel className="max-w-5xl rounded bg-white p-4">
              <div className="pb-2">
                <img
                  src={icons8crossFilled}
                  onClick={() => setAddSparePartDialogOpen(false)}
                  className="w-8 h-8 cursor-pointer ml-auto"
                />
              </div>

              <div class="flex items-center my-4">
                <hr class="flex-grow border-t border-muted border-black" />
                <span class="mx-4 text-muted-foreground font-semibold text-xl">
                  Upload Images
                </span>
                <hr class="flex-grow border-t border-muted border-black" />
              </div>

              <div className="grid grid-cols-3 gap-2">
                <ImageUploadButton
                  stateData={formData.firstImage}
                  setStateData={(value) =>
                    setFormData({ ...formData, firstImage: value })
                  }
                  refData={firstImageRef}
                />
                <ImageUploadButton
                  stateData={formData.secondImage}
                  setStateData={(value) =>
                    setFormData({ ...formData, secondImage: value })
                  }
                  refData={secondImageRef}
                />
                <ImageUploadButton
                  stateData={formData.thirdImage}
                  setStateData={(value) =>
                    setFormData({ ...formData, thirdImage: value })
                  }
                  refData={thirdImageRef}
                />
              </div>

              <div className="grid grid-cols-3 gap-5 mt-5">
                <InputFields
                  type="text"
                  title="Product Name"
                  placeholder="Product Name"
                  value={formData.productName}
                  name="productName"
                  onChange={handleInputChange}
                />
                <InputFields
                  type="text"
                  title="Price"
                  placeholder="Price"
                  value={formData.price}
                  name="price"
                  onChange={handleInputChange}
                />
                <InputFields
                  type="text"
                  title="Sold By"
                  placeholder="Sold By"
                  value={formData.soldBy}
                  name="soldBy"
                  onChange={handleInputChange}
                />
                <InputFields
                  type="text"
                  title="Brand"
                  placeholder="Brand"
                  value={formData.brand}
                  name="brand"
                  onChange={handleInputChange}
                />
                <InputFields
                  type="text"
                  title="Size"
                  placeholder="Size"
                  value={formData.size}
                  name="size"
                  onChange={handleInputChange}
                />
                <InputFields
                  type="text"
                  title="Type"
                  placeholder="Type"
                  value={formData.type}
                  name="type"
                  onChange={handleInputChange}
                />
                <InputFields
                  type="text"
                  title="Model"
                  placeholder="Model"
                  value={formData.model}
                  name="model"
                  onChange={handleInputChange}
                />
                <InputFields
                  type="text"
                  title="Section Width"
                  placeholder="Section Width"
                  value={formData.sectionWidth}
                  name="sectionWidth"
                  onChange={handleInputChange}
                />
              </div>

              <div className="flex justify-center mt-5">
                <PrimaryButton
                  onClick={handleAddSparePartConfirm}
                  title="Add Spare Part"
                  loading={loading}
                />
              </div>
            </Dialog.Panel>
          </div>
        </Dialog>

        {/* Edit Spare Part Dialog */}
        <Dialog
          open={editSparePartDialogOpen}
          onClose={() => {
            resetForm();
            setEditSparePartDialogOpen(false);
          }}
          className="relative z-50"
        >
          <div className="fixed inset-0 bg-black/30 flex items-center justify-center p-4">
            <Dialog.Panel className="max-w-5xl rounded bg-white p-4">
              <div className="pb-2">
                <img
                  src={icons8crossFilled}
                  onClick={() => {
                    setEditSparePartDialogOpen(false);
                    resetForm();
                  }}
                  className="w-8 h-8 cursor-pointer  ml-auto"
                />
              </div>

              <div class="flex items-center my-4">
                <hr class="flex-grow border-t border-muted border-black" />
                <span class="mx-4 text-muted-foreground font-semibold text-xl">
                  Upload Images
                </span>
                <hr class="flex-grow border-t border-muted border-black" />
              </div>

              <div className="grid grid-cols-3 gap-2">
                <ImageUploadButton
                  stateData={formData.firstImage}
                  setStateData={(value) =>
                    setFormData({ ...formData, firstImage: value })
                  }
                  refData={firstImageRef}
                />
                <ImageUploadButton
                  stateData={formData.secondImage}
                  setStateData={(value) =>
                    setFormData({ ...formData, secondImage: value })
                  }
                  refData={secondImageRef}
                />
                <ImageUploadButton
                  stateData={formData.thirdImage}
                  setStateData={(value) =>
                    setFormData({ ...formData, thirdImage: value })
                  }
                  refData={thirdImageRef}
                />
              </div>

              <div className="grid grid-cols-3 gap-5 mt-5">
                <InputFields
                  type="text"
                  title="Product Name"
                  placeholder="Product Name"
                  value={formData.productName}
                  name="productName"
                  onChange={handleInputChange}
                />
                <InputFields
                  type="text"
                  title="Price"
                  placeholder="Price"
                  value={formData.price}
                  name="price"
                  onChange={handleInputChange}
                />
                <InputFields
                  type="text"
                  title="Sold By"
                  placeholder="Sold By"
                  value={formData.soldBy}
                  name="soldBy"
                  onChange={handleInputChange}
                />
                <InputFields
                  type="text"
                  title="Brand"
                  placeholder="Brand"
                  value={formData.brand}
                  name="brand"
                  onChange={handleInputChange}
                />
                <InputFields
                  type="text"
                  title="Size"
                  placeholder="Size"
                  value={formData.size}
                  name="size"
                  onChange={handleInputChange}
                />
                <InputFields
                  type="text"
                  title="Type"
                  placeholder="Type"
                  value={formData.type}
                  name="type"
                  onChange={handleInputChange}
                />
                <InputFields
                  type="text"
                  title="Model"
                  placeholder="Model"
                  value={formData.model}
                  name="model"
                  onChange={handleInputChange}
                />
                <InputFields
                  type="text"
                  title="Section Width"
                  placeholder="Section Width"
                  value={formData.sectionWidth}
                  name="sectionWidth"
                  onChange={handleInputChange}
                />
              </div>

              <div className="flex justify-center mt-5">
                <PrimaryButton
                  onClick={handleUpdateSparePart}
                  title="Update Spare Part"
                  loading={loading}
                />
              </div>
            </Dialog.Panel>
          </div>
        </Dialog>
      </div>
    </div>
  );
};
