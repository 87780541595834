import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "@headlessui/react";
import PrimaryButton from "../utils/buttons/primaryButton";
import ImageUploadButton from "../utils/buttons/imageUploadButton";
import InputField from "../form/input";
import { icons8crossFilled } from "../../assets";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOffers,
  createOffer,
  deleteOffer,
  updateOffer,
} from "../../redux/offerThunk";
import { OfferCard } from "./OfferCard";

import { InputFields } from "../InputField/InputFIelds";
import { notifyWarning } from "../../utility/axiosInstance";
import { validateFileSize } from "../../utility/ImageValidation";

export const Offers = () => {
  const [addOffer, setAddOffer] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const dispatch = useDispatch();
  const { list, loading } = useSelector((state) => state.offers);
  const icon = useRef(null);
  const [selectedOffer, setSelectedOffer] = useState(null);

  const [data, setData] = useState([]);

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    discount: "",
    discountType: "percentage", // Default to percentage
    minOrderAmount: "",
    applicableCategories: "",
    code: "",
    validFrom: "",
    validTo: "",
    icon: "", // Assuming you want to handle image upload
  });

  const resetForm = () => {
    setFormData({
      title: "",
      description: "",
      discount: "",
      discountType: "percentage", // Reset to default
      minOrderAmount: "",
      applicableCategories: "",
      code: "",
      validFrom: "",
      validTo: "",
      icon: "",
    });

    icon.current = null;
  };

  const handleAddOffer = () => {
    setAddOffer(true);
    resetForm();
  };

  const handleAddOfferConfirm = () => {
    const {
      title,
      description,
      discount,
      discountType,
      minOrderAmount,
      applicableCategories,
      code,
      validFrom,
      validTo,
      icon,
    } = formData;

    if (
      !title ||
      !description ||
      !discount ||
      !discountType ||
      !minOrderAmount ||
      !validFrom ||
      !validTo ||
      !code
    ) {
      notifyWarning("All fields are required");
      return;
    }

    const formData2 = new FormData();
    formData2.append("title", title);
    formData2.append("description", description);
    formData2.append("discount", discount);
    formData2.append("discountType", discountType);
    formData2.append("minOrderAmount", minOrderAmount);
    formData2.append("applicableCategories", applicableCategories);
    formData2.append("code", code);
    formData2.append("validFrom", validFrom);
    formData2.append("validTo", validTo);

    // Only append the icon if it exists
    if (icon) {
      if (!validateFileSize(icon, "icon")) return;
      formData2.append("icon", icon);
    }

    // Dispatch the action to create the offer
    dispatch(createOffer(formData2)).then(() => {
      setAddOffer(false);
      resetForm();
    });
  };

  const handleEditOffer = (offer) => {
    setFormData(offer);
    setSelectedOffer(offer);
    setOpenEditModal(true);
  };

  const handleUpdateOffer = () => {
    const updatedOffer = {
      ...formData,
    };
    const formData2 = new FormData();
    formData2.append("_id", updatedOffer?._id);
    formData2.append("title", updatedOffer?.title);
    formData2.append("description", updatedOffer?.description);
    formData2.append("discount", updatedOffer?.discount);
    formData2.append("discountType", updatedOffer?.discountType);
    formData2.append("minOrderAmount", updatedOffer?.minOrderAmount);
    formData2.append(
      "applicableCategories",
      updatedOffer?.applicableCategories
    );
    formData2.append("code", updatedOffer?.code);
    formData2.append("validFrom", updatedOffer?.validFrom);
    formData2.append("validTo", updatedOffer?.validTo);

    if (updatedOffer?.icon) {
      if (!validateFileSize(updatedOffer?.icon, "icon")) return;
      formData2.append("icon", updatedOffer?.icon);
    }

    dispatch(updateOffer({ id: selectedOffer._id, updatedOffer: formData2 }));
    setOpenEditModal(false);
  };

  const handleDeleteOffer = (id) => {
    dispatch(deleteOffer(id));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "validTo" || name === "validFrom") {
      const formattedValue = new Date(value).toISOString().split("T")[0];
      setFormData({
        ...formData,
        [name]: formattedValue,
      });
    } else if (name === "applicableCategories") {
      // Handle comma-separated categories
      setFormData({
        ...formData,
        [name]: value.split(",").map((cat) => cat.trim()),
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    dispatch(fetchOffers());
  }, [dispatch]);

  useEffect(() => {
    if (list) {
      setData(list);
    }
  }, [list]);

  return (
    <div>
      <div className="h-[calc(100vh-120px)] overflow-y-auto rounded-t-lg">
        <div className="flex px-5 py-2 h-[80px] justify-between items-center border-b-[2px] border-lightBorderColor">
          <p className="text-black text-2xl">Offers</p>
          <PrimaryButton
            onClick={handleAddOffer}
            title={"Add Offer"}
            backgroundColor={""}
            color={""}
            border=""
          />
        </div>
        <div className="flex p-5 w-full">
          <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 w-full gap-10">
            {data?.map((item) => (
              <div key={item._id}>
                <OfferCard
                  item={item}
                  onEdit={() => handleEditOffer(item)}
                  onDelete={() => handleDeleteOffer(item._id)}
                />
              </div>
            ))}
          </div>
        </div>

        {/* Add Offer Dialog */}
        <Dialog
          open={addOffer}
          onClose={() => setAddOffer(false)}
          className="relative z-50"
        >
          <div className="fixed inset-0 bg-black/70 flex w-screen items-center justify-center p-6">
            <Dialog.Panel className="w-full max-w-5xl rounded bg-white p-4 max-h-[600px] overflow-auto no-scrollbar">
              <div className="relative bg-white rounded-3xl">
                <div className="h-[85vh] w-[850px] rounded-md relative p-4 bg-white">
                  <div className="p-4">
                    <img
                      src={icons8crossFilled}
                      className="w-8 h-8 absolute right-2 top-2 rounded-full cursor-pointer"
                      onClick={() => setAddOffer(false)}
                    />
                    <div className="text-lg pt-2 px-3">
                      <div class="flex items-center my-4">
                        <hr class="flex-grow border-t border-muted border-black" />
                        <span class="mx-4 text-muted-foreground font-semibold text-xl">
                          Upload Image
                        </span>
                        <hr class="flex-grow border-t border-muted border-black" />
                      </div>

                      <div className="m-auto w-1/4">
                        <ImageUploadButton
                          stateData={formData.icon}
                          setStateData={(value) =>
                            setFormData({ ...formData, icon: value })
                          }
                          refData={icon}
                        />
                      </div>

                      <div className="grid grid-cols-3 gap-5 mt-5">
                        <InputFields
                          type={"text"}
                          title={"Coupon Name"}
                          name={"title"}
                          placeholder={"Enter Coupon Name"}
                          value={formData.title}
                          onChange={handleInputChange}
                        />
                        <InputFields
                          type={"text"}
                          title={"Description"}
                          name={"description"}
                          placeholder={"Enter Description"}
                          value={formData.description}
                          onChange={handleInputChange}
                        />
                        <InputFields
                          type={"number"}
                          title={"Discount"}
                          name={"discount"}
                          placeholder={"Enter Discount"}
                          value={formData.discount}
                          onChange={handleInputChange}
                        />
                        <InputFields
                          type={"text"}
                          title={"Discount Type"}
                          name={"discountType"}
                          placeholder={"Enter Discount Type"}
                          value={formData.discountType}
                          onChange={handleInputChange}
                        />
                        <InputFields
                          type={"number"}
                          title={"Minimum Order Amount"}
                          name={"minOrderAmount"}
                          placeholder={"Enter Minimum Order Amount"}
                          value={formData.minOrderAmount}
                          onChange={handleInputChange}
                        />
                        <InputFields
                          type={"text"}
                          title={"Applicable Categories"}
                          name={"applicableCategories"}
                          placeholder={
                            "Enter Applicable Categories (comma-separated)"
                          }
                          value={formData.applicableCategories}
                          onChange={handleInputChange}
                        />
                        <InputFields
                          type={"text"}
                          title={"Code"}
                          name={"code"}
                          placeholder={"Enter Offer Code"}
                          value={formData.code}
                          onChange={handleInputChange}
                        />
                        <InputFields
                          type={"date"}
                          title={"Valid From"}
                          name={"validFrom"}
                          placeholder={"Enter Valid From"}
                          value={formData.validFrom}
                          onChange={handleInputChange}
                        />
                        <InputFields
                          type={"date"}
                          title={"Valid To"}
                          name={"validTo"}
                          placeholder={"Enter Valid To"}
                          value={formData.validTo}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="flex justify-center pt-6 gap-6">
                        <PrimaryButton
                          title={"Upload"}
                          onClick={handleAddOfferConfirm}
                          backgroundColor={""}
                          loading={loading}
                          color={""}
                          border=""
                          width="250px"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </div>
        </Dialog>

        {/* Edit Offer Dialog */}
        <Dialog
          open={openEditModal}
          onClose={() => setOpenEditModal(false)}
          className="relative z-50"
        >
          <div className="fixed inset-0 bg-black/70 flex w-screen items-center justify-center p-6">
            <Dialog.Panel className="w-full max-w-5xl rounded bg-white p-4 max-h-[600px] overflow-auto no-scrollbar">
              <div className="relative bg-white rounded-3xl">
                <div className="h-[85vh] w-[850px] rounded-md relative p-4 bg-white">
                  <div className="p-4">
                    <img
                      src={icons8crossFilled}
                      className="w-8 h-8 absolute right-2 top-2 rounded-full cursor-pointer"
                      onClick={() => setOpenEditModal(false)}
                    />
                    <div className="text-lg pt-2 px-3">
                      <div class="flex items-center my-4">
                        <hr class="flex-grow border-t border-muted border-black" />
                        <span class="mx-4 text-muted-foreground font-semibold text-xl">
                          Upload Image
                        </span>
                        <hr class="flex-grow border-t border-muted border-black" />
                      </div>

                      <div className="m-auto grid grid-cols-1 gap-2 w-1/4">
                        <ImageUploadButton
                          stateData={formData.icon}
                          setStateData={(value) =>
                            setFormData({ ...formData, icon: value })
                          }
                          refData={icon}
                        />
                      </div>

                      <div className="grid grid-cols-2 gap-5 mt-5">
                        <InputFields
                          type={"text"}
                          title={"Coupon Name"}
                          name={"title"}
                          placeholder={"Enter Coupon Name"}
                          value={formData.title}
                          onChange={handleInputChange}
                        />
                        <InputFields
                          type={"text"}
                          title={"Description"}
                          name={"description"}
                          placeholder={"Enter Description"}
                          value={formData.description}
                          onChange={handleInputChange}
                        />
                        <InputFields
                          type={"number"}
                          title={"Discount"}
                          name={"discount"}
                          placeholder={"Enter Discount"}
                          value={formData.discount}
                          onChange={handleInputChange}
                        />
                        <InputFields
                          type={"text"}
                          title={"Discount Type"}
                          name={"discountType"}
                          placeholder={"Enter Discount Type"}
                          value={formData.discountType}
                          onChange={handleInputChange}
                        />
                        <InputFields
                          type={"number"}
                          title={"Minimum Order Amount"}
                          name={"minOrderAmount"}
                          placeholder={"Enter Minimum Order Amount"}
                          value={formData.minOrderAmount}
                          onChange={handleInputChange}
                        />
                        <InputFields
                          type={"text"}
                          title={"Applicable Categories"}
                          name={"applicableCategories"}
                          placeholder={
                            "Enter Applicable Categories (comma-separated)"
                          }
                          value={formData.applicableCategories}
                          onChange={handleInputChange}
                        />
                        <InputFields
                          type={"text"}
                          title={"Code"}
                          name={"code"}
                          placeholder={"Enter Offer Code"}
                          value={formData.code}
                          onChange={handleInputChange}
                        />
                        <InputFields
                          type={"date"}
                          title={"Valid From"}
                          name={"validFrom"}
                          placeholder={"Enter Valid From"}
                          value={formData.validFrom?.split("T")[0]}
                          onChange={handleInputChange}
                        />
                        <InputFields
                          type={"date"}
                          title={"Valid To"}
                          name={"validTo"}
                          placeholder={"Enter Valid To"}
                          value={formData.validTo?.split("T")[0]}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="flex justify-center pt-8 gap-6">
                        <PrimaryButton
                          title={"Update"}
                          onClick={handleUpdateOffer}
                          backgroundColor={""}
                          color={""}
                          border=""
                          loading={loading}
                          width="250px"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </div>
        </Dialog>
      </div>
    </div>
  );
};
