import React, { createContext, useEffect, useRef, useState } from "react";
import WeekSettingComponent from "./components/WeekSettingComponent";
import CalenderComponent from "./components/CalenderComponent";
import ServiceListComponent from "./components/ServiceListComponent";

import PrimaryButton from "../../utils/buttons/primaryButton";
import axiosInstance, { notifyError, notifySuccess } from "../../../utility/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  setAdditionServiceInfo,
  setServiceSettingInfo,
} from "../../../redux/serviceSlice";

export const ServiceSettingContext = createContext({});
const weekdays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
const ServiceSettingIndex = ({tab,setTab}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  // const [tab, setTab] = useState(0);
  const { serviceSettingInfo, loading, serviceAdditionInfo } = useSelector(
    (state) => state?.services
  );

  const [tempTimeSave, setTempTimeSave] = useState([]);
  const [weeklyCheckbox, setWeeklyCheckbox] = useState({
    Monday: true,
    Tuesday: true,
    Wednesday: true,
    Thursday: true,
    Friday: true,
    Saturday: true,
    Sunday: true,
  });

  // console.log(weeklyCheckbox);
  const [calenderView, setCalenderView] = useState("week");
  const [workingTime, setWorkingTime] = useState({
    start: "9:00",
    end: "18:00",
  });

  const [workingDayTime, setWorkingDayTime] = useState([]);
  const [availableWorkingDays, setAvailableWorkingDays] = useState([]);

  const [services, setServices] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [dateValue, onDateChange] = useState(new Date());
  const [currentMonth, onCurrentMonth] = useState(
    new Date(new Date().setDate(1))
  );
  const [available, setAvailable] = useState([]);
  // console.log(new Date(currentMonthYear));
  const compareTwoDate = (date1, date2) => {
    const d1 = new Date(date1).setHours(0, 0, 0, 0);
    const d2 = new Date(date2).setHours(0, 0, 0, 0);

    return d1 === d2;
  };
  const compareMonthAndYear = (date1, date2) => {
    const d1 = new Date(date1);
    const d2 = new Date(date2);

    // Compare month and year
    return (
      d1.getMonth() === d2.getMonth() && d1.getFullYear() === d2.getFullYear()
    );
  };
  useEffect(() => {
    if (serviceSettingInfo) {
      const { workingTime, weeklySetting } = serviceSettingInfo?.workingDetails;

      if (Object.keys(workingTime).length !== 0) {
        setWorkingTime(workingTime);
      }
      const { holidays } = serviceSettingInfo;
      setHolidays(holidays);
      if (weeklySetting) {
        setWeeklyCheckbox(weeklySetting);
      }
    }
  }, [calenderView, serviceSettingInfo]);

  const handleMonthDateUpdate = async (body) => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.put(
        "servicesSetting/updateServiceSettingInfo",
        {
          ...body,
        }
      );
      // console.log(response);
      if (response?.status === 200) {
        console.log(response?.data.serviceRequestSettings);
        dispatch(setServiceSettingInfo(response?.data.serviceRequestSettings));
        notifySuccess("Updated sucessfully");
        // toast.success("Changed the update");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };
  const handleSingleUpdate = async (body) => {
    try {
      setIsLoading(true);

      const response = await axiosInstance.put(
        "servicesSetting/updateServiceSettingInfowithDate",
        {
          ...body,
        }
      );

      if (response?.status === 200) {
        console.log(response?.data.serviceRequestSettings);
        dispatch(setServiceSettingInfo(response?.data.serviceRequestSettings));
        toast.success("Changed the update");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };
  const settingType = "service";

  const onHandleWOS = async (body) => {
    try {
      setIsLoading(true);
      const response = await handleMonthDateUpdate({
        weeklySetting: weeklyCheckbox,
      });
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  // const onBack = () => {
  //   setTab((count) => (count == 0 ? count : count - 1));
  // };
  const onNext = () => {
    setTab((count) => (count == 1 ? count : count + 1));
    // console.log(tab,'tab')
  };
  useEffect(() => {
    const tempAllDate = [];

    if (available.length > 0) {
      available.forEach((element) => {
        tempAllDate.push({
          date: element,
          services: services,
          timeSlots: workingDayTime,
        });
      });
      console.log(tempAllDate);
      setAvailableWorkingDays(tempAllDate);
    }
  }, [workingDayTime, services, available]);
  // console.log("available",availableWorkingDays);
  const CustomDatePicker = () => {
    return (
      <span className="text-xl flex items-center gap-5">
        Date:
        {/* <MdOutlineCalendarMonth className="ml-2 mr-1" /> */}
        <span className="text-xl cursor-pointer">
          <input
            type="date"
            // style={{ display: "none" }}
            className="w-64"
            value={new Date(dateValue).toISOString().split("T")[0]}
            onChange={(e) => {
              const newDateValue = e.target.value;
              if (
                !available.find((date) => compareTwoDate(date, newDateValue))
              ) {
                toast.warn("This Date is not available");
                return;
              }
              onDateChange(new Date(newDateValue)); // Trigger date change callback
            }}
          />
        </span>
      </span>
    );
  };
  return (
    <ServiceSettingContext.Provider
      value={{
        CustomDatePicker,
        services,
        setServices,
        weeklyCheckbox,
        weekdays,
        setWeeklyCheckbox,
        dateValue,
        onDateChange,
        calenderView,
        setCalenderView,
        workingTime,
        setWorkingTime,
        workingDayTime,
        setWorkingDayTime,
        availableWorkingDays,
        setAvailableWorkingDays,
        handleMonthDateUpdate,
        isLoading,
        handleSingleUpdate,
        tempTimeSave,
        setTempTimeSave,
        compareTwoDate,
        holidays,
        setHolidays,
        settingType,
        onHandleWOS,
        currentMonth,
        onCurrentMonth,
        available,
        setAvailable,
        compareMonthAndYear,
      }}
    >
      <div className="py-8 flex flex-col  w-[100%] gap-10">
        {tab === 0 ? (
          <CalenderComponent />
        ) : (
          <div className="flex flex-col gap-5 ">
            <WeekSettingComponent />
            <ServiceListComponent />
          </div>
        )}

        <div className="flex gap-1 justify-end px-10">
          {/* <PrimaryButton
            width="120px"
            className={`bg-white border border-redBackground `}
            title={"Back"}
            textClass={"text-redBackground "}
            onClick={onBack}
            // loading={isMLoading}
          /> */}
          {tab < 1 && (
            <PrimaryButton
              width="140px"
              title={"Next"}
              onClick={onNext}
              // onClick={() => updateHoliday(dateValue)}
              // loading={isLoading}
            />
          )}
        </div>
      </div>
    </ServiceSettingContext.Provider>
  );
};

export const HeaderText = ({ text }) => (
  <span className="text-2xl  text-redBackground underline">{text}</span>
);
export default ServiceSettingIndex;
