import React, { useState } from "react";
import PrimaryButton from "../../utils/buttons/primaryButton";
import { HeaderText } from "../../Service/ServiceSetting/ServiceSettingIndex";
import Calendar from "react-calendar";
import "../../css/Calender.css";
export default function CalenderViewComponent({
  SettingContextData,
  handleMonthDateUpdate,
  updateHoliday,
  allDate,
  button = ()=>{}
}) {
  const {
    currentMonth,
    onCurrentMonth,
    onDateChange,
    available,
    compareTwoDate,
    dateValue,
   
  } = SettingContextData;

  const [isLoading, setIsLoading] = useState(false);
  return (
    <div className="flex flex-col flex-wrap gap-5 border border-black px-10 py-5 rounded-lg ">
      <HeaderText text={"Select Month"} />
      <Calendar
        className="custom-calendar custom-calendar_month"
        value={currentMonth}
        view="year"
        maxDetail="year"
        minDetail="year"
        onClickMonth={onCurrentMonth}
        tileClassName={({ date, view }) =>
          view === "year" &&
          date.getMonth() === currentMonth.getMonth() &&
          date.getFullYear() === currentMonth.getFullYear()
            ? "active-month"
            : null
        }
      />

      <div className="flex flex-col  gap-5  my-5">
        <HeaderText text={" Calender Setting"} />
        <div className="calendar-container justify-start w-[30rem]">
          <Calendar
            view="month"
            maxDetail="month"
            minDetail="month"
            showNavigation={false}
            // onClickDay={handleDateChange}
            onChange={(e) => {
              onDateChange(e);
            }}
            tileDisabled={({ activeStartDate, date, view }) => {
              let inactive = false;

              if (!available.find((aDate) => compareTwoDate(aDate, date))) {
                inactive = true;
              }

              return inactive;
            }}
            tileClassName={({ activeStartDate, date, view }) => {
              let inactive = "";
              if (available.find((aDate) => compareTwoDate(aDate, date))) {
                inactive = "react-calendar__tile_available";
              }

              if (!available.find((aDate) => compareTwoDate(aDate, date))) {
                inactive = "react-calendar__tile--disabled";
              }

              return inactive;
            }}
            value={dateValue}
            maxDate={allDate.maxDate}
            minDate={allDate.minDate}
            className="custom-calendar"
          />
        </div>
        <div className="flex w-full gap-5">
        {button()}
          <PrimaryButton
            width="120px"
            className={""}
            title={"Update All"}
            onClick={async () => {
              try {
                setIsLoading(true);
                
                await handleMonthDateUpdate();
              } catch (err) {
              } finally {
                setIsLoading(false);
              }
            }}
            loading={isLoading}
          />
          <PrimaryButton
            width="140px"
            title={"Mark As Holiday"}
            onClick={async () => {
              try {
                setIsLoading(true);
                await updateHoliday(dateValue);
              } catch (err) {
              } finally {
                setIsLoading(false);
              }
            }}
            loading={isLoading}
          />
        </div>
      </div>
    </div>
  );
}
