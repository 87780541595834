import { createSlice } from "@reduxjs/toolkit";

const sparePartsSlice = createSlice({
    name: "spareParts",
    initialState: {
      list: [],
      loading: false,
    },
    reducers: {
      setSpareParts: (state, action) => {
        state.list = action.payload;
      },
      addSparePartSuccess: (state, action) => {
        state.list.push(action.payload);
      },
      deleteSparePartSuccess: (state, action) => {
        state.list = state.list.filter((part) => part.id !== action.payload);
      },
      updateSparePartSuccess: (state, action) => {
        const index = state.list.findIndex((part) => part.id === action.payload.id);
        state.list[index] = action.payload;
      },
      setLoading: (state, action) => {
        state.loading = action.payload;
      },
    },
  });
  
  export const {
    setSpareParts,
    addSparePartSuccess,
    deleteSparePartSuccess,
    updateSparePartSuccess,
    setLoading,
  } = sparePartsSlice.actions;
  
  export default sparePartsSlice.reducer;