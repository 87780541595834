import { Dialog } from "@headlessui/react";
import { useEffect, useState } from "react";
import { CustomerTable } from "./CustomerTable";
import { useDispatch, useSelector } from "react-redux";
import { fetchCustomers } from "../../redux/customersThunk";
import { setCustomersOptionSelected } from "../../redux/searchSlice";
import NoDataFound from "../NoDataFound/NoDataFound";
import Pagination from "../Common/Pagination";
import { AiOutlineLoading } from "react-icons/ai";

export const Customers = () => {
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();

  // Fetching customers and metadata (pagination details) from Redux store
  const {
    list: customers,
    loading,
    meta,
  } = useSelector((state) => state.customers);

  const searchTabs = useSelector((state) => state.search);

  // Pagination state
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    limit: 10,
    totalPage: meta?.totalPages,
    totalData: meta?.totalCustomers,
  });

  // Handle page change for pagination
  const handlePageChange = (newPageIndex) => {
    setPaginationData((prev) => ({
      ...prev,
      currentPage: newPageIndex + 1,
    }));
  };

  // Fetch customers based on current pagination and search term
  useEffect(() => {
    dispatch(
      fetchCustomers({
        currentPage: paginationData.currentPage,
        limit: paginationData.limit,
        search: searchTabs?.searchText?.trim(),
      })
    );
  }, [
    dispatch,
    paginationData.currentPage,
    paginationData.limit,
    searchTabs?.searchText,
  ]);

  // Update pagination data when meta (total customers/pages) changes
  useEffect(() => {
    setPaginationData((prev) => ({
      ...prev,
      totalData: meta?.totalCustomers,
      totalPage: meta?.totalPages,
    }));
  }, [meta]);

  // Clear search when component mounts and cleanup on unmount
  useEffect(() => {
    dispatch(setCustomersOptionSelected({ isActive: true, searchText: "" }));
    return () => {
      dispatch(setCustomersOptionSelected({ isActive: false, searchText: "" }));
    };
  }, [dispatch]);

  return (
    <div className="h-[calc(100vh-120px)] overflow-y-auto">
      {/* Show NoDataFound component if no customers match */}
      {customers?.length === 0 && (
        <div className="h-[100%]">
          <NoDataFound message={"No Customers found"} />
        </div>
      )}

      {/* Customer table and pagination */}
      <div className={`flex px-5 ${customers?.length === 0 && "hidden"}`}>
        <div className="w-full my-2 overflow-y-auto h-[calc(81vh)] relative">
          <CustomerTable data={customers} />
          {/* Pagination */}
          <div className="mt-auto absolute bottom-0 bg-white p-4 min-w-[95%]">
            <Pagination
              currentPageIndex={paginationData?.currentPage - 1}
              totalPages={paginationData?.totalPage}
              handlePageChange={handlePageChange}
              canNextPage={
                paginationData?.currentPage !== paginationData?.totalPage
              }
              canPreviousPage={paginationData?.currentPage > 1}
            />
          </div>
        </div>
      </div>

      {/* Modal */}
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        className="relative z-50"
      >
        <div className=" fixed inset-0 bg-black/30 flex w-screen items-center justify-center p-4">
          <div className="h-[700px] w-[700px] relative bg-white">
            <div className="border-borderColor border-b-[1px] pb-5">
              <div
                onClick={() => setOpenModal(false)}
                className="absolute h-[20px] w-[20px] bg-redBackground text-white flex justify-center items-center right-1 top-1 rounded-full cursor-pointer"
              >
                x
              </div>
              <div className="text-lg pt-2 px-3">Add Car</div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
