import { createSlice } from "@reduxjs/toolkit";

const ordersSlice = createSlice({
    name: "orders",
    initialState: {
      list: [],
      loading: false,
    },
    reducers: {
      setOrders: (state, action) => {
        state.list = action.payload;
      },
     
      deleteOrders: (state, action) => {
        state.list = state.list.filter((part) => part.id !== action.payload);
      },
      updateOrders: (state, action) => {
        const index = state.list.findIndex((part) => part.id === action.payload.id);
        state.list[index] = action.payload;
      },
      setLoading: (state, action) => {
        state.loading = action.payload;
      },
    },
  });
  
  export const {
    setOrders,
    updateOrders,
    deleteOrders,
    setLoading,
  } = ordersSlice.actions;
  
  export default ordersSlice.reducer;