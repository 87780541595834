import React from "react";
import styles from './NewtonCradleLoader.module.css';


export const ScreenLoader = () => {
  return (
    <div className={styles.newtonsCradle}>
      <div className={styles.newtonsCradle__dot}></div>
      <div className={styles.newtonsCradle__dot}></div>
      <div className={styles.newtonsCradle__dot}></div>
      <div className={styles.newtonsCradle__dot}></div>
    </div>
  );
};
