import { Dialog } from "@headlessui/react";
import { useEffect, useRef, useState } from "react";
import PrimaryButton from "../utils/buttons/primaryButton";
import UsedCarCard from "../utils/card/usedCars/usedCarCard";
import ImageUploadButton from "../utils/buttons/imageUploadButton";
import { icons8crossFilled } from "../../assets";
import InputField from "../form/input";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUsedCars,
  addUsedCars,
  updateUsedCars,
  deleteUsedCars,
  fetchUsedCarsSettingInfo,
} from "../../redux/usedCarsThunk";
import { toast } from "react-toastify";
import { InputFields } from "../InputField/InputFIelds";
import { validateFileSize } from "../../utility/ImageValidation";
import { ScreenLoader } from "../ScreenLoader/ScreenLoader";
import { setUsedCarSelected } from "../../redux/searchSlice";
import NoDataFound from "../NoDataFound/NoDataFound";
import axiosInstance from "../../utility/axiosInstance";
import { SlotsCreater } from "./SlotsCreater";
import CreatableSelect from "react-select/creatable"; // Allows custom entries too

import { carData } from "../../assets/2020";
import Pagination from "../Common/Pagination";
import { IoSettings } from "react-icons/io5";
import { FaArrowLeft } from "react-icons/fa";
import UsedCarSettingIndex from "./usedCarSetting/UsedCarSettingIndex";

export const UsedCars = () => {
  const dispatch = useDispatch();
  const [OCSetting, setOCSetting] = useState(false);
  const initialFormData = {
    front: "",
    back: "",
    left: "",
    right: "",
    overview: {
      carName: "",
      carModel: "",
      engineType: "",
      drivenKm: "",
      makeYear: "",
      registrationYear: "",
      transmission: "",
      numberOfOwners: "",
      insuranceValidity: "",
      insuranceType: "",
      location: "",
      price: "",
      testDriveAvailable: false,
      emiAvailable: false,
    },
    keySpecs: {
      fuelType: "",
      engine: "",
      maxPower: "",
      maxPowerRPM: "",
      mileage: "",
      seatingCapacity: "",
    },
    offers: [{ description: "" }],
    colours: [{ colour: "" }],
  };

  const colorOptions = [
    { value: "Black", label: "Black" },
    { value: "White", label: "White" },
    { value: "Red", label: "Red" },
    { value: "Blue", label: "Blue" },
    // Predefined colors...
  ];

  const {
    list: usedCars,
    loading,
    meta,
  } = useSelector((state) => state.usedCars);
  const searchTabs = useSelector((state) => state.search);

  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [pagination, setPagination] = useState({
    pageIndex: currentPageIndex,
    pageSize: 12,
  });

  const [carData, setCarData] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [filteredUsedCars, setFilteredUsedCars] = useState([]);
  console.log(filteredUsedCars);
  const [addUsedCarDialogOpen, setAddUsedCarDialogOpen] = useState(false);
  const [editUsedCarDialogOpen, setEditUsedCarDialogOpen] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [selectedUsedCar, setSelectedUsedCar] = useState(null);
  const [errors, setErrors] = useState({});
  const [tab, setTab] = useState(0);


  const [isUnique, setIsUnique] = useState({
    unique: false,
    sameUser: false,
  });
  const [error, setError] = useState("");

  const imageRefs = {
    front: useRef(null),
    back: useRef(null),
    left: useRef(null),
    right: useRef(null),
  };

  // Handle page change
  const handlePageChange = (newPageIndex) => {
    setCurrentPageIndex(newPageIndex);
    setPagination((prev) => ({ ...prev, pageIndex: newPageIndex }));
  };

  useEffect(() => {
    const uniqueMakes = [...new Set(carData.map((car) => car.make))];
    setCarData(uniqueMakes);
  }, []);

  useEffect(() => {
    dispatch(fetchUsedCarsSettingInfo({dispatch}))
    dispatch(
      fetchUsedCars({
        page: pagination.pageIndex + 1,
        limit: pagination.pageSize,
        search: searchTabs?.searchText,
      })
    );
  }, [dispatch, pagination.pageIndex, searchTabs?.searchText]);

  useEffect(() => {
    if (usedCars) {
      const obj = {};
      usedCars?.map((ele) => {
        obj["id"] = ele?._id;
        obj["front"] = ele?.images?.front;
        obj["back"] = ele?.images?.back;
        obj["left"] = ele?.images?.left;
        obj["right"] = ele?.images?.right;
        obj["overview"] = ele?.overview;
        obj["vin"] = ele?.vin;
        obj["isPublic"] = ele?.isPublic;
        obj["status"] = ele?.status;
      });
      setFormData(obj);
    }
  }, [usedCars]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const parts = name?.split(".");

    if (parts.length > 1) {
      setFormData((prev) => ({
        ...prev,
        [parts[0]]: {
          ...prev[parts[0]],
          [parts[1]]: type === "checkbox" ? checked : value,
        },
      }));
    } else {
      if (name === "overview.insuranceValidity") {
        const formattedValue = new Date(value)?.toISOString()?.split("T")[0];
        setFormData({
          ...formData,
          [name]: formattedValue,
        });
      } else {
        setFormData({
          ...formData,
          [name]: type === "checkbox" ? checked : value,
        });
      }
    }
  };

  const handleEditUsedCars = (car) => {
    if (car) {
      const obj = {};
      const { front, back, left, right } = car?.images;
      const { overview } = car;
      const { keySpecs } = car;
      const { vin } = car;
      const { isPublic } = car;
      const { status } = car;

      obj.front = front;
      obj.back = back;
      obj.left = left;
      obj.right = right;
      obj.overview = overview;
      obj.keySpecs = keySpecs;
      obj.vin = vin;
      obj.isPublic = isPublic;
      obj.status = status;

      setFormData(obj);
    }

    setSelectedUsedCar(car);
    setEditUsedCarDialogOpen(true);
  };

  const validateForm = () => {
    const newErrors = {};

    // VIN validation
    if (!formData.vin) {
      toast.error("VIN number is required.");
      return false;
    } else if (formData?.vin?.length !== 17) {
      toast.error("VIN number should be exactly 17 digits.");
      return false;
    } else if (error) {
      toast.error(error);
      return false;
    }

    // Car Name validation
    if (!formData?.overview?.carName?.trim()) {
      toast.error("Car Name is required.");
      return false;
    }

    // Car Model validation
    if (!formData?.overview?.carModel?.trim()) {
      toast.error("Car Model is required.");
      return false;
    }

    if (!formData?.status?.trim()) {
      toast.error("Status is required.");
      return false;
    }

    // if (!formData?.isPublic) {
    //   toast.error("Listing type is required.");
    //   return false;
    // }

    // Price validation
    if (
      !formData?.overview?.price?.trim() ||
      isNaN(formData?.overview?.price)
    ) {
      toast.error("Valid Price is required.");
      return false;
    }

    if (!formData?.overview?.transmission) {
      toast.error("Transmission is required.");
      return false;
    }

    // Engine Type validation
    if (!formData?.overview?.engineType?.trim()) {
      toast.error("Engine Type is required.");
      return false;
    }

    // Fuel Type validation
    if (!formData?.keySpecs?.fuelType?.trim()) {
      toast.error("Fuel Type is required.");
      return false;
    }

    if (!formData?.keySpecs?.colors) {
      toast.error("Color is required.");
      return false;
    }

    // Max Power validation
    if (!formData?.keySpecs?.maxPower?.trim()) {
      toast.error("Max Power is required.");
      return false;
    }

    // Mileage validation
    if (!formData?.keySpecs?.mileage?.trim()) {
      toast.error("Mileage is required.");
      return false;
    }

    // Seating Capacity validation
    if (
      !formData?.keySpecs?.seatingCapacity ||
      isNaN(formData?.keySpecs?.seatingCapacity) ||
      formData?.keySpecs?.seatingCapacity < 1
    ) {
      toast.error("Number of Seating Capacity should be at least 1.");
      return false;
    }

    // Insurance validation
    if (!formData?.overview?.insuranceType?.trim()) {
      toast.error("Insurance Type is required.");
      return false;
    }
    if (!formData?.overview?.insuranceValidity?.trim()) {
      toast.error("Insurance Validity is required.");
      return false;
    }

    // Images validation (at least one image is required)
    const images = [
      formData.front,
      formData.back,
      formData.left,
      formData.right,
    ].filter(Boolean);
    if (images.length === 0) {
      toast.error("Please upload at least one image.");
      return false;
    }

    // Location validation
    if (!formData?.overview?.location?.trim()) {
      toast.error("Location is required.");
      return false;
    }

    // Number of Owners validation (at least 1 owner)
    if (
      !formData?.overview?.numberOfOwners ||
      isNaN(formData?.overview?.numberOfOwners) ||
      formData?.overview?.numberOfOwners < 1
    ) {
      toast.error("Number of Owners should be at least 1.");
      return false;
    }

    const currentYear = new Date()?.getFullYear();
    const makeYear = formData?.overview?.makeYear?.trim();

    if (!makeYear) {
      toast.error("Make Year is required.");
      return false;
    } else if (isNaN(makeYear)) {
      toast.error("Make Year should be a valid number.");
      return false;
    } else if (makeYear.length !== 4) {
      toast.error(
        "Make Year should be a valid 4-digit year (e.g., 2020, 1999)."
      );
      return false;
    } else if (parseInt(makeYear) > currentYear) {
      toast.error(
        `Make Year cannot be in the future. Current year is ${currentYear}.`
      );
      return false;
    }

    // Registration Year validation (should not be before the Make Year and not in the future)
    const registrationYear = formData?.overview?.registrationYear?.trim();

    if (
      !formData?.overview?.registrationYear?.trim() ||
      isNaN(formData?.overview?.registrationYear)
    ) {
      toast.error("Registration Year is required and should be valid.");
      return false;
    } else if (
      parseInt(formData?.overview?.registrationYear) <
      parseInt(formData?.overview?.makeYear)
    ) {
      toast.error("Registration Year cannot be before the Make Year.");
      return false;
    } else if (parseInt(formData?.overview?.registrationYear) > currentYear) {
      toast.error(
        `Registration Year cannot be in the future. Current year is ${currentYear}.`
      );
      return false;
    } else if (registrationYear?.length !== 4) {
      toast.error(
        "RegistrationYear Year should be a valid 4-digit year (e.g., 2020, 1999)."
      );
      return false;
    }

    // If no errors found
    return true;
  };

  const resetForm = () => {
    setFormData(initialFormData);
    Object.values(imageRefs).forEach((ref) => {
      if (ref && ref.current && typeof ref.current.clear === "function") {
        ref.current.clear();
      } else {
        console.warn("clear method not found on ref", ref);
      }
    });
  };

  const handleUpdateUsedCar = () => {
    if (!validateForm()) return;

    const formData2 = new FormData();

    const fileKeys = ["front", "back", "left", "right"];

    for (const key of fileKeys) {
      const file = formData[key];
      if (file) {
        if (!validateFileSize(file, key)) return;
        formData2.append(key, file);
      }
    }

    if (formData?.vin) {
      formData2.append("vin", JSON.stringify(formData.vin));
    }

    if (formData?.overview) {
      formData2.append("overview", JSON.stringify(formData.overview));
    }
    if (formData.keySpecs) {
      formData2.append(`keySpecs`, JSON.stringify(formData?.keySpecs));
    }

    formData2.append("isPublic", JSON.stringify(formData?.isPublic));

    formData2.append("status", JSON.stringify(formData?.status));

    dispatch(
      updateUsedCars({
        id: selectedUsedCar._id,
        updatedUsedCars: formData2,
      })
    ).then(() => {
      setEditUsedCarDialogOpen(false);
      resetForm();
    });
  };

  const handleAddUsedCarConfirm = () => {
    
    if (!validateForm()) return;

    const formData2 = new FormData();

    const fileKeys = ["front", "back", "left", "right"];

    for (const key of fileKeys) {
      const file = formData[key];
      if (file) {
        if (!validateFileSize(file, key)) return;
        formData2.append(key, file);
      }
    }

    if (formData?.vin) {
      formData2.append("vin", JSON.stringify(formData.vin));
    }

    if (formData?.overview) {
      formData2.append("overview", JSON.stringify(formData.overview));
    }
    if (formData.keySpecs) {
      formData2.append(`keySpecs`, JSON.stringify(formData.keySpecs));
    }

    formData2.append("isPublic", JSON.stringify(formData.isPublic));

    formData2.append("status", JSON.stringify(formData?.status));

    dispatch(addUsedCars(formData2)).then((data) => {
      setAddUsedCarDialogOpen(false);
      resetForm();
      const { totalPages, page } = data?.payload?.meta;
      if (totalPages > 1) {
        setCurrentPageIndex(totalPages - 1);
      } else if (totalPages == 1) {
        setCurrentPageIndex(0);
      }
      dispatch(
        fetchUsedCars({
          page: totalPages,
          limit: pagination.pageSize,
        })
      );
    });
  };

  const handleOffersChange = (e, index) => {
    const { name, value } = e.target;
    const updatedOffers = [...formData.offers];
    updatedOffers[index] = { ...updatedOffers[index], [name]: value };
    setFormData({ ...formData, offers: updatedOffers });
  };

  const handleColoursChange = (e, index) => {
    const { name, value } = e.target;
    const updatedColours = [...formData.colours];
    updatedColours[index] = { ...updatedColours[index], [name]: value };
    setFormData({ ...formData, colours: updatedColours });
  };

  // useEffect(() => {
  //   const totalPages = meta?.totalPages;
  //   if (totalPages > 1) {
  //     setCurrentPageIndex(pagination.pageIndex - 1);
  //   } else if (totalPages == 1) {
  //     setCurrentPageIndex(0);
  //   }
  //   dispatch(
  //     fetchUsedCars({
  //       page: pagination.pageIndex + 1,
  //       limit: pagination.pageSize,
  //     })
  //   );
  // }, [meta?.totalPages]);

  const handleDeleteUsedCars = (id) => {
    dispatch(deleteUsedCars(id)).then((data) => {
      const { totalPages, page } = data?.payload?.meta;
      if (totalPages > 1) {
        setCurrentPageIndex(totalPages - 1);
      } else if (totalPages == 1) {
        setCurrentPageIndex(0);
      }
      dispatch(
        fetchUsedCars({
          page: totalPages,
          limit: pagination.pageSize,
        })
      );
    });
  };

  // useEffect(() => {
  //   const filteredUsedCars = usedCars?.filter((car) =>
  //     car?.overview?.carName
  //       ?.toLowerCase()
  //       .includes(searchTabs?.searchText.toLowerCase()?.trim())
  //   );
  //   setFilteredUsedCars(filteredUsedCars);
  // }, [searchTabs?.searchText, usedCars]);

  useEffect(() => {
    dispatch(
      setUsedCarSelected({
        isActive: true,
        searchText: "",
      })
    );

    return () => {
      dispatch(
        setUsedCarSelected({
          isActive: false,
          searchText: "",
        })
      );
    };
  }, []);

  useEffect(() => {
    const fetchVin = async () => {
      if (formData?.vin?.length == 17) {
        try {
          const response = await axiosInstance.get(
            `usedCars/check-vin/${formData?.vin}`
          );
          setIsUnique({
            unique: response.data.isUnique,
            sameUser: response.data.sameUser,
          });
          setError("");
        } catch (error) {
          setError("Failed to validate VIN");
        }
      } else {
        setIsUnique(null);
      }
    };

    fetchVin();
  }, [formData?.vin]);

  const handleColorChange = (newValue) => {
    setSelectedColors(newValue);
    setFormData((prev) => {
      return {
        ...prev,
        keySpecs: {
          ...prev.keySpecs,
          colors: newValue,
        },
      };
    });
  };

  const onBack = () => {
    if (tab == 0) {
      setOCSetting(false);
    }
    setTab((count) => (count == 0 ? count : count - 1));
  };

  return (
    <>
      <div>
        <div className="h-[calc(75vh)] overflow-y-auto">
          {loading && (
            <div className="flex justify-center h-[calc(100vh-120px)] items-center">
              <ScreenLoader />
            </div>
          )}

          <div className="flex px-5 py-2 h-[80px] justify-between items-center border-b-[2px] border-lightBorderColor">
            {OCSetting ? (
              <FaArrowLeft
                size={25}
                style={{ cursor: "pointer" }}
                // onClick={() => setOCSetting(false)}
                onClick={onBack}

                color="rgb(153 0 0 / var(--tw-bg-opacity))"
              />
            ) : (
              <p className="text-black text-2xl">
                <p className="text-black text-2xl">All Used Cars </p>
              </p>
            )}

            <div className="flex gap-5">
              {OCSetting ? null : (
                <PrimaryButton
                  onClick={() => {
                    setAddUsedCarDialogOpen(true);
                    resetForm();
                  }}
                  title={"Add Cars"}
                />
              )}
              <IoSettings
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (usedCars?.length !== 0) setOCSetting(true);
                }}
                size={50}
                color={
                  usedCars?.length !== 0
                    ? "rgb(153 0 0 / var(--tw-bg-opacity))"
                    : "grey"
                }
              />
            </div>
          </div>

          {usedCars?.length == 0 && (
            <div className="h-[100%]">
              {<NoDataFound message={"No Used cars found"} />}
            </div>
          )}

          <div
            className={`flex p-5 w-full ${usedCars?.length == 0 && "hidden"}`}
          >
            {OCSetting ? (
              <UsedCarSettingIndex  tab={tab} setTab={setTab} />
            ) : (
              <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 w-full gap-10">
                {usedCars?.map((item) => (
                  <UsedCarCard
                    key={item._id}
                    item={item}
                    onEdit={() => handleEditUsedCars(item)}
                    onDelete={() => handleDeleteUsedCars(item._id)}
                    type={"usedCars"}
                  />
                ))}
              </div>
            )}
            {/* <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 w-full gap-10">
              {usedCars?.map((item) => (
                <UsedCarCard
                  key={item._id}
                  item={item}
                  onEdit={() => handleEditUsedCars(item)}
                  onDelete={() => handleDeleteUsedCars(item._id)}
                  type={"usedCars"}
                />
              ))}
            </div> */}
          </div>

          <Dialog
            open={addUsedCarDialogOpen}
            onClose={() => setAddUsedCarDialogOpen(false)}
            className="relative z-50"
          >
            <div className="fixed inset-0 bg-black/30 flex items-center justify-center p-4 ">
              <Dialog.Panel className="w-full max-w-5xl rounded bg-white p-4 max-h-[100vh]">
                <div className="pb-2">
                  <img
                    src={icons8crossFilled}
                    onClick={() => setAddUsedCarDialogOpen(false)}
                    className="w-8 h-8 cursor-pointer ml-auto"
                  />
                </div>

                <div className="overflow-auto no-scrollbar w-full max-h-[75vh] p-1">
                  <div class="flex items-center my-4">
                    <hr class="flex-grow border-t border-muted border-black" />
                    <span class="mx-4 text-muted-foreground font-semibold text-xl">
                      Upload Images
                    </span>
                    <hr class="flex-grow border-t border-muted border-black" />
                  </div>

                  <div className="grid grid-cols-4 gap-2">
                    <ImageUploadButton
                      stateData={formData?.front}
                      setStateData={(value) =>
                        setFormData({ ...formData, front: value })
                      }
                      refData={imageRefs.front}
                      view={"Front View"}
                    />
                    <ImageUploadButton
                      stateData={formData.back}
                      setStateData={(value) =>
                        setFormData({ ...formData, back: value })
                      }
                      refData={imageRefs.back}
                      view={"Back View"}
                    />
                    <ImageUploadButton
                      stateData={formData.left}
                      setStateData={(value) =>
                        setFormData({ ...formData, left: value })
                      }
                      refData={imageRefs.left}
                      view={"Left View"}
                    />
                    <ImageUploadButton
                      stateData={formData.right}
                      setStateData={(value) =>
                        setFormData({ ...formData, right: value })
                      }
                      refData={imageRefs.right}
                      view={"Right View"}
                    />
                  </div>

                  <div className="">
                    <div className="">
                      <div class="flex items-center my-4">
                        <hr class="flex-grow border-t border-muted border-black" />
                        <span class="mx-4 text-muted-foreground font-semibold text-xl">
                          Overview
                        </span>
                        <hr class="flex-grow border-t border-muted border-black" />
                      </div>

                      <div className="grid grid-cols-3 gap-5 mt-5">
                        <div>
                          <InputFields
                            type="text"
                            title="Vin Number"
                            placeholder="Vin Number"
                            value={formData?.vin}
                            name="vin"
                            onChange={handleInputChange}
                          />

                          {isUnique?.unique === false && (
                            <p className="mt-2" style={{ color: "red" }}>
                              This VIN is already registered.
                            </p>
                          )}
                          {isUnique?.unique === true && (
                            <p className="mt-2" style={{ color: "green" }}>
                              This VIN is available.
                            </p>
                          )}
                          {error && <p style={{ color: "red" }}>{error}</p>}
                        </div>

                        <InputFields
                          type="text"
                          title="Car Name"
                          placeholder="Car Name"
                          value={formData?.overview?.carName}
                          name="overview.carName"
                          onChange={handleInputChange}
                        />
                        <InputFields
                          type="number"
                          title="Price"
                          placeholder="Price"
                          value={formData?.overview?.price}
                          name="overview.price"
                          onChange={handleInputChange}
                          min={0}
                          step={1}
                          onKeyDown={(e) => {
                            if (e.code === "Minus") {
                              e.preventDefault();
                            }
                          }}
                        />
                        {/* Add more fields as needed */}
                        {/* <InputFields
                          type="text"
                          title="Engine Type"
                          placeholder="Engine Type"
                          value={formData?.overview?.engineType}
                          name="overview.engineType"
                          onChange={handleInputChange}
                        /> */}
                        <div>
                          <label className="mb-1 text-sm font-medium">
                            Engine Type
                          </label>
                          <div className="flex flex-row mt-1">
                            <select
                              name="overview.engineType"
                              value={formData?.overview?.engineType}
                              onChange={handleInputChange}
                              className=" border-[1px] w-full h-[49px] mt-0 transition bg-white ease-in-out delay-150 border-[#ccc] truncate leading-[19.07px] text-gray-600 text-lg py-[8px] px-2 lg:pl-3 placeholder:text-base  rounded-md  focus:outline-none focus:border-blue-300"
                            >
                              <option value="">Select Engine Type</option>
                              <option value="V engine">V engine</option>
                              <option value="Inline engine">
                                Inline engine
                              </option>
                              <option value="Rotary engine">
                                Rotary engine
                              </option>
                              <option value="Diesel engine">
                                Diesel engine
                              </option>
                              <option value="Hybrid engine">
                                Hybrid engine
                              </option>
                              <option value="W engine">W engine</option>
                              <option value="External combustion engine">
                                External combustion engine
                              </option>
                            </select>
                          </div>
                        </div>
                        <InputFields
                          type="number"
                          title="Driven KM"
                          placeholder="Driven KM"
                          value={formData?.overview?.drivenKm}
                          name="overview.drivenKm"
                          onChange={handleInputChange}
                          min={0}
                          step={1}
                          onKeyDown={(e) => {
                            if (e.code === "Minus") {
                              e.preventDefault();
                            }
                          }}
                        />
                        <InputFields
                          type="number"
                          title="Make Year"
                          placeholder="Make Year"
                          value={formData?.overview?.makeYear}
                          name="overview.makeYear"
                          onChange={handleInputChange}
                          min={0}
                          step={1}
                          onKeyDown={(e) => {
                            if (e.code === "Minus") {
                              e.preventDefault();
                            }
                          }}
                        />
                        <InputFields
                          type="number"
                          title="Registration Year"
                          placeholder="Registration Year"
                          value={formData?.overview?.registrationYear}
                          name="overview.registrationYear"
                          onChange={handleInputChange}
                          min={0}
                          step={1}
                          onKeyDown={(e) => {
                            if (e.code === "Minus") {
                              e.preventDefault();
                            }
                          }}
                        />

                        {/* Transmission */}
                        <div>
                          <label className="mb-1 text-sm font-medium">
                            Transmission
                          </label>
                          <div className="flex flex-row mt-1">
                            <select
                              name="overview.transmission"
                              value={formData?.overview?.transmission}
                              onChange={handleInputChange}
                              className=" border-[1px] w-full h-[49px] mt-0 transition bg-white ease-in-out delay-150 border-[#ccc] truncate leading-[19.07px] text-gray-600 text-lg py-[8px] px-2 lg:pl-3 placeholder:text-base  rounded-md  focus:outline-none focus:border-blue-300"
                              required
                            >
                              <option value="">Select Transmission</option>
                              <option value="Automatic">Automatic</option>
                              <option value="Manual">Manual</option>
                            </select>
                          </div>
                        </div>

                        <InputFields
                          type="number"
                          title="Number of Owners"
                          placeholder="Number of Owners"
                          value={formData?.overview?.numberOfOwners}
                          name="overview.numberOfOwners"
                          onChange={handleInputChange}
                          min={1}
                          step={1}
                          onKeyDown={(e) => {
                            if (e.code === "Minus") {
                              e.preventDefault();
                            }
                          }}
                        />

                        {/* <InputFields
                          type="text"
                          title="Insurance Type"
                          placeholder="Insurance Type"
                          value={formData?.overview?.insuranceType}
                          name="overview.insuranceType"
                          onChange={handleInputChange}
                        /> */}
                        {/* Insurance Type */}
                        <div>
                          <label className="mb-2 text-sm font-medium">
                            Insurance Type
                          </label>
                          <div className="flex flex-row mt-1">
                            <select
                              name="overview.insuranceType"
                              value={formData?.overview?.insuranceType}
                              onChange={handleInputChange}
                              className=" border-[1px] w-full h-[49px] mt-0 transition bg-white ease-in-out delay-150 border-[#ccc] truncate leading-[19.07px] text-gray-600 text-lg py-[8px] px-2 lg:pl-3 placeholder:text-base  rounded-md  focus:outline-none focus:border-blue-300"
                              required
                            >
                              <option value="">Select Insurance Type</option>
                              <option value="Comprehensive">
                                Comprehensive
                              </option>
                              <option value="Third-Party">Third-Party</option>
                            </select>
                          </div>
                        </div>

                        <InputFields
                          type="text"
                          title="Car Model"
                          placeholder="Car Model"
                          value={formData?.overview?.carModel}
                          name="overview.carModel"
                          onChange={handleInputChange}
                        />

                        <InputFields
                          type="text"
                          title="Location"
                          placeholder="Location"
                          value={formData?.overview?.location}
                          name="overview.location"
                          onChange={handleInputChange}
                        />

                        <div>
                          <label className="mb-1 text-sm font-medium">
                            Listing Type
                          </label>

                          <div className="flex flex-row mt-1">
                            <select
                              id="isPublic"
                              name="isPublic"
                              value={formData?.isPublic}
                              onChange={handleInputChange}
                              //   className="py-4 px-4 border rounded"
                              className=" border-[1px] w-full h-[49px] mt-0 transition bg-white ease-in-out delay-150 border-[#ccc] truncate leading-[19.07px] text-gray-600 text-lg py-[8px] px-2 lg:pl-3 placeholder:text-base  rounded-md  focus:outline-none focus:border-blue-300"
                            >
                              <option value="">Select Listing Type</option>
                              <option value={true}>Public</option>
                              <option value={false}>Private</option>
                            </select>
                          </div>
                        </div>

                        <div>
                          <label className="mb-1 text-sm font-medium">
                            Status
                          </label>

                          <div className="flex flex-row mt-1">
                            <select
                              id="status"
                              name="status"
                              value={formData?.status}
                              onChange={handleInputChange}
                              //   className="py-4 px-4 border rounded"
                              className=" border-[1px] w-full h-[49px] mt-0 transition bg-white ease-in-out delay-150 border-[#ccc] truncate leading-[19.07px] text-gray-600 text-lg py-[8px] px-2 lg:pl-3 placeholder:text-base  rounded-md  focus:outline-none focus:border-blue-300"
                            >
                              <option value="">Select Car Status</option>
                              <option value={"available"}>Available</option>
                              <option value={"on-hold"}>On-hold</option>
                            </select>
                          </div>
                        </div>

                        <div className="">
                          <InputFields
                            type="date"
                            title="Insurance Validity"
                            placeholder="Insurance Validity"
                            value={
                              formData?.overview?.insuranceValidity?.split(
                                "T"
                              )[0]
                            }
                            name="overview.insuranceValidity"
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div>
                      <div class="flex items-center my-4">
                        <hr class="flex-grow border-t border-muted border-black" />
                        <span class="mx-4 text-muted-foreground font-semibold text-xl">
                          Key Specs
                        </span>
                        <hr class="flex-grow border-t border-muted border-black" />
                      </div>

                      <div className="grid grid-cols-3 gap-5 mt-5">
                        <InputFields
                          type="text"
                          title="Engine"
                          placeholder="Engine"
                          value={formData?.keySpecs?.engine}
                          name="keySpecs.engine"
                          onChange={handleInputChange}
                        />
                        <div className="">
                          <label className="mb-2 text-sm font-medium">
                            Colors
                          </label>
                          <CreatableSelect
                            isMulti
                            className="z-50"
                            styles={{
                              control: (base) => ({
                                ...base,
                                minHeight: "49px", // Adjust this height to match your other input fields
                                height: "49px", // This ensures the select box has the same fixed height
                                borderColor: "#ccc", // Same border color as other input fields
                                boxShadow: "none",
                                marginTop: "4px", // Remove default focus styles (if any)
                              }),
                              valueContainer: (base) => ({
                                ...base,
                                padding: "0 8px", // Adjust padding to match other input fields if needed
                              }),
                            }}
                            // className="border-[1px] w-full h-[49px] mt-0 transition bg-white ease-in-out delay-150 border-[#ccc] truncate leading-[19.07px] text-gray-600 text-lg py-[8px] px-2 lg:pl-3 placeholder:text-base  rounded-md  focus:outline-none focus:border-blue-300"
                            options={colorOptions}
                            onChange={handleColorChange}
                            value={formData?.keySpecs?.colors}
                            placeholder="Select or type custom colors..."
                          />
                        </div>
                        <InputFields
                          type="text"
                          title="Max Power in HP or kW"
                          placeholder="Max Power in HP or kW"
                          value={formData?.keySpecs?.maxPower}
                          name="keySpecs.maxPower"
                          onChange={handleInputChange}
                        />
                        <InputFields
                          type="text"
                          title="Max Power (RPM)"
                          placeholder="Max Power (RPM)"
                          value={formData?.keySpecs?.maxPowerRPM}
                          name="keySpecs.maxPowerRPM"
                          onChange={handleInputChange}
                        />
                        <InputFields
                          type="text"
                          title="Mileage (KMPL)"
                          placeholder="Mileage"
                          value={formData?.keySpecs?.mileage}
                          name="keySpecs.mileage"
                          onChange={handleInputChange}
                        />
                        <InputFields
                          type="number"
                          title="Seating Capacity"
                          placeholder="Seating Capacity"
                          value={formData?.keySpecs?.seatingCapacity}
                          name="keySpecs.seatingCapacity"
                          onChange={handleInputChange}
                          min={0}
                          step={1}
                          onKeyDown={(e) => {
                            if (e.code === "Minus") {
                              e.preventDefault();
                            }
                          }}
                        />
                        {/* New select tags for specific fields */}
                        <div>
                          <label className="mb-1 text-sm font-medium">
                            Fuel Type
                          </label>
                          <div className="flex flex-row mt-1">
                            <select
                              name="keySpecs.fuelType"
                              value={formData?.keySpecs?.fuelType}
                              onChange={handleInputChange}
                              className="border-[1px] w-full h-[49px] mt-0 transition bg-white ease-in-out delay-150 border-[#ccc] truncate leading-[19.07px] text-gray-600 text-lg py-[8px] px-2 lg:pl-3 placeholder:text-base  rounded-md  focus:outline-none focus:border-blue-300"
                              required
                            >
                              <option value="">Select Fuel Type</option>
                              <option value="Petrol">Petrol</option>
                              <option value="Diesel">Diesel</option>
                              <option value="Electric">Electric</option>
                              <option value="Hybrid">Hybrid</option>
                            </select>
                          </div>
                        </div>

                        {/* <InputFields
                          type="text"
                          title="Fuel Type"
                          placeholder="Fuel Type"
                          value={formData?.keySpecs?.fuelType}
                          name="keySpecs.fuelType"
                          onChange={handleInputChange}
                        /> */}
                      </div>
                      {/* <SlotsCreater /> */}
                    </div>
                  </div>

                  <div className="flex justify-center mt-5">
                    <PrimaryButton
                      onClick={handleAddUsedCarConfirm}
                      title="Add Used Car"
                      loading={loading}
                    />
                  </div>
                </div>
              </Dialog.Panel>
            </div>
          </Dialog>

          <Dialog
            open={editUsedCarDialogOpen}
            onClose={() => setEditUsedCarDialogOpen(false)}
            className="relative z-50"
          >
            <div className="fixed inset-0 bg-black/30 flex items-center justify-center p-4 ">
              <Dialog.Panel className="w-full max-w-5xl rounded bg-white p-4 max-h-[100vh] overflow-auto no-scrollbar">
                <div className="pb-2">
                  <img
                    src={icons8crossFilled}
                    onClick={() => setEditUsedCarDialogOpen(false)}
                    className="w-8 h-8 cursor-pointer ml-auto"
                  />
                </div>

                <div className="overflow-auto no-scrollbar w-full max-h-[75vh] p-1">
                  <div class="flex items-center my-4">
                    <hr class="flex-grow border-t border-muted border-black" />
                    <span class="mx-4 text-muted-foreground font-semibold text-xl">
                      Upload Images
                    </span>
                    <hr class="flex-grow border-t border-muted border-black" />
                  </div>

                  <div className="grid grid-cols-4 gap-2">
                    <ImageUploadButton
                      stateData={formData.front}
                      setStateData={(value) =>
                        setFormData({ ...formData, front: value })
                      }
                      refData={imageRefs.front}
                      view={"Front View"}
                    />
                    <ImageUploadButton
                      stateData={formData.back}
                      setStateData={(value) =>
                        setFormData({ ...formData, back: value })
                      }
                      refData={imageRefs.back}
                      view={"Back View"}
                    />
                    <ImageUploadButton
                      stateData={formData.left}
                      setStateData={(value) =>
                        setFormData({ ...formData, left: value })
                      }
                      refData={imageRefs.left}
                      view={"Left View"}
                    />
                    <ImageUploadButton
                      stateData={formData.right}
                      setStateData={(value) =>
                        setFormData({ ...formData, right: value })
                      }
                      refData={imageRefs.right}
                      view={"Right View"}
                    />
                  </div>

                  <div className="">
                    <div className="">
                      <div class="flex items-center my-4">
                        <hr class="flex-grow border-t border-muted border-black" />
                        <span class="mx-4 text-muted-foreground font-semibold text-xl">
                          Overview
                        </span>
                        <hr class="flex-grow border-t border-muted border-black" />
                      </div>

                      <div className="grid grid-cols-3 gap-5 mt-5">
                        <div>
                          <InputFields
                            type="text"
                            title="Vin Number"
                            placeholder="Vin Number"
                            value={formData?.vin}
                            name="vin"
                            disabled={true}
                            onChange={handleInputChange}
                          />

                          {error && <p style={{ color: "red" }}>{error}</p>}
                        </div>

                        <InputFields
                          type="text"
                          title="Car Name"
                          placeholder="Car Name"
                          value={formData?.overview?.carName}
                          name="overview.carName"
                          onChange={handleInputChange}
                        />
                        <InputFields
                          type="text"
                          title="Price"
                          placeholder="Price"
                          value={formData?.overview?.price}
                          name="overview.price"
                          onChange={handleInputChange}
                        />
                        {/* Add more fields as needed */}
                        {/* <InputFields
                          type="text"
                          title="Engine Type"
                          placeholder="Engine Type"
                          value={formData?.overview?.engineType}
                          name="overview.engineType"
                          onChange={handleInputChange}
                        /> */}
                        <div>
                          <label className="mb-1 text-sm font-medium">
                            Engine Type
                          </label>
                          <div className="flex flex-row mt-1">
                            <select
                              name="overview.engineType"
                              value={formData?.overview?.engineType}
                              onChange={handleInputChange}
                              className=" border-[1px] w-full h-[49px] mt-0 transition bg-white ease-in-out delay-150 border-[#ccc] truncate leading-[19.07px] text-gray-600 text-lg py-[8px] px-2 lg:pl-3 placeholder:text-base  rounded-md  focus:outline-none focus:border-blue-300"
                            >
                              <option value="">Select Engine Type</option>
                              <option value="V engine">V engine</option>
                              <option value="Inline engine">
                                Inline engine
                              </option>
                              <option value="Rotary engine">
                                Rotary engine
                              </option>
                              <option value="Diesel engine">
                                Diesel engine
                              </option>
                              <option value="Hybrid engine">
                                Hybrid engine
                              </option>
                              <option value="W engine">W engine</option>
                              <option value="External combustion engine">
                                External combustion engine
                              </option>
                            </select>
                          </div>
                        </div>
                        <InputFields
                          type="number"
                          title="Driven KM"
                          placeholder="Driven KM"
                          value={formData?.overview?.drivenKm}
                          name="overview.drivenKm"
                          onChange={handleInputChange}
                          min={0}
                          step={1}
                          onKeyDown={(e) => {
                            if (e.code === "Minus") {
                              e.preventDefault();
                            }
                          }}
                        />

                        <InputFields
                          type="number"
                          title="Make Year"
                          placeholder="Make Year"
                          value={formData?.overview?.makeYear}
                          name="overview.makeYear"
                          onChange={handleInputChange}
                          min={0}
                          step={1}
                          onKeyDown={(e) => {
                            if (e.code === "Minus") {
                              e.preventDefault();
                            }
                          }}
                        />
                        <InputFields
                          type="number"
                          title="Registration Year"
                          placeholder="Registration Year"
                          value={formData?.overview?.registrationYear}
                          name="overview.registrationYear"
                          onChange={handleInputChange}
                          min={0}
                          step={1}
                          onKeyDown={(e) => {
                            if (e.code === "Minus") {
                              e.preventDefault();
                            }
                          }}
                        />
                        {/* <InputFields
                          type="text"
                          title="Transmission"
                          placeholder="Transmission"
                          value={formData?.overview?.transmission}
                          name="overview.transmission"
                          onChange={handleInputChange}
                        /> */}
                        <div>
                          <label className="mb-1 text-sm font-medium">
                            Transmission
                          </label>
                          <div className="flex flex-row mt-1">
                            <select
                              name="overview.transmission"
                              value={formData?.overview?.transmission}
                              onChange={handleInputChange}
                              className=" border-[1px] w-full h-[49px] mt-0 transition bg-white ease-in-out delay-150 border-[#ccc] truncate leading-[19.07px] text-gray-600 text-lg py-[8px] px-2 lg:pl-3 placeholder:text-base  rounded-md  focus:outline-none focus:border-blue-300"
                              required
                            >
                              <option value="">Select Transmission</option>
                              <option value="Automatic">Automatic</option>
                              <option value="Manual">Manual</option>
                            </select>
                          </div>
                        </div>
                        <InputFields
                          type="number"
                          title="Number of Owners"
                          placeholder="Number of Owners"
                          value={formData?.overview?.numberOfOwners}
                          name="overview.numberOfOwners"
                          onChange={handleInputChange}
                          min={0}
                          step={1}
                          onKeyDown={(e) => {
                            if (e.code === "Minus") {
                              e.preventDefault();
                            }
                          }}
                        />
                        <InputFields
                          type="date"
                          title="Insurance Validity"
                          placeholder="Insurance Validity"
                          value={
                            formData?.overview?.insuranceValidity?.split("T")[0]
                          }
                          name="overview.insuranceValidity"
                          onChange={handleInputChange}
                        />
                        {/* <InputFields
                          type="text"
                          title="Insurance Type"
                          placeholder="Insurance Type"
                          value={formData?.overview?.insuranceType}
                          name="overview.insuranceType"
                          onChange={handleInputChange}
                        /> */}
                        <div>
                          <label className="mb-2 text-sm font-medium">
                            Insurance Type
                          </label>
                          <div className="flex flex-row mt-1">
                            <select
                              name="overview.insuranceType"
                              value={formData?.overview?.insuranceType}
                              onChange={handleInputChange}
                              className=" border-[1px] w-full h-[49px] mt-0 transition bg-white ease-in-out delay-150 border-[#ccc] truncate leading-[19.07px] text-gray-600 text-lg py-[8px] px-2 lg:pl-3 placeholder:text-base  rounded-md  focus:outline-none focus:border-blue-300"
                              required
                            >
                              <option value="">Select Insurance Type</option>
                              <option value="Comprehensive">
                                Comprehensive
                              </option>
                              <option value="Third-Party">Third-Party</option>
                            </select>
                          </div>
                        </div>

                        <InputFields
                          type="text"
                          title="Insurance Type"
                          placeholder="Car Model"
                          value={formData?.overview?.carModel}
                          name="overview.carModel"
                          onChange={handleInputChange}
                        />

                        <InputFields
                          type="text"
                          title="Location"
                          placeholder="Location"
                          value={formData?.overview?.location}
                          name="overview.location"
                          onChange={handleInputChange}
                        />

                        <div>
                          <label className="mb-1 text-sm font-medium">
                            Listing Type
                          </label>

                          <div className="flex flex-row mt-1">
                            <select
                              id="isPublic"
                              name="isPublic"
                              value={formData?.isPublic}
                              onChange={handleInputChange}
                              //   className="py-4 px-4 border rounded"
                              className=" border-[1px] w-full h-[49px] mt-0 transition bg-white ease-in-out delay-150 border-[#ccc] truncate leading-[19.07px] text-gray-600 text-lg py-[8px] px-2 lg:pl-3 placeholder:text-base  rounded-md  focus:outline-none focus:border-blue-300"
                            >
                              <option value="">Select Listing Type</option>

                              <option value={true}>Public</option>
                              <option value={false}>Private</option>
                            </select>
                          </div>
                        </div>
                        <div>
                          <label className="mb-1 text-sm font-medium">
                            Status
                          </label>

                          <div className="flex flex-row mt-1">
                            <select
                              id="status"
                              name="status"
                              value={formData?.status}
                              onChange={handleInputChange}
                              //   className="py-4 px-4 border rounded"
                              className=" border-[1px] w-full h-[49px] mt-0 transition bg-white ease-in-out delay-150 border-[#ccc] truncate leading-[19.07px] text-gray-600 text-lg py-[8px] px-2 lg:pl-3 placeholder:text-base  rounded-md  focus:outline-none focus:border-blue-300"
                            >
                              <option value="">Select Your Car Status</option>

                              <option value={"available"}>Available</option>
                              <option value={"on-hold"}>On-hold</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div class="flex items-center my-4">
                        <hr class="flex-grow border-t border-muted border-black" />
                        <span class="mx-4 text-muted-foreground font-semibold text-xl">
                          Key Specs
                        </span>
                        <hr class="flex-grow border-t border-muted border-black" />
                      </div>

                      <div className="grid grid-cols-3 gap-5 mt-5">
                        <InputFields
                          type="text"
                          title="Engine"
                          placeholder="Engine"
                          value={formData?.keySpecs?.engine}
                          name="keySpecs.engine"
                          onChange={handleInputChange}
                        />
                        <div className="">
                          <label className="mb-2 text-sm font-medium">
                            Colors
                          </label>
                          <CreatableSelect
                            isMulti
                            className="z-50"
                            styles={{
                              control: (base) => ({
                                ...base,
                                minHeight: "49px", // Adjust this height to match your other input fields
                                height: "49px", // This ensures the select box has the same fixed height
                                borderColor: "#ccc", // Same border color as other input fields
                                boxShadow: "none",
                                marginTop: "4px", // Remove default focus styles (if any)
                              }),
                              valueContainer: (base) => ({
                                ...base,
                                padding: "0 8px", // Adjust padding to match other input fields if needed
                              }),
                            }}
                            // className="border-[1px] w-full h-[49px] mt-0 transition bg-white ease-in-out delay-150 border-[#ccc] truncate leading-[19.07px] text-gray-600 text-lg py-[8px] px-2 lg:pl-3 placeholder:text-base  rounded-md  focus:outline-none focus:border-blue-300"
                            options={colorOptions}
                            onChange={handleColorChange}
                            value={formData?.keySpecs?.colors}
                            placeholder="Select or type custom colors..."
                          />
                        </div>
                        <InputFields
                          type="text"
                          title="Max Power in HP or kW"
                          placeholder="Max Power"
                          value={formData?.keySpecs?.maxPower}
                          name="keySpecs.maxPower"
                          onChange={handleInputChange}
                        />
                        <InputFields
                          type="text"
                          title="Max Power (RPM)"
                          placeholder="Max Power RPM"
                          value={formData?.keySpecs?.maxPowerRPM}
                          name="keySpecs.maxPowerRPM"
                          onChange={handleInputChange}
                        />
                        <InputFields
                          type="text"
                          title="Mileage (KMPL)"
                          placeholder="Mileage"
                          value={formData?.keySpecs?.mileage}
                          name="keySpecs.mileage"
                          onChange={handleInputChange}
                        />
                        <InputFields
                          type="number"
                          title="Seating Capacity"
                          placeholder="Seating Capacity"
                          value={formData?.keySpecs?.seatingCapacity}
                          name="keySpecs.seatingCapacity"
                          onChange={handleInputChange}
                          min={0}
                          step={1}
                          onKeyDown={(e) => {
                            if (e.code === "Minus") {
                              e.preventDefault();
                            }
                          }}
                        />
                        {/* <InputFields
                          type="text"
                          title="Fuel Type"
                          placeholder="Fuel Type"
                          value={formData?.keySpecs?.fuelType}
                          name="keySpecs.fuelType"
                          onChange={handleInputChange}
                        /> */}
                        <div>
                          <label className="mb-1 text-sm font-medium">
                            Fuel Type
                          </label>
                          <div className="flex flex-row mt-1">
                            <select
                              name="keySpecs.fuelType"
                              value={formData?.keySpecs?.fuelType}
                              onChange={handleInputChange}
                              className="border-[1px] w-full h-[49px] mt-0 transition bg-white ease-in-out delay-150 border-[#ccc] truncate leading-[19.07px] text-gray-600 text-lg py-[8px] px-2 lg:pl-3 placeholder:text-base  rounded-md  focus:outline-none focus:border-blue-300"
                              required
                            >
                              <option value="">Select Fuel Type</option>
                              <option value="Petrol">Petrol</option>
                              <option value="Diesel">Diesel</option>
                              <option value="Electric">Electric</option>
                              <option value="Hybrid">Hybrid</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-center mt-5">
                    <PrimaryButton
                      onClick={handleUpdateUsedCar}
                      title="Update Details"
                      loading={loading}
                    />
                  </div>
                </div>
              </Dialog.Panel>
            </div>
          </Dialog>
        </div>
        <div className="w-full mt-auto sticky bottom-0 bg-white p-4">
          {/* Reusable Pagination Component */}
          {OCSetting ? null : (
            <Pagination
              currentPageIndex={currentPageIndex}
              totalPages={meta?.totalPages}
              handlePageChange={handlePageChange}
              canNextPage={
                Math.ceil(meta?.total / pagination?.pageSize) > meta?.page
              }
              canPreviousPage={meta?.page > 1}
            />
          )}
        </div>
      </div>
    </>
  );
};
