import React, { useCallback, useEffect, useMemo, useState } from "react";
import axiosInstance, {
  notifyError,
  notifySuccess,
} from "../../utility/axiosInstance";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import AcceptButton from "../staticButtons/acceptButton";
import RejectButton from "../staticButtons/rejectButton";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { AiOutlineLoading } from "react-icons/ai";

import {
  leftIcon,
  rightIcon,
  disabledLeftIcon,
  disabledRightIcon,
  icons8crossFilled,
} from "../../assets";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
// import useSocket from "../../hooks/useSocket";
import {
  fetchNotifications,
  clearNotifications,
} from "../../redux/notificationsThunk";
import { Dialog } from "@headlessui/react";

import { Steps } from "rsuite";
import "rsuite/Steps/styles/index.css";
import BookingSteps from "./BookingSteps";
import { formatString } from "../../utility/api";
import { useCarBookingsSocket } from "../../hooks/useCarBookingsSocket";
import Pagination from "../Common/Pagination";

// const fetchCarBookings = async () => {
//   try {
//     const response = await axiosInstance.get("usedCars/admin/bookings");
//     return response.data;
//   } catch (error) {
//     console.error("Error fetching data", error);
//     return [];
//   }
// };

const UsedCarsRequestTable = React.memo(() => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [buttonLoading, setButtonLoading] = useState({});

  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const [pagination, setPagination] = useState({
    pageIndex: currentPageIndex,
    pageSize: 5,
  });

  const [sorting, setSorting] = useState([]);

  const [loading2, setLoading2] = useState(false);

  const dispatch = useDispatch();
  const searchTabs = useSelector((state) => state.search);

  const [carDealStatus, setCarDealStatus] = useState({
    isActive: false,
    data: "",
    _id: "",
  });

  const [currentStepInfo, setCurrentStepInfo] = useState(0);

  const currentStep = (info) => {
    setCurrentStepInfo(info);
  };
  const [isReject, setIsReject] = useState(false);

  const fetchCarBookings = useMemo(
    () => async () => {
      try {
        const response = await axiosInstance.get("usedCars/admin/bookings");
        return response.data;
      } catch (error) {
        console.error("Error fetching data", error);
        return [];
      }
    },
    []
  );

  const handlePageChange = (newPageIndex) => {
    setCurrentPageIndex(newPageIndex);
    setPagination((prev) => ({ ...prev, pageIndex: newPageIndex }));
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    const { bookings: allData } = await fetchCarBookings();
    const searchText = searchTabs?.searchText?.toLowerCase()?.trim();
    const filteredData = allData?.filter((item) => {
      const fullName =
        `${item?.buyer?.firstName} ${item?.buyer?.lastName}`.toLowerCase();
      return (
        fullName?.includes(searchText) ||
        item?.buyer?.email?.toLowerCase()?.includes(searchText) ||
        // item?.car?.model?.toLowerCase()?.includes(searchText) ||
        item?.car?.overview?.carName?.toLowerCase()?.includes(searchText) ||
        item?._id?.toLowerCase()?.includes(searchText)
      );
    });
    setData(filteredData);
    setLoading(false);
  }, [fetchCarBookings, searchTabs.searchText]);

  const usedCarRequest = useSelector(
    (state) => state?.usedCars?.usedCarRequest
  );

  useEffect(() => {
    fetchData();
    return () => {
      console.log("unmounted");
    };
  }, [usedCarRequest, searchTabs.searchText, fetchData]);

  useEffect(() => {
    fetchData();
  }, [dispatch, searchTabs.searchText]);

  const handleSwitchStatus = async (info, status) => {
    try {
      const response = await axiosInstance.put(
        `usedCars/bookings/${info.row.original._id}`,
        {
          status: status ? "accepted" : "rejected",
        }
      );
      if (response.status === 200) {
        if (response.data?.booking.status === "accepted") {
          notifySuccess("Car booking request has been accepted");
        } else {
          toast.warn("Car booking request has been rejected");
        }

        // Update the status in the data array
        setData((prevData) =>
          prevData.map((item) =>
            item._id === response.data?.booking?._id
              ? {
                  ...item,
                  status: response.data.booking.status,
                  cancellationReason: response.data.booking.cancellationReason,
                  statusHistory: response.data.booking.statusHistory,
                }
              : item
          )
        );
      } else {
        console.error("Error updating status", response);
      }
    } catch (error) {
      notifyError(`Error updating status ${error}`);
    }
  };

  const columnHelper = createColumnHelper();

  const columns = useMemo(
    () => [
      columnHelper.accessor("_id", {
        cell: (info) => info.row.index + 1,
        header: "Sr no.",
      }),
      columnHelper.accessor(
        (row) => row?.buyer?.firstName + " " + row?.buyer?.lastName,
        {
          header: () => "Name",
          id: "name",
          cell: (info) => <i>{info.getValue()}</i>,
        }
      ),
      // columnHelper.accessor((row) => row.buyer.email, {
      //   header: () => "Email",
      //   id: "email",
      //   cell: (info) => <i>{info.getValue()}</i>,
      // }),
      columnHelper.accessor((row) => row?.car?.overview?.carName, {
        header: () => "Car Model",
        id: "carModel",
        cell: (info) => <i>{info.getValue()}</i>,
      }),
      columnHelper.accessor((row) => row?.testDrive?.date, {
        header: () => "Booking Date",
        id: "testDrive",
        cell: (info) => <i>{new Date(info.getValue()).toDateString()}</i>,
      }),
      columnHelper.accessor("status", {
        header: () => "Status",
        cell: (info) => (
          <div className="flex gap-3 justify-center">
            {info.getValue() == "pending" ? (
              <>
                <AcceptButton accept={() => handleSwitchStatus(info, true)} />
                <RejectButton
                  reject={() => {
                    setCarDealStatus({
                      isActive: false,
                      data: `${info.getValue()} at`,
                      _id: info.row.original._id,
                      currentStatus: info.getValue(),
                      buyerDetail: info.row.original.buyer,
                      carDetail: info.row.original.car,
                      cancellationReason:
                        info?.row?.original?.cancellationReason,
                    });
                    setIsReject(!isReject);
                  }}
                />
              </>
            ) : (
              <>
                <i
                  onClick={() => {
                    setCarDealStatus({
                      isActive: true,
                      data: `${info.getValue()} at ${
                        info.row.original.statusHistory
                      }`,
                      _id: info.row.original._id,
                      currentStatus: info.getValue(),
                      buyerDetail: info.row.original.buyer,
                      carDetail: info.row.original.car,
                      cancellationReason:
                        info?.row?.original?.cancellationReason,
                      booking: info.row.original,
                    });

                    currentStep(info.getValue());
                  }}
                  className="cursor-pointer"
                >
                  {formatString(info.getValue())}
                </i>
              </>
            )}
          </div>
        ),
      }),
    ],
    [handleSwitchStatus]
  );

  const table = useReactTable({
    data,
    columns,
    onPaginationChange: setPagination,
    state: {
      sorting,
      pagination: { ...pagination, pageIndex: currentPageIndex },
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const customStepStyles = {
    color: "#4CAF50", // Change text color
    fontWeight: "bold",
  };

  const customIconStyles = {
    backgroundColor: "#4CAF50", // Change icon background color
    borderColor: "#4CAF50", // Change icon border color
  };
  const handleUpdate = async (status, updatedData) => {
    setLoading2(true);
    setButtonLoading({
      ...status,
      status: true,
    });
    try {
      const payload = {};

      if (updatedData != null) {
        Object.assign(payload, status);
      }

      console.log(status, updatedData, "payload");

      const response = await axiosInstance.put(
        `usedCars/bookings/${updatedData?._id}`,
        // {
        //   status: status || updatedData?.status,
        //   cancellationReason: updatedData?.cancellationReason || "",
        //   proposedDate: updatedData?.proposedDate || "",
        //   remarks: updatedData?.remarks || "",
        //   generatePaymentLink: {
        //     amount: updatedData?.generatePaymentLink?.amount || 200000,
        //   },
        // }
        payload
      );
      if (response.data) {
        console.log(response?.data, "response");
        notifySuccess(response?.data?.message);

        setCarDealStatus({
          ...carDealStatus,
          currentStatus: response.data.booking.status,
          cancellationReason: response.data.booking.cancellationReason,
          booking: response?.data.booking,
        });

        setIsReject(false);

        const { bookings: allData } = await fetchCarBookings();
        setData(allData);
        currentStep(response.data.booking.status);
      } else {
        console.error("Error updating status", response);
      }
    } catch (error) {
      // notifyError(`Error updating status ${error}`);
    } finally {
      setLoading2(false);
      setButtonLoading({
        ...status,
        status: false,
      });
    }
  };

  return (
    <div className="">
      {loading ? (
        <div>
          {" "}
          <AiOutlineLoading className="h-6 w-6 animate-spin m-auto" />
        </div>
      ) : data?.length == 0 ? (
        <div className="text-center p-8">No used cars request yet</div>
      ) : (
        <div>
          <table className="w-full border-borderColor border-y-[1px]">
            <thead className="w-full">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      className="border-borderColor font-semibold text-base border-x-[1px] py-2 px-1"
                      key={header.id}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="text-center">
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td
                      className="border-borderColor text-base font-medium border-[1px] px-1 py-2 m-auto"
                      key={cell.id}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>

          <Dialog
            open={carDealStatus.isActive}
            onClose={() => setCarDealStatus({ isActive: false })}
            className="relative z-50"
          >
            <div className="fixed inset-0 bg-black/70 flex w-screen items-center justify-center p-4">
              <Dialog.Panel className="w-full flex justify-center items-center rounded">
                <div className="min-h-[55vh] w-[700px] rounded-md relative bg-[#F5F5F5] p-4">
                  <div>
                    <div className="p-2">
                      <img
                        src={icons8crossFilled}
                        onClick={() => {
                          setCarDealStatus({ isActive: false });
                        }}
                        className="w-8 h-8 absolute flex justify-center items-center right-2 top-2 rounded-full cursor-pointer"
                      />
                    </div>
                  </div>
                  <div className="mt-0">
                    {/* data goes here */}
                    <div className="">
                      <h1 className="text-2xl p-2">Car booking status</h1>
                    </div>
                    <div className="">
                      {!data ? (
                        <div className="border min-h-[500px] flex items-center justify-center">
                          <AiOutlineLoading className="h-6 w-6 animate-spin m-auto" />
                        </div>
                      ) : (
                        <BookingSteps
                          currentStatus={currentStepInfo}
                          onUpdate={handleUpdate}
                          data={carDealStatus}
                          setData={setCarDealStatus}
                          setIsReject={setIsReject}
                          isReject={isReject}
                          loading={loading2}
                          buttonLoading={buttonLoading}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </div>
          </Dialog>

          <Dialog
            open={isReject}
            onClose={() => setIsReject(!isReject)}
            className="relative z-50"
          >
            <div className="fixed inset-0 bg-black/70 flex w-screen items-center justify-center p-4">
              <Dialog.Panel className="w-full flex justify-center items-center rounded">
                <div className="max-h-[40vh] w-[400px] rounded-md relative bg-[#F5F5F5] p-4">
                  <div>
                    <div className="p-2">
                      <img
                        src={icons8crossFilled}
                        onClick={() => {
                          setIsReject(!isReject);
                        }}
                        className="w-8 h-8 absolute flex justify-center items-center right-2 top-2 rounded-full cursor-pointer"
                      />
                    </div>
                  </div>

                  <div className="mt-0 p-2">
                    {/* data goes here */}
                    <div className="">
                      <h1 className="">Enter your rejection reason</h1>
                    </div>
                    <div>
                      <div className="flex justify-center items-center min-w-full">
                        <div className="w-full">
                          <textarea
                            value={carDealStatus?.cancellationReason}
                            onChange={(e) =>
                              setCarDealStatus({
                                ...carDealStatus,
                                cancellationReason: e.target.value,
                              })
                            }
                            placeholder="Enter rejection reason"
                            className="w-full border border-gray-300 rounded mt-2"
                            rows="4"
                          />
                          <button
                            type="button"
                            onClick={() =>
                              handleUpdate("rejected", carDealStatus)
                            }
                            className="bg-[#990000] hover:bg-[#990000] text-white font-semibold py-2 px-4 w-full ml-auto rounded"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </div>
          </Dialog>
        </div>
      )}

      <div className="flex justify-center mt-5 gap-10">
        <button
          className="px-4 py-2 border-gray-300 rounded-sm flex items-center border-[1px]"
          onClick={() => handlePageChange(currentPageIndex - 1)}
          disabled={!table.getCanPreviousPage()}
        >
          {!table.getCanPreviousPage() ? (
            <img src={disabledLeftIcon} alt="" />
          ) : (
            <img src={leftIcon} alt="" />
          )}
        </button>
        <div className="flex gap-5">
          {Array.from({ length: table.getPageCount() }, (_, index) => (
            <button
              key={index}
              className={`px-4 py-2 border rounded-sm ${
                index === currentPageIndex
                  ? "border-redBackground text-redBackground"
                  : "border-gray-200"
              }`}
              onClick={() => handlePageChange(index)}
            >
              {index + 1}
            </button>
          ))}
        </div>
        <button
          className="px-4 py-2 border-gray-300 rounded-sm flex items-center border-[1px]"
          onClick={() => handlePageChange(currentPageIndex + 1)}
          disabled={!table.getCanNextPage()}
        >
          {!table.getCanNextPage() ? (
            <img src={disabledRightIcon} alt="" />
          ) : (
            <img src={rightIcon} alt="" />
          )}
        </button>
      </div>
    </div>
  );
});

export default UsedCarsRequestTable;
