import { createSlice } from "@reduxjs/toolkit";

const serviceSlice = createSlice({
  name: "services",
  initialState: {
    list: [],
    loading: false,
    serviceSettingInfo: null,
    serviceAdditionInfo: null,
    serviceRequest: false,
    meta: null,
  },
  reducers: {
    setServiceSettingInfo: (state, action) => {
      state.serviceSettingInfo = action.payload;
    },
    setAdditionServiceInfo: (state, action) => {
      // console.log(action.payload);
      state.serviceAdditionInfo = action.payload;
    },
    setServiceRequest: (state, action) => {
      state.serviceRequest = action.payload;
    },
    setServices: (state, action) => {
      state.list = action.payload?.data;
      state.meta = action.payload.meta;
    },
    addService: (state, action) => {
      state.list.push(action.payload);
    },
    deleteService: (state, action) => {
      state.list = state.list.filter(
        (service) => service.id !== action.payload
      );
    },
    updateService: (state, action) => {
      const index = state.list.findIndex(
        (service) => service.id === action.payload.id
      );
      if (index !== -1) {
        state.list[index] = action.payload;
      }
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const {
  setServiceSettingInfo,
  setAdditionServiceInfo,
  setServices,
  updateService,
  setLoading,
  addService,
  deleteService,
  setServiceRequest
} = serviceSlice.actions;

export default serviceSlice.reducer;
