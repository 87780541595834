import React, { useState } from "react";
import PrimaryButton from "../../utils/buttons/primaryButton";
import { HeaderText } from "../../Service/ServiceSetting/ServiceSettingIndex";

function WeeklyOffSetting({ SettingContextData }) {
  const { weekdays, onHandleWOS } = SettingContextData;
  //   console.log(weeklyCheckbox);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className="flex flex-col gap-8 border border-black px-10 py-5  rounded-lg">
          <HeaderText text={"Weekly Off Setting"}/>
      {/* <span className="text-2xl font-bold">Weekly Off Setting</span> */}
      <div className="flex gap-6">
        <div className="flex  gap-4 flex-wrap">
          {/* <span className="text-xl text-center p-3 bg-gray-300 mb-5">Day</span> */}

          {weekdays.map((weekday, index) => (
            <CheckBoxComponent
              key={index}
              weekday={weekday}
              ServiceSettingContextData={SettingContextData}
            />
          ))}
        </div>

      </div>
      <div className="">
        <PrimaryButton
          width="120px"
          className={"m-auto "}
          title={"Update"}
          onClick={async () => {
            setIsLoading(true);
            try {
              await onHandleWOS();
            } catch (err) {
              console.log(err);
            } finally {
              setIsLoading(false);
            }
          }}
          loading={isLoading}
        />
      </div>
    </div>
  );
}
const CheckBoxComponent = ({ weekday, ServiceSettingContextData }) => {
  const { weeklyCheckbox, setWeeklyCheckbox } = ServiceSettingContextData;
  //     console.log(weekday,settingType,weeklyCheckbox)

  //   try {
  //     console.log(weekday,settingType, weeklyCheckbox[weekday][settingType]);
  //   } catch (error) {
  //     console.log(error);
  //   }

  return (
    <div className="relative w-40">
      <div className="flex  flex-row gap-4">
        <input
          type="checkbox"
          checked={weeklyCheckbox[weekday]}
          onChange={(e) => {
            setWeeklyCheckbox((state) => ({
              ...state,
              [weekday]: !state[weekday],
            }));
          }}
          className="cursor-pointer mt-1 block w-6 h-6 text-blue-600 border-gray-300 rounded focus:ring-blue-500 focus:border-blue-500"
        />
        <label className="block text-base font-medium text-gray-700">
          {weekday}
        </label>
      </div>
    </div>
  );
};
export default WeeklyOffSetting;
