import React, { useContext, useEffect, useState } from "react";
import Calendar from "react-calendar";
import "../../../css/Calender.css";
import { HeaderText, ServiceSettingContext } from "../ServiceSettingIndex";
import ServiceListComponent from "./ServiceListComponent";
import PrimaryButton from "../../../utils/buttons/primaryButton";
import axiosInstance, { notifySuccess } from "../../../../utility/axiosInstance";
import { toast } from "react-toastify";
import {
  setAdditionServiceInfo,
  setServiceSettingInfo,
} from "../../../../redux/serviceSlice";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { Datepicker } from "flowbite-react";
import { InputFields } from "../../../InputField/InputFIelds";
import HolidayListComponent from "../../../Common/settingComponet/HolidayListComponent";
import WeeklyOffSetting from "../../../Common/settingComponet/WeeklyOffSetting";
import CalenderViewComponent from "../../../Common/settingComponet/CalenderViewComponent";

const CalenderComponent = () => {
  const {
    calenderView,
    dateValue,
    workingDayTime,
    onDateChange,
    workingTime,
    weekdays,
    weeklyCheckbox,
    available,
    setAvailable,
    availableWorkingDays,
    setAvailableWorkingDays,
    handleMonthDateUpdate,
    isLoading: isMLoading,
    tempTimeSave,
    compareTwoDate,
    holidays,
    currentMonth,
    onCurrentMonth,
    compareMonthAndYear,
  } = useContext(ServiceSettingContext);
  const { list, loading, serviceSettingInfo } = useSelector(
    (state) => state?.services
  );

  const dispatch = useDispatch();

  const [allDate, setAllDate] = useState({
    minDate: new Date(),
    maxDate: new Date(),
  });
  // const [updateSate, setUpdate] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  //  console.log(available);
  useEffect(() => {
    try {
      let today = new Date(currentMonth);

      const currentMonthDate = new Date(currentMonth);
      let count = 30;
      const daysInMonth = new Date(
        currentMonthDate.getFullYear(),
        currentMonthDate.getMonth() + 1,
        0
      ).getDate();

      count = daysInMonth;

      if (weeklyCheckbox) {
        let allDates = [];
        for (let index = 0; index < count; index++) {
          let tempDate = new Date(today);
          tempDate.setDate(tempDate.getDate() + index);
          const weekday = tempDate.toLocaleDateString("en-US", {
            weekday: "long",
          });
          if (tempDate.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) {
            continue;
          }
          // console.log(tempDate);
          if (weeklyCheckbox[weekday]) {
            allDates.push(tempDate);
          }
        }
        let lastDay = new Date(today);
        if (serviceSettingInfo) {
          const { holidays } = serviceSettingInfo;
          if (holidays.length > 0) {
            allDates = allDates.filter((tempDate) => {
              const exist = holidays.find(({ date }) =>
                compareTwoDate(date, tempDate)
              );
              if (exist) {
                return false;
              }
              return true;
            });
          }
        }
        setAvailable(allDates);

        lastDay.setDate(lastDay.getDate() + count - 1);

        if (allDates.length > 0) {
          onDateChange(allDates[0]);
        }
        setAllDate((state) => ({ minDate: today, maxDate: lastDay }));
      }
    } catch (error) {
      console.log(error);
    }
  }, [
    weeklyCheckbox,
    calenderView,
    weekdays,
    onDateChange,
    setAvailableWorkingDays,
    serviceSettingInfo,
    currentMonth,
  ]);

  useEffect(() => {
    if (available.length > 0) {
      onDateChange(available[0]);
    }
  }, [available, onDateChange]);

  const updateHoliday = async (selectedDate, deleteDate = false) => {
    try {
      try {
        setIsLoading(true);
        let holidayBody = [...holidays];

        if (deleteDate) {
          if (selectedDate?.length <= 0) {
            toast.error("Please Select Date");
            return;
          }
          holidayBody = holidayBody.filter(
            ({ date }) =>
              !selectedDate.includes(new Date(date).setHours(0, 0, 0, 0))
          );
          console.log(holidayBody);
          // return;
        } else {
          if (!selectedDate) {
            toast.error("Please Select Date");
            return;
          }
          const exist = holidays.find(({ date }) =>
            compareTwoDate(date, selectedDate)
          );
          if (exist) {
            toast.error("Date already Exist in Holiday List");
            return;
          }
          holidayBody = [...holidays, { date: selectedDate }];
        }

        const temp = availableWorkingDays.find(({ date }) => {
          return compareTwoDate(date, dateValue);
        });

        const response = await axiosInstance.put(
          "servicesSetting/updateServiceSettingHolidays",
          {
            holidays: holidayBody,
          }
        );

        if (response?.status === 200) {
          console.log(response?.data.serviceRequestSettings);
          dispatch(
            setServiceSettingInfo(response?.data.serviceRequestSettings)
          );
          // tempTimeSave([]);
          notifySuccess("Updated suceccesfully");
          // toast.success("Changed the update");
          // setInfo(response.data.parkingLot);
        } else {
          toast.warn("Update the Setting");
        }
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="flex  flex-row  md:flex-col first-line:flex-wrap gap-5 justify-center sm:justify-normal">
      <WeeklyOffSetting
        SettingContextData={useContext(ServiceSettingContext)}
      />
      <CalenderViewComponent
        allDate={allDate}
        handleMonthDateUpdate={async () => {
         
          await handleMonthDateUpdate({
            ServiceMonthSetting: {
              monthYearDate: currentMonth,
              serviceDateSettings: availableWorkingDays,
            },
          });
        }}
        SettingContextData={useContext(ServiceSettingContext)}
        updateHoliday={updateHoliday}
      />
      {/* <div className="flex flex-col flex-wrap gap-5 border border-black px-10 py-5 rounded-lg ">
        <HeaderText text={"Select Month"} />
        <Calendar
          className="custom-calendar custom-calendar_month"
          value={currentMonth}
          view="year"
          maxDetail="year"
          minDetail="year"
          onClickMonth={onCurrentMonth}
          tileClassName={({ date, view }) =>
            view === "year" &&
            date.getMonth() === currentMonth.getMonth() &&
            date.getFullYear() === currentMonth.getFullYear()
              ? "active-month"
              : null
          }
        />

        <div className="flex flex-col  gap-5  my-5">
          <HeaderText text={" Calender Setting"} />
          <div className="calendar-container justify-start w-[30rem]">
            <Calendar
              view="month"
              maxDetail="month"
              minDetail="month"
              showNavigation={false}
              // onClickDay={handleDateChange}
              onChange={(e) => {
                onDateChange(e);
              }}
              tileDisabled={({ activeStartDate, date, view }) => {
                let inactive = false;

                if (!available.find((aDate) => compareTwoDate(aDate, date))) {
                  inactive = true;
                }

                return inactive;
              }}
              tileClassName={({ activeStartDate, date, view }) => {
                let inactive = "";
                if (available.find((aDate) => compareTwoDate(aDate, date))) {
                  inactive = "react-calendar__tile_available";
                }

                if (!available.find((aDate) => compareTwoDate(aDate, date))) {
                  inactive = "react-calendar__tile--disabled";
                }

                return inactive;
              }}
              value={dateValue}
              maxDate={allDate.maxDate}
              minDate={allDate.minDate}
              className="custom-calendar"
            />
          </div>
          <div className="flex w-full gap-5">
            <PrimaryButton
              width="120px"
              className={""}
              title={"Update All"}
              onClick={async () => {
                console.log(currentMonth);
                await handleMonthDateUpdate({
                  ServiceMonthSetting: {
                    monthYearDate: currentMonth,
                    serviceDateSettings: availableWorkingDays,
                  },
                });
              }}
              loading={isMLoading}
            />
            <PrimaryButton
              width="140px"
              title={"Mark As Holiday"}
              onClick={() => updateHoliday(dateValue)}
              loading={isLoading}
            />
          </div>
        </div>
      </div> */}
      {/* HolidayListComponent */}
      <HolidayListComponent
        isLoading={isLoading}
        updateHolidayFunc={updateHoliday}
        holidays={holidays}
      />
    </div>
  );
};

// const TimeSlotComponent = () => {
//   const {
//     workingDayTime,
//     setAvailableWorkingDays,
//     dateValue,
//     availableWorkingDays,
//     setWorkingDayTime,
//     setTempTimeSave,
//     compareTwoDate,
//     services,
//   } = useContext(ServiceSettingContext);
//   // console.log("workingDayTime", workingDayTime);
//   return (
//     <div
//       className="bg-[#e5e7eb] p-[15px] rounded-[12px] border-none shadow-md w-full flex gap-8 flex-wrap overflow-y-auto
//      max-h-[25.2rem]"
//     >
//       {workingDayTime?.map(({ time, status }, i) => (
//         <span
//           key={i}
//           onClick={() => {
//             // setWorkingDayTime((prevState) => {
//             //   const newState = [...prevState];

//             //   newState[i] = {
//             //     ...newState[i],
//             //     status: !newState[i].status,
//             //   };

//             //   return newState;
//             // });

//             const find = availableWorkingDays.find(({ date }) => {
//               return compareTwoDate(date,dateValue);
//             });

//             if (find) {
//               find.timeSlots.map((item) => {
//                 if (item.time === time) {
//                   item.status = !item.status;
//                 }
//                 return item;
//               });

//               setAvailableWorkingDays((state) => {
//                 const newState = state.map((item) => {
//                   if (item.date === find.date) {
//                     return find;
//                   }
//                   return item;
//                 });
//                 return newState;
//               });
//               setWorkingDayTime(() => [...find.timeSlots]);

//               setTempTimeSave((prevState) => {
//                 const existIndex = prevState.findIndex(({ date }) =>
//                   compareTwoDate(date, dateValue)
//                 );
//                 if (existIndex >= 0) {
//                   const updatedState = [...prevState];
//                   updatedState[existIndex] = {
//                     ...updatedState[existIndex],
//                     timeSlots: find.timeSlots,
//                   };
//                   console.log(updatedState);
//                   return updatedState;
//                 }

//                 return [
//                   ...prevState,
//                   { date: dateValue, timeSlots: find.timeSlots, services },
//                 ];
//               });
//             }
//           }}
//           className={`color-[#111827] cursor-pointer text-lg p-[7px] text-center rounded-[8px] `}
//           style={{ backgroundColor: status ? "#6b7280" : "#d1d5db" }}
//         >
//           {time}
//         </span>
//       ))}
//     </div>
//   );
// };

export default CalenderComponent;
