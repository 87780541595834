import React from 'react';

const NoDataFound = ({ message, icon, className }) => {
  return (
    <div
      className={`flex flex-col justify-center items-center h-[100%] p-10 text-center bg-gray-100 rounded-lg ${className}`}
    >
      {icon && (
        <div className="mb-4">
          <img src={icon} alt="No Data Found" className="h-12 w-12" />
        </div>
      )}
      <p className="text-gray-500 text-lg">{message}</p>
    </div>
  );
};

export default NoDataFound;
