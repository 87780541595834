import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "flowbite-react";
import { IoMdClock } from "react-icons/io";

// import { fetchNotifications, clearNotifications } from '../redux/notificationThunks';
import {
  fetchNotifications,
  clearNotifications,
} from "../../redux/notificationsThunk";
import axios from "axios";
import axiosInstance from "../../utility/axiosInstance";
import { LoadingSpinner } from "./LoadingSpinner";

const ImageUser = () => {
  return (
    <div className="h-[40px] w-[40px]  overflow-hidden rounded-full">
      <img
        className="h-full w-full bg-cover cursor-pointer"
        src="https://t3.ftcdn.net/jpg/05/53/79/60/360_F_553796090_XHrE6R9jwmBJUMo9HKl41hyHJ5gqt9oz.jpg"
        alt=""
      />
    </div>
  );
};

const Notification = () => {
  const dispatch = useDispatch();
  const { list, loading } = useSelector((state) => state.notifications);

  const [showPopup, setShowPopup] = useState(false);
  const [selectedNotificationId, setSelectedNotificationId] = useState(null);

  const popupRef = useRef(null);

  const handleClearNotifications = () => {
    dispatch(clearNotifications());
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowPopup(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popupRef]);

  const handleDeleteNotification = async (id) => {
    try {
      await axiosInstance.delete(`notifications/${id}`);
      dispatch(fetchNotifications());
    } catch (error) {
      console.error("Failed to delete notification:", error);
    }
  };

  const handleEllipsisClick = (id) => {
    setSelectedNotificationId(id);
    setShowPopup(!showPopup);
  };

  return (
    <div className="relative">
      {loading && <LoadingSpinner />}

      {list.length > 0 ? (
        <div className="text-end p-2">
          <button onClick={handleClearNotifications}>
            Clear All Notifications
          </button>
        </div>
      ) : (
        <p className="text-center">You don't have any notification</p>
      )}

      <div>
        {list.map((notification) => (
          <span key={notification._id}>
            <div className="flex justify-between items-center px-1 py-1 border-t-[0.5px] border-borderColor sm:px-4">
              <div className="flex justify-around gap-4 items-center">
                <ImageUser />
                <div className="max-w-full">
                  <p className="font-medium text-lg">User</p>
                  <Tooltip content={notification.message}>
                    <p className="text-sm truncate max-w-[200px] sm:max-w-xs cursor-pointer">
                      {notification.message}
                    </p>
                    <p className="flex gap-2 text-sm items-center py-2">
                      <IoMdClock className="text-base" size={18} />
                      {new Date(notification.createdAt).toLocaleString(
                        "en-us",
                        {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                        }
                      )}
                    </p>
                  </Tooltip>
                </div>
              </div>
              <div
                className="font-bold text-xl flex items-center cursor-pointer"
                onClick={() => handleEllipsisClick(notification._id)}
              >
                ...
              </div>
              {showPopup && selectedNotificationId === notification._id && (
                <div
                  ref={popupRef}
                  className="absolute right-0 mt-2 p-2 bg-white border border-gray-200 shadow-lg z-10"
                >
                  <button
                    className="text-red-600"
                    onClick={() => handleDeleteNotification(notification._id)}
                  >
                    Remove this notification
                  </button>
                </div>
              )}
            </div>
          </span>
        ))}
      </div>
    </div>
  );
};

export default Notification;
