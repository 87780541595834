import React, { useEffect, useState } from "react";
import { Dialog } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import axiosInstance, {
  notifyError,
  notifySuccess,
  notifyWarning,
} from "../../utility/axiosInstance";
import { useSelector } from "react-redux";
import OtpVerify from "./OtpVerify";
import PrimaryButton from "../utils/buttons/primaryButton";
import Vector from "../../assets/auth/Vector.png";
import sportCar from "../../assets/auth/sportCar.png";
import "../css/Login.css";
import profile from "../../assets/auth/profile.png";
import logo2 from "../../assets/auth/logo2.png";
import lock from "../../assets/auth/lock.png";
import { icons8cross, newLogo, phoneIcon } from "../../assets";
import ResetPassword from "../pages/resetPassword/ResetPassword";
import OtpVeify from "./OtpVerify";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import resendOtp from "../../utility/resendOtp";
import { auth } from "../../utility/api";
import { flattenBy } from "@tanstack/react-table";
import { PasswordReset } from "./PasswordReset";
import { FaMailBulk, FaPhoneAlt } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { fetchUserDetails } from "../../redux/userThunk";
import { MdEmail } from "react-icons/md";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [forgetPassword, setForgetPassword] = useState({
    active: false,
    email: "",
    password: "",
    loading: false,
    newPassword: "",
  });
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [isOtpVerification, setIsOtpVerification] = useState(false);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [forgetPasswordOtpVerify, setForgetPasswordOtpVerify] = useState(false);

  const [canResend, setCanResend] = useState(true);
  const [timer, setTimer] = useState(0);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [otp, setOtp] = useState(new Array(4).fill(""));
  const [error, setError] = useState({
    login: "",
    otpVerification: "",
    forgetPasswordError: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/home");
    }
  }, [navigate]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    return emailRegex.test(email);
  };

  const login = async () => {
    const { email, password } = formData;

    setError((prevState) => ({
      ...prevState,
      login: "",
    }));

    // if (!email || !password) {
    //   setError((prevState) => ({
    //     ...prevState,
    //     login: "Please enter both email and password.",
    //   }));
    //   return;
    // }

    if (!validateEmail(email)) {
      setError((prevState) => ({
        ...prevState,
        login: "Please enter a valid email address.",
      }));
      return;
    }

    setIsLoading(true);

    try {
      const response = await axiosInstance.post(auth.login, {
        email,
        // password,
      });

      if (response.data.requiresOTPVerification) {
        notifySuccess(response.data.message);
        setIsOtpVerification(true);
      } else {
        toast.error("Login failed. Please try again.");
      }
    } catch (error) {
      console.error("Login error", error);
      setError((prevState) => ({
        ...prevState,
        login: "Login failed. Please try again.",
      }));
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyOTP = async () => {
    try {
      const enteredOtp = otp.join("");
      const response = await axiosInstance.post(auth.verifyLoginOtp, {
        otp: enteredOtp,
      });

      if (response.data.token) {
        localStorage.setItem("token", response.data.token);
        dispatch(fetchUserDetails());
        navigate("/home");
        notifySuccess("Login successful!");
        setIsOtpVerification(false);
        setError({ login: "", forgetPasswordError: "", otpVerification: "" });
      } else {
        setError((prevState) => ({
          ...prevState,
          otpVerification: "OTP verification failed. Please try again.",
        }));
        notifyError("OTP verification failed. Please try again.");
      }
    } catch (error) {
      setError((prevState) => ({
        ...prevState,
        otpVerification: "OTP verification failed. Please try again.",
      }));
    }
  };

  const handleResendOtp = async (email, purpose) => {
    if (!canResend) return;

    const response = await resendOtp(email, purpose);
    if (response.success) {
      setCanResend(false);
      setTimer(30);
      notifySuccess("OTP resent successfully.");
    } else {
      setError((prevState) => ({
        ...prevState,
        otpVerification: response.message,
      }));
      notifyError(response.message);
    }
  };

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setCanResend(true);
    }
    return () => clearInterval(interval);
  }, [timer]);

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setCanResend(true);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const handleForgetPassword = async (email, type) => {
    if (!validateEmail(email)) {
      notifyError("Please enter a valid email address.");
      return;
    }

    setError((prevState) => ({
      ...prevState,
      otpVerification: "",
    }));

    try {
      if (type === "setNewPassword") {
        const response = await axiosInstance.post(auth.resetPasssword, {
          newPassword: forgetPassword.newPassword,
        });

        if (response) {
          toast.success(response.data.message);
          setIsResetPassword(false);
          setIsOtpVerification(false);
          setForgetPassword((prevState) => ({
            ...prevState,
            active: false,
          }));
          setForgetPasswordOtpVerify(false);
          setFormData({ email: "", password: "" });
        } else {
          setError((prevState) => ({
            ...prevState,
            forgetPasswordError: "Password reset failed. Please try again.",
          }));
          notifyError("Password reset failed. Please try again.");
        }
      } else {
        setForgetPassword((prevState) => ({
          ...prevState,
          loading: true,
        }));

        const response = await axiosInstance.post(auth.forgetPassword, {
          email,
        });

        if (response.data.requiresOTPVerification) {
          notifySuccess(response.data.message);
          setIsResetPassword(true);
          setForgetPassword((prevState) => ({
            ...prevState,
            active: false,
            loading: false,
          }));
          setFormData({ email: "", password: "" });
        } else {
          setForgetPassword((prevState) => ({
            ...prevState,
            loading: false,
          }));
          setError((prevState) => ({
            ...prevState,
            otpVerification: "Failed to send Passkey. Please try again.",
          }));
          notifyError("Failed to send Passkey. Please try again.");
        }
      }
    } catch (error) {
      console.error("Forget password error", error);
      notifyError("An error occurred. Please try again.");
    }
  };

  const handleVerifyForgetPasswordOtp = async () => {
    setFormData({ password: "" });
    setError((prevState) => ({
      ...prevState,
      otpVerification: "",
    }));

    if (otp.join("").length < 4) {
      return notifyWarning("Please enter the correct Passkey.");
    }

    try {
      const enteredOtp = otp.join("");
      const response = await axiosInstance.post(auth.verifyResetPasswordOtp, {
        otp: enteredOtp,
      });

      if (response) {
        setIsResetPassword(false);
        setForgetPasswordOtpVerify(true);
        setOtp(new Array(4).fill(""));
        notifySuccess(response.data.message);
      }
    } catch (error) {
      setError((prevState) => ({
        ...prevState,
        otpVerification: "Passkey verification failed. Please try again.",
      }));
      console.error("OTP verification error", error);
    }
  };

  return (
    <div>
      <div className="login-page">
        <img className="sportsCar" src={sportCar} alt="sportsCar" />
        <img className="banner_image" src={Vector} alt="Banner" />
        <div className="login-form">
          <div className="div_logo">
            <img
              className=" m-auto"
              src={newLogo}
              alt="Logo2"
              style={{ height: "200px" }}
            />
          </div>
          <div className="">
            <div className="max-w-sm space-y-4 m-auto">
              <div className="relative">
                <input
                  type="email"
                  className="peer placeholder:text-lg py-4 px-5 ps-14 block w-full bg-gray-100 border-transparent rounded-lg text-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-700 dark:border-transparent dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                  placeholder="Enter your email"
                  style={{ fontSize: "18px" }}
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
                <div className="absolute inset-y-0 start-0 flex items-center pointer-events-none ps-5 peer-disabled:opacity-50 peer-disabled:pointer-events-none">
                  <svg
                    className="flex-shrink-0 size-6 text-gray-500 dark:text-neutral-500"
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                  </svg>
                </div>
              </div>

              {/* <div className="relative">
                <input
                  className="peer placeholder:text-lg py-4 px-5 ps-14 block w-full bg-gray-100 border-transparent rounded-lg text-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-700 dark:border-transparent dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                  placeholder="Enter your password"
                  style={{ fontSize: "18px" }}
                  type={"password"}
                  id="password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  required
                />
                <div className="absolute inset-y-0 start-0 flex items-center pointer-events-none ps-5 peer-disabled:opacity-50 peer-disabled:pointer-events-none">
                  <svg
                    className="flex-shrink-0 size-6 text-gray-500 dark:text-neutral-500"
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M2 18v3c0 .6.4 1 1 1h4v-3h3v-3h2l1.4-1.4a6.5 6.5 0 1 0-4-4Z"></path>
                    <circle cx="16.5" cy="7.5" r=".5"></circle>
                  </svg>
                </div>
              </div> */}
            </div>

            {/* <div className="forgotPassword m-auto w-[335px] mt-1">
              <p
                className="cursor-pointer text-end"
                onClick={() =>
                  setForgetPassword((prevState) => ({
                    ...prevState,
                    active: true,
                  }))
                }
              >
                Forgot Password?
              </p>
            </div> */}

            <div>
              {error && (
                <p className="error-message w-[80%] m-auto text-[#F2030B] px-1 py-1">
                  {error.login}
                </p>
              )}
            </div>
          </div>

          <div className="w-[350px] m-auto p-4">
            <PrimaryButton
              title="Login"
              width={"100%"}
              height={"60px"}
              className="text-xl"
              onClick={login}
              loading={isLoading}
            />
          </div>
        </div>
      </div>
      <Dialog
        open={forgetPassword?.active}
        onClose={() =>
          setForgetPassword((prevState) => ({
            ...prevState,
            active: false,
          }))
        }
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/60 flex w-screen items-center justify-center p-4">
          <Dialog.Panel className="w-1/3 max-w-[700px] md:max-w-[600px] lg:max-w-[800px] xl:max-w-[900px] flex justify-center items-center rounded">
            <div className="w-full rounded-xl relative bg-white">
              <div className="p-4">
                <img
                  src={icons8cross}
                  onClick={() => {
                    setForgetPassword((prevState) => ({
                      ...prevState,
                      active: false,
                    }));
                  }}
                  className="w-8 h-8 absolute flex justify-center items-center right-2 top-2 rounded-full cursor-pointer"
                />

                <div className="text-lg pt-2 px-3">
                  <p className="text-2xl font-medium text-center py-5">
                    Forgot Password?
                  </p>
                  <p className="font-normal text-xl text-center py-5">
                    In publishing and graphic design, Lorem ipsum dolor sit amet
                    consectetur adipisicing elit. Illum, accusantium!
                  </p>
                  <div className="mb-8">
                    <div className="flex justify-center">
                      <div className="w-full max-w-md">
                        <label
                          htmlFor="phone"
                          className="block text-lg font-medium text-primaryDark py-1 ml-auto"
                        >
                          Email
                        </label>
                        <div className="flex items-center border border-black rounded-lg overflow-hidden relative">
                          {/* <span className="absolute left-0 p-4">
                            <img src={phoneIcon} alt="" className="h-8 w-8" />
                          </span> */}
                          <div className="w-full">
                            <div className="relative">
                              <input
                                className="peer placeholder:text-lg py-4 px-5 ps-14 block w-full border-transparent rounded-lg text-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-700 dark:border-transparent dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                                type="tel"
                                onChange={(e) =>
                                  setForgetPassword((prevState) => ({
                                    ...prevState,
                                    email: e.target.value,
                                  }))
                                }
                                id="phone"
                                value={forgetPassword.email}
                                name="phone"
                                placeholder="Enter your email address"
                              />
                              <div className="absolute inset-y-0 start-0 flex items-center pointer-events-none ps-5 peer-disabled:opacity-50 peer-disabled:pointer-events-none">
                                <MdEmail className="w-6 h-6" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-center pb-5">
                    <PrimaryButton
                      width={"214px"}
                      height={"52px"}
                      title={"Reset"}
                      loading={forgetPassword.loading}
                      onClick={() => {
                        handleForgetPassword(forgetPassword.email);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>

      <Dialog
        open={isOtpVerification}
        onClose={() => setIsOtpVerification(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/60 flex w-screen items-center justify-center p-4">
          <Dialog.Panel className="h-[90vh] max-w-[850px] flex justify-center items-center rounded overflow-auto no-scrollbar">
            <div className="w-full rounded-xl relative bg-white">
              <div className="p-4">
                <img
                  src={icons8cross}
                  onClick={() => {
                    setIsOtpVerification(false);
                  }}
                  className="w-8 h-8 absolute flex justify-center items-center right-2 top-2 rounded-full cursor-pointer"
                />

                {isOtpVerification && (
                  <OtpVeify
                    otp={otp}
                    setOtp={setOtp}
                    onVerifyOtp={handleVerifyOTP}
                    onResendOtp={() => handleResendOtp(formData.email, "login")}
                    timer={timer}
                    isResend={canResend}
                    errorMessage={error.otpVerification}
                  />
                )}
              </div>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>

      <Dialog
        open={isResetPassword}
        onClose={() => setIsResetPassword(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/60 flex w-screen items-center justify-center p-4">
          <Dialog.Panel className="h-[90vh] max-w-[850px] flex justify-center items-center rounded overflow-auto no-scrollbar">
            <div className="w-full rounded-xl relative bg-white">
              <div className="p-4">
                <img
                  src={icons8cross}
                  onClick={() => {
                    setIsResetPassword(false);
                  }}
                  className="w-8 h-8 absolute flex justify-center items-center right-2 top-2 rounded-full cursor-pointer"
                />
                {isResetPassword && (
                  <OtpVeify
                    otp={otp}
                    setOtp={setOtp}
                    onVerifyOtp={handleVerifyForgetPasswordOtp}
                    onResendOtp={() =>
                      handleResendOtp(forgetPassword?.email, "resetPassword")
                    }
                    timer={timer}
                    isResend={canResend}
                    errorMessage={error.otpVerification}
                  />
                )}
              </div>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>

      <Dialog
        open={forgetPasswordOtpVerify}
        onClose={() => setIsResetPassword(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/60 flex w-screen items-center justify-center p-4">
          <Dialog.Panel className="h-[90vh] max-w-[850px] flex justify-center items-center rounded overflow-auto no-scrollbar">
            <div className="w-full rounded-xl relative bg-white">
              <div className="p-4">
                <img
                  src={icons8cross}
                  onClick={() => {
                    setForgetPasswordOtpVerify(false);
                  }}
                  className="w-8 h-8 absolute flex justify-center items-center right-2 top-2 rounded-full cursor-pointer"
                />
                {forgetPasswordOtpVerify && (
                  <PasswordReset
                    newPasswordProp={forgetPassword?.password}
                    setForgetPassword={setForgetPassword}
                    onSubmit={() => {
                      handleForgetPassword(
                        forgetPassword.email,
                        "setNewPassword"
                      );
                    }}
                    newPasswordValue={forgetPassword?.newPassword}
                  />
                )}
              </div>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </div>
  );
};

export default Login;
