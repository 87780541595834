import React, { useState } from "react";
import PrimaryButton from "../../utils/buttons/primaryButton";
import { HeaderText } from "../../Service/ServiceSetting/ServiceSettingIndex";

const HolidayListComponent = ({ holidays, isLoading, updateHolidayFunc }) => {
  // const { list, loading, serviceSettingInfo } = useSelector(
  //   (state) => state?.services
  // );

  const [selectedDate, setSelectedDate] = useState("");
  const [checkBoxDate, setCheckBoxDate] = useState("");
  // const { holidays } = serviceSettingInfo;

  return (
    <div className="relative flex flex-col gap-4 border border-black px-10 py-5 rounded-lg ">
      <HeaderText text={"Holiday Management"} />
      <span className="text-2xl text-center font-bold "></span>

      <span className="text-l text-left font-bold ">Select Date:</span>
      <input
        type="date"
        className="border border-gray-300"
        value={selectedDate}
        onChange={(e) => {
          const dateValue = e.target.value;
          setSelectedDate(dateValue);
          console.log(new Date(dateValue));
        }}
      />

      <div className="rounded-lg overflow-y-auto flex flex-col gap-2 p-4 min-h-60 max-h-60 border border-1">
      <span className="text-l text-left font-bold mb-3">HOLIDAYS:</span>
        {holidays?.map(({ date }, key) => (
          <div className="flex gap-2 ">
            <input
              type="checkbox"
              value={new Date(date).setHours(0, 0, 0, 0)}
              checked={checkBoxDate.includes(
                new Date(date).setHours(0, 0, 0, 0)
              )}
              onChange={(e) => {
                const dateNumber = new Date(date).setHours(0, 0, 0, 0);
                setCheckBoxDate((prevState) => {
                  if (prevState.includes(dateNumber)) {
                    return prevState.filter((item) => item !== dateNumber);
                  } else {
                    return [...prevState, dateNumber];
                  }
                });
              }}
              className="cursor-pointer mt-1 block w-6 h-6 text-blue-600 border-gray-300 rounded focus:ring-blue-500 focus:border-blue-500"
            />

            <span className="text-lg">
              {new Date(date).toLocaleDateString()} (
              {new Date(date).toLocaleDateString("en-US", {
                weekday: "long",
              })}
              )
            </span>
          </div>
        ))}
      </div>
      <div className="flex gap-5">
        <PrimaryButton
          width="140px"
          title={"Update Holiday"}
          onClick={() => updateHolidayFunc(selectedDate)}
          loading={isLoading}
        />
        <PrimaryButton
          width="140px"
          title={"Remove"}
          onClick={() => updateHolidayFunc(checkBoxDate, true)}
          loading={isLoading}
        />
      </div>
    </div>
  );
};
export default HolidayListComponent;
