import React, { useContext, useEffect, useState } from "react";
import { HeaderText, ServiceSettingContext } from "../ServiceSettingIndex";
import PrimaryButton from "../../../utils/buttons/primaryButton";
import { useSelector } from "react-redux";
import axiosInstance from "../../../../utility/axiosInstance";
import { toast } from "react-toastify";
import TimeSlotComponent from "../../../Common/settingComponet/TimeSlotComponent";
import WorkTimeSettingComponent from "../../../Common/settingComponet/WorkTimeSettingComponent";

const WeekSettingComponent = () => {
  const {
    setWorkingTime,
    workingTime,
    handleMonthDateUpdate,
    compareTwoDate,
    dateValue,
    setWorkingDayTime,
    compareMonthAndYear,
    tempTimeSave,
    currentMonth,
    workingDayTime,
    handleSingleUpdate,
  } = useContext(ServiceSettingContext);
  const { list, loading, serviceSettingInfo } = useSelector(
    (state) => state?.services
  );
  useEffect(() => {
    try {
      if (Object.keys(workingTime).length !== 0) {
        let currentTime = workingTime.start;
        let endTime = workingTime.end;
        let startDate = new Date();

        let endDate = new Date();
        let [startHour, startMinute] = currentTime.split(":");
        startDate.setHours(startHour, startMinute, 0, 0);

        let [endStartHour, endStartMinute] = endTime.split(":");
        endDate.setHours(endStartHour, endStartMinute - 15, 0, 0);
        let min = 0;
        let end = 15;
        const allTime = [];
        let dateData = null;

        if (serviceSettingInfo) {
          const { serviceMonthSetting } = serviceSettingInfo;

          const tempMonth = serviceMonthSetting?.find(({ monthYearDate }) => {
            return compareMonthAndYear(monthYearDate, currentMonth);
          });

          if (tempMonth) {
            dateData = tempMonth?.serviceDateSettings?.find(({ date }) => {
              return compareTwoDate(date, dateValue);
            });
          }
        }

        if (tempTimeSave.length > 0) {
          const exist = tempTimeSave?.find(({ date }) => {
            return compareTwoDate(date, dateValue);
          });

          if (exist) {
            dateData = exist;
          }
        }
        console.log(dateData);
        while (startDate < endDate) {
          startDate.setHours(startHour, startMinute + min, 0, 0);

          const tempTime = startDate.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          });

          let status = false;
          if (dateData) {
            const exist = dateData.timeSlots.find(
              ({ time }) => time === tempTime
            );
            status = exist ? exist.status : false;
          }
          allTime.push({
            time: tempTime,
            status: status,
          });

          min += 15;
          end += 15;
        }
        console.log(allTime);
        setWorkingDayTime(allTime);
      }
    } catch (error) {
      console.log(error);
    }
  }, [setWorkingDayTime, workingTime, dateValue, serviceSettingInfo]);

  const handleStartChange = (event, type) => {
    if (type == "h") {
      const hr = event.target.value;
      console.log(hr);
      setWorkingTime((state) => ({
        ...state,
        start: `${hr}:${state.start.split(":")[1]}`,
      }));
    } else {
      const min = event.target.value;
      setWorkingTime((state) => ({
        ...state,
        start: `${state.start.split(":")[0]}:${min.replace(":", "")}`,
      }));
    }
  };


  // const [isLoading, setIsLoading] = useState(false);
  // const handleEDAToggle = async () => {
  //   try {
  //     // console.log(weeklyCheckbox);
  //     setIsLoading(true);
  //     const response = await axiosInstance.put(
  //       "servicesSetting/updateServiceSettingInfo",
  //       {
  //         weeklySetting: weeklyCheckbox,
  //         workingTime,
  //         calenderView,
  //       }
  //     );

  //     if (response?.status === 200) {
  //       toast.success("Changed the Status");
  //       // setInfo(response.data.parkingLot);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };
  return (
    <div className="flex flex-col  gap-4 justify-center ">
      <WorkTimeSettingComponent
        handleMonthDateUpdate={async () => {
          await handleMonthDateUpdate({
            monthYearDate: new Date(currentMonth),
            workingTime,
          });
        }}
        SettingContextData={useContext(ServiceSettingContext)}
      />
      <TimeSlotComponent
        SettingContextData={useContext(ServiceSettingContext)}
        handleSingleUpdate={async () =>
          await handleSingleUpdate({
            monthYearDate: new Date(currentMonth),
            workingDayTime,
            currentDate: dateValue,
          })
        }
        handleMonthDateUpdate={async () =>
          await handleMonthDateUpdate({
            monthYearDate: new Date(currentMonth),
            workingDayTime,
          })
        }
      />
    </div>
  );
};

// const CheckBoxSetCalenderComponent = ({
//   weekday,
//   handleEDAToggle,
//   isLoading,
// }) => {
//   const { calenderView, setCalenderView } = useContext(ServiceSettingContext);

//   return (
//     <div className="relative flex flex-col gap-4 border border-black px-10 py-5 rounded-lg ">
//       <label className="block text-2xl font-bold  text-gray-700 ">
//         Calender Setting
//       </label>
//       <div className="flex flex-row justify-evenly gap-3 items-center ">
//         <div className="flex  flex-row gap-3 ">
//           <label className="block text-lg font-medium text-gray-700">
//             Week
//           </label>
//           <input
//             type="radio"
//             name="week"
//             checked={calenderView === "week"}
//             onChange={(e) => {
//               setCalenderView("week");
//             }}
//             className="cursor-pointer mt-1 block w-6 h-6 text-blue-600 border-gray-300 rounded focus:ring-blue-500 focus:border-blue-500"
//           />
//         </div>
//         <div className="flex  flex-row gap-3">
//           <label className="block text-base font-medium text-gray-700">
//             Fortnight
//           </label>
//           <input
//             type="radio"
//             name="fortnight"
//             checked={calenderView === "fortnight"}
//             onChange={(e) => {
//               setCalenderView("fortnight");
//             }}
//             className="cursor-pointer mt-1 block w-6 h-6 text-blue-600 border-gray-300 rounded focus:ring-blue-500 focus:border-blue-500"
//           />
//         </div>
//         <div className="flex  flex-row gap-3">
//           <label className="block text-base font-medium text-gray-700">
//             Month
//           </label>
//           <input
//             type="radio"
//             name="month"
//             checked={calenderView === "month"}
//             onChange={(e) => {
//               setCalenderView("month");
//             }}
//             className="cursor-pointer mt-1 block w-6 h-6 text-blue-600 border-gray-300 rounded focus:ring-blue-500 focus:border-blue-500"
//           />
//         </div>
//         {/* <label className="block text-base font-medium text-gray-700">All</label>
//         <input
//           type="radio"
//           name="all"
//           checked={calenderView === "all"}
//           onChange={(e) => {
//             setCalenderView("all");
//           }}
//           className="cursor-pointer mt-1 block w-6 h-6 text-blue-600 border-gray-300 rounded focus:ring-blue-500 focus:border-blue-500"
//         /> */}
//       </div>
//       <PrimaryButton
//         width="120px"
//         className={"m-auto "}
//         title={"Update"}
//         onClick={handleEDAToggle}
//         loading={isLoading}
//       />
//     </div>
//   );
// };

const TimeSelect = ({
  selectedHour,
  selectedMinute,
  handleMinuteChange,
  handleHourChange,
  workingTime,
  title,
}) => {
  let hours = Array.from({ length: 24 }, (_, index) => index);
  if (title === "End Time") {
    hours = Array.from({ length: 24 }, (_, index) => index).splice(
      Number(workingTime?.start.split(":")[0]) + 1,
      24
    );
  } else {
    hours = Array.from({ length: 24 }, (_, index) => index).splice(0, 23);
  }
  const minutes = [0, 30];

  // console.log(selectedHour)
  return (
    <div className="flex gap-4 ">
      <label className="text-lg font-bold min-w-24 pt-2">{title}:</label>
      <div className=" flex gap-3 items-center ">
        <label className="mr-2 font-lg text-gray-400">Hour:</label>
        <select
          value={selectedHour}
          onChange={handleHourChange}
          className="w-36 lg:w-72 h-10"
        >
          {hours.map((hour) => (
            <option key={hour} value={hour}>
              {hour.toString().padStart(2, "0")}
            </option>
          ))}
        </select>

        <label className="mx-2 font-lg text-gray-400">Minute:</label>
        <select
          value={selectedMinute}
          onChange={handleMinuteChange}
          className=" w-36 lg:w-72 h-10"
        >
          {minutes.map((minute) => (
            <option key={minute} value={minute}>
              {minute.toString().padStart(2, "0")}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
export default WeekSettingComponent;
