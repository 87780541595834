import React, { useState, useEffect, useRef } from "react";
import { IoSendSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { fetchCustomers } from "../../redux/customersThunk";
import axiosInstance, { notifyError } from "../../utility/axiosInstance";
import { login } from "../../redux/authSlice";
import { customers } from "../../utility/api";
import useSocket from "../../hooks/useSocket";

export const Chat = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [currentUser, setCurrentUser] = useState(null);
  const [conversations, setConversations] = useState([]);
  const [selectedConversations, setSelectedConversations] = useState(null);
  const [showUserList, setShowUserList] = useState(false);
  const dispatch = useDispatch();

  const messagesEndRef = useRef(null);

  const { list: customers, loading } = useSelector((state) => state.customers);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const response = await axiosInstance.get("admin/getUserMe");
        setCurrentUser(response?.data);
      } catch (error) {
        notifyError(`Failed to fetch notifications:, ${error}`);
      }
    };

    fetchCurrentUser();
  }, []);

  useEffect(() => {
    fetchConversations().then((res) => setConversations(res));
  }, []);

  useEffect(() => {
    const modified = conversations
      .map((conversation) => {
        const filteredParticipants = conversation.participants
          ?.filter((participant) => participant._id !== currentUser?._id)
          .map((participant) => ({
            id: participant._id,
            name: `${participant.firstName} ${participant.lastName}`,
            avatar:
              "https://reputationprotectiononline.com/wp-content/uploads/2022/04/78-786207_user-avatar-png-user-avatar-icon-png-transparent.png",
            conversationId: conversation?._id,
          }));

        return filteredParticipants;
      })
      ?.flat();
    setUsers(modified);
  }, [conversations]);

  useEffect(() => {
    dispatch(fetchCustomers());
    dispatch(login());
  }, [dispatch]);

  useEffect(() => {
    if (selectedUser) {
      fetchMessages(selectedUser?.conversationId);
    }
  }, [selectedUser]);

  const handleSendMessage = async () => {
    if (newMessage.trim() && selectedUser) {
      const messageData = {
        content: newMessage,
        conversation: selectedUser.conversationId,
      };

      try {
        const response = await axiosInstance.post(
          `/message/send/${selectedUser.id}`,
          messageData
        );
        const newMessageFromServer = response.data?.data;
        setMessages([...messages, newMessageFromServer]);
        setNewMessage("");
      } catch (error) {
        console.error("Error sending message:", error);
        notifyError("Failed to send message.");
      }
    }
  };

  const handleCreateChat = async (user) => {
    try {
      const response = await axiosInstance.post(`/chat`, {
        participants: [currentUser._id, user.id],
      });

      if (response.data) {
        setSelectedUser(user);
        setShowUserList(false);
      }
    } catch (error) {
      console.error("Error creating chat:", error);
      notifyError("Failed to create chat.");
    }
  };

  const fetchMessages = async (conversationsId) => {
    try {
      const response = await axiosInstance.get(
        `chat/${conversationsId}/messages`
      );
      const chat = response?.data;

      setMessages(chat);
    } catch (error) {
      console.error("Error fetching chat history:", error);
      notifyError("Failed to load chat history.");
    }
  };

  const fetchConversations = async () => {
    try {
      const response = await axiosInstance.get(`chat`);
      return response?.data;
    } catch (error) {
      console.error("Error fetching chat history:", error);
      notifyError("Failed to load chat history.");
    }
  };

  const filteredMessages = selectedUser
    ? messages?.filter(
        (message) =>
          message?.sender == selectedUser?.id ||
          message?.sender == currentUser?._id ||
          message?.receiver == selectedUser?.id ||
          message?.receiver == currentUser?._id
      )
    : [];

  useSocket((newMessage) => {
    setMessages((prevMessages) => [...prevMessages, newMessage]);
  });

  const scrollToBottom = () => {
    messagesEndRef?.current?.scrollTo(0, messagesEndRef.current.scrollHeight);
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div className="flex h-[calc(100vh-120px)] rounded-lg">
      <div className="w-1/3 bg-white border-r border-zinc-200 rounded-xl">
        <div className="flex items-center justify-between p-4 border-b border-zinc-200">
          <button className="text-zinc-500">
            <img
              alt="menu-icon"
              src="https://openui.fly.dev/openui/24x24.svg?text=☰"
            />
          </button>
          <span>Today</span>

          <button
            className="text-zinc-500"
            onClick={() => setShowUserList(!showUserList)}
          >
            <img
              alt="more-options-icon"
              src="https://openui.fly.dev/openui/24x24.svg?text=⋮"
            />
          </button>
        </div>
        <div className="overflow-y-auto h-[calc(100vh-120px)] no-scrollbar">
          {users.map((user) => (
            <div
              key={user.id}
              className="p-4 flex items-center border-b border-zinc-200 cursor-pointer"
              onClick={() => setSelectedUser(user)}
            >
              <img
                className="w-10 h-10 rounded-full"
                src={user.avatar}
                alt="User Avatar"
              />
              <div className="ml-4">
                <div className="font-bold">{user.name}</div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="flex-1 flex flex-col">
        {selectedUser && (
          <>
            <div className="flex items-center justify-between p-4 border-b border-zinc-200">
              <div className="flex items-center">
                <img
                  className="w-10 h-10 rounded-full"
                  src={selectedUser.avatar}
                  alt="User Avatar"
                />
                <div className="ml-4">
                  <div className="font-bold">{selectedUser.name}</div>
                </div>
              </div>
              <button className="text-zinc-500">
                <img
                  alt="more-options-icon"
                  src="https://openui.fly.dev/openui/24x24.svg?text=⋮"
                />
              </button>
            </div>
            <div
              className="flex-1 overflow-y-auto p-4 bg-zinc-50"
              ref={messagesEndRef}
            >
              {filteredMessages?.map((message) => (
                <div
                  key={message.id}
                  className={`flex items-start mb-4 ${
                    message.sender === currentUser._id ? "justify-end" : ""
                  }`}
                >
                  {message.sender !== currentUser._id && (
                    <img
                      className="w-8 h-8 rounded-full mt-1"
                      src="https://reputationprotectiononline.com/wp-content/uploads/2022/04/78-786207_user-avatar-png-user-avatar-icon-png-transparent.png"
                      alt="User Avatar"
                    />
                  )}
                  <div
                    className={`ml-4 p-2 rounded-lg shadow-md ${
                      message.user === "me"
                        ? "bg-white text-zinc-800"
                        : "bg-red-600 text-white"
                    }`}
                  >
                    {message.content}
                  </div>

                  {message.sender === currentUser._id && (
                    <img
                      className="w-8 h-8 rounded-full mt-1 ml-1"
                      src="https://reputationprotectiononline.com/wp-content/uploads/2022/04/78-786207_user-avatar-png-user-avatar-icon-png-transparent.png"
                      alt="User Avatar"
                    />
                  )}
                </div>
              ))}

              {/* <div ref={dummy} /> */}
            </div>
            <div className="p-4 border-t border-zinc-200 flex items-center">
              <input
                type="text"
                placeholder="Write Message"
                className="flex-1 p-2 border border-zinc-300 rounded-lg"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
              />
              <button
                className="ml-4 text-blue-500"
                onClick={handleSendMessage}
              >
                <img
                  alt="send-icon"
                  src="https://openui.fly.dev/openui/24x24.svg?text=➤"
                />
              </button>
            </div>
          </>
        )}
      </div>

      {showUserList && (
        <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-4 rounded-lg shadow-lg min-w-[400px]">
            <h2 className="text-xl mb-4">Select User to Chat</h2>
            <div className="overflow-y-auto h-64">
              {users.map((user) => (
                <div
                  key={user.id}
                  className="p-2 flex items-center border-b border-zinc-200 cursor-pointer"
                  onClick={() => handleCreateChat(user)}
                >
                  <img
                    className="w-8 h-8 rounded-full"
                    src={user.avatar}
                    alt="User Avatar"
                  />
                  <div className="ml-4">
                    <div className="font-bold">{user.name}</div>
                  </div>
                </div>
              ))}
            </div>
            <div className="text-center">
              <button
                className="mt-4 px-4 py-2 bg-red-500 text-white rounded-lg"
                onClick={() => setShowUserList(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
