import React, { useState } from "react";
import PrimaryButton from "../../utils/buttons/primaryButton";
import { HeaderText } from "../../Service/ServiceSetting/ServiceSettingIndex";

export default function WorkTimeSettingComponent({ handleMonthDateUpdate,SettingContextData }) {
  const { setWorkingTime,workingTime } = SettingContextData;
  const handleStartChange = (event, type) => {
    if (type == "h") {
      const hr = event.target.value;
      console.log(hr);
      setWorkingTime((state) => ({
        ...state,
        start: `${hr}:${state.start.split(":")[1]}`,
      }));
    } else {
      const min = event.target.value;
      setWorkingTime((state) => ({
        ...state,
        start: `${state.start.split(":")[0]}:${min.replace(":", "")}`,
      }));
    }
  };
  const handleEndChange = (event, type) => {
    if (type == "h") {
      const hr = event.target.value;
      setWorkingTime((state) => ({
        ...state,
        end: `${hr}:${state.end.split(":")[1]}`,
      }));
    } else {
      const min = event.target.value;
      setWorkingTime((state) => ({
        ...state,
        end: `${state.end.split(":")[0]}:${min.replace(":", "")}`,
      }));
    }
  };
  const [isLoading, setIsLoading] = useState(false);
  return<div className="flex flex-col gap-4 justify-center border border-black px-10 py-5  rounded-lg">
  <HeaderText text={"Work Time Setting"}/>
    {/* <span className="text-2xl font-bold text-center">Day Time Setting</span> */}
    {/* <h2 className="mb-1">Select Time</h2> */}
    <div className="mb-10 flex flex-col gap-12 ">
      <TimeSelect
        workingTime={workingTime}
        handleHourChange={(e) => handleStartChange(e, "h")}
        handleMinuteChange={(e) => handleStartChange(e, "min")}
        title={"Start Time"}
        selectedMinute={workingTime?.start.split(":")[1]}
        selectedHour={workingTime?.start.split(":")[0]}
      />
      <TimeSelect
        workingTime={workingTime}
        title={"End Time"}
        handleHourChange={(e) => handleEndChange(e, "h")}
        handleMinuteChange={(e) => handleEndChange(e, "min")}
        selectedMinute={workingTime?.end.split(":")[1]}
        selectedHour={workingTime?.end.split(":")[0]}
      />
    </div>
    <div className="flex justify-start py-2">
      <PrimaryButton
        width="120px"
        className={" "}
        title={"Update "}
        onClick={async () => {
              try {
                setIsLoading(true);
                await handleMonthDateUpdate();
              } catch (err) {
              } finally {
                setIsLoading(false);
              }
            }}
        loading={isLoading}
      />
    </div>
  </div>;
}
const TimeSelect = ({
    selectedHour,
    selectedMinute,
    handleMinuteChange,
    handleHourChange,
    workingTime,
    title,
  }) => {
    let hours = Array.from({ length: 24 }, (_, index) => index);
    if (title === "End Time") {
      hours = Array.from({ length: 24 }, (_, index) => index).splice(
        Number(workingTime?.start.split(":")[0]) + 1,
        24
      );
    } else {
      hours = Array.from({ length: 24 }, (_, index) => index).splice(0, 23);
    }
    const minutes = [0, 30];
  
    // console.log(selectedHour)
    return (
      <div className="flex gap-4 ">
        <label className="text-lg font-bold min-w-24 pt-2">{title}:</label>
        <div className=" flex gap-3 items-center ">
          <label className="mr-2 font-lg text-gray-400">Hour:</label>
          <select
            value={selectedHour}
            onChange={handleHourChange}
            className="w-36 lg:w-72 h-10"
          >
            {hours.map((hour) => (
              <option key={hour} value={hour}>
                {hour.toString().padStart(2, "0")}
              </option>
            ))}
          </select>
  
          <label className="mx-2 font-lg text-gray-400">Minute:</label>
          <select
            value={selectedMinute}
            onChange={handleMinuteChange}
            className=" w-36 lg:w-72 h-10"
          >
            {minutes.map((minute) => (
              <option key={minute} value={minute}>
                {minute.toString().padStart(2, "0")}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  };