import React, { useEffect, useState } from "react";
import PrimaryButton from "../utils/buttons/primaryButton";
// import PrimaryButton from "../../utils/buttons/primaryButton";
import { Dialog, Switch } from "@headlessui/react";
import ServiceTable from "./ServiceTable";
import { priceIcon, serviceIcon, icons8crossFilled } from "../../assets";
import AcceptButton from "../staticButtons/acceptButton";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "flowbite-react";

import {
  fetchServices,
  createService,
  modifyService,
  deleteServices,
  fetchServicesSettingInfo,
  fetchAdditionServicesInfo,
} from "../../redux/serviceThunk";
import {
  IoFlowerSharp,
  IoSettings,
  IoSettingsOutline,
  IoTime,
} from "react-icons/io5";
import { toast } from "react-toastify";
import { setServiceOptionSelected } from "../../redux/searchSlice";
import ParkingLotModal from "./component/ParkingLotModal";
import axiosInstance, {
  notifyError,
  notifySuccess,
} from "../../utility/axiosInstance";
import ServiceSettingIndex from "./ServiceSetting/ServiceSettingIndex";
import { FaArrowLeft } from "react-icons/fa";
import ToggleButton, { ToggleAButton } from "../utils/buttons/toggleButton";
import Pagination from "../Common/Pagination";

export const Services = () => {
  const [timePeriod, setTimePeriod] = useState(15);
  const [addServices, setAddServices] = useState(false);
  const [serviceName, setServiceName] = useState("");
  const [servicePrice, setServicePrice] = useState("");
  const [estimatedTime, setEstimatedTime] = useState(15);
  const [isEditMode, setIsEditMode] = useState(false); // New state to track edit mode
  const [OCSetting, setOCSetting] = useState(false); // New state to track edit mode
  const [editingServiceId, setEditingServiceId] = useState(null); // ID of the service being edited
  const [enableBooking, setEnableBooking] = useState(false); // For enabling booking
  const [showHidePL, setShowHidePL] = useState(false); // For enabling booking
  const [availableSlots, setAvailableSlots] = useState(1); // For available slots
  const [EDTimeSlot, setEDTimeSlot] = useState(false); // For available slots
  const [info, setInfo] = useState(0);
  const dispatch = useDispatch();
  const { list, loading, meta, serviceAdditionInfo } = useSelector(
    (state) => state?.services
  );

  const [tab, setTab] = useState(0);

  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    totalPage: meta?.totalPages,
    limit: 10,
    totalData: meta?.totalServices,
  });

  const [currentTableData, setCurrentTableData] = useState([]);
  const searchTabs = useSelector((state) => state?.search);
  const [services, setServices] = useState([]);
  useEffect(() => {
    try {
      if (serviceAdditionInfo) {
        setInfo(serviceAdditionInfo);
        setEDTimeSlot(serviceAdditionInfo.EDTimeSlot);
      }
    } catch (error) {
      notifyError(`Failed to fetch notifications:, ${error}`);
    }
  }, [serviceAdditionInfo]);

  const handleAddOrEditService = () => {
    const newService = {
      serviceName,
      price: parseFloat(servicePrice),
      status: "active",
      estimatedTime: parseFloat(estimatedTime),
      enableBooking,
      availableSlots: parseInt(availableSlots),
    };

    if (!serviceName || !servicePrice || !newService?.estimatedTime) {
      toast.warning("All fields are required");
      return;
    }

    if (isEditMode) {
      dispatch(modifyService({ ...newService, _id: editingServiceId })).then(
        () => {
          dispatch(
            fetchServices({
              currentPage: paginationData.currentPage,
              limit: paginationData.limit,
            })
          );
        }
      );
    } else {
      dispatch(createService(newService)).then(() => {
        setPaginationData((prev) => {
          return { ...prev, totalData: prev.totalData + 1 };
        });
      });
    }

    resetForm();
    setAddServices(false);
  };

  const resetForm = () => {
    setServiceName("");
    setServicePrice("");
    setEstimatedTime(0);
    setEnableBooking(false); // Reset enableBooking
    setAvailableSlots(0); // Reset availableSlots
    setIsEditMode(false);
    setEditingServiceId(null);
  };

  const handleEditService = (service) => {
    setServiceName(service.serviceName);
    setServicePrice(service.price);
    setEstimatedTime(service.estimatedTime);
    setEnableBooking(service.enableBooking); // Set enableBooking
    setAvailableSlots(service.availableSlots); // Set availableSlots
    setEditingServiceId(service._id);
    setIsEditMode(true);
    setAddServices(true);
  };

  useEffect(() => {
    dispatch(setServiceOptionSelected({ isActive: true, searchText: "" }));
    return () => {
      dispatch(setServiceOptionSelected({ isActive: false, searchText: "" }));
    };
  }, [dispatch]);

  useEffect(() => {
    // dispatch(
    //   fetchServices({
    //     currentPage: paginationData.currentPage,
    //     limit: paginationData.limit,
    //   })
    // );

    dispatch(fetchServicesSettingInfo());

    dispatch(fetchAdditionServicesInfo());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      fetchServices({
        currentPage: paginationData.currentPage,
        limit: paginationData.limit,
        search: searchTabs?.searchText,
      })
    );
  }, [
    dispatch,
    paginationData.currentPage,
    paginationData.limit,
    paginationData?.totalData,
    searchTabs?.searchText,
  ]);

  useEffect(() => {
    setPaginationData((prev) => ({
      ...prev,
      totalData: meta?.totalServices,
      totalPage: meta?.totalPages,
    }));
  }, [meta]);

  const handleToggle = (info, type) => {
    let payload;

    if (type == "enableBooking") {
      const newStatus = info?.enableBooking == true ? false : true;

      payload = {
        _id: info?._id,
        enableBooking: newStatus,
      };
    } else {
      const newStatus = info?.status === "active" ? "inactive" : "active";
      payload = {
        _id: info?._id,
        status: newStatus,
      };
    }
    dispatch(modifyService(payload)).then(() => {
      dispatch(
        fetchServices({
          currentPage: paginationData.currentPage,
          limit: paginationData.limit,
          search: searchTabs?.searchText,
        })
      );
    });
  };
  const handleEDAToggle = async () => {
    try {
      const response = await axiosInstance.put(
        "servicesSetting/EDTimeSlotSetting",
        {
          EDTimeSlot: !EDTimeSlot,
        }
      );

      if (response?.status === 200) {
        setEDTimeSlot(response.data.EDTimeSlot);
        notifySuccess("Updated the status");

        // toast.success("Changed the Status");
        // setInfo(response.data.parkingLot);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleDelete = (info) => {
    const payload = {
      _id: info?._id,
    };

    dispatch(deleteServices(payload?._id)).then(() => {
      const cp = Math.ceil(
        Number(paginationData?.totalData - 1) / Number(paginationData?.limit)
      );
      setPaginationData((prev) => {
        return {
          ...prev,
          totalData: prev.totalData - 1,
          currentPage: cp,
        };
      });
    });
  };

  // Pagination controls
  // const handlePageChange = (newPage) => {
  //   console.log(paginationData, newPage, "pah");
  //   if (newPage > 0 && newPage <= paginationData?.totalPage) {
  //     setPaginationData((prev) => {
  //       return { ...prev, currentPage: newPage };
  //     });
  //   }
  // };

  const handlePageChange = (newPageIndex) => {
    // setCurrentPageIndex(newPageIndex);

    // setPagination((prev) => ({ ...prev, pageIndex: newPageIndex }));
    console.log(newPageIndex, paginationData, "newPageIndex");
    setPaginationData((prev) => {
      return { ...prev, currentPage: newPageIndex + 1 };
    });
  };

  const onBack = () => {
    if (tab == 0) {
      setOCSetting(false);
    }
    setTab((count) => (count == 0 ? count : count - 1));
  };

  return (
    <div className="h-[calc(100vh-120px)] overflow-y-auto">
      <div className="flex px-5 h-[80px] w-full py-2 justify-between items-center border-b-[2px] border-lightBorderColor">
        {OCSetting ? (
          <FaArrowLeft
            size={25}
            style={{ cursor: "pointer" }}
            onClick={onBack}
            color="rgb(153 0 0 / var(--tw-bg-opacity))"
          />
        ) : (
          <p className="text-black text-2xl">
            All Services {paginationData?.totalData}
          </p>
        )}
        <div className="flex px-5 gap-8 items-center">
          {OCSetting ? (
            <>
              <div>
                <PrimaryButton
                  onClick={() => setShowHidePL(true)}
                  title={"Edit Slots"}
                />
                {info?.lotSize?.length !== 0 ? null : (
                  <label className="block text-sm font-medium text-gray-700">
                    Parking Slot is required*
                  </label>
                )}
              </div>
              {/* <div>
                <p className="text-black text-lg ">Enable Booking</p>
                <ToggleAButton
                  // onClick={()=>{}}
                  onClick={handleEDAToggle}
                  checked={EDTimeSlot}
                />
              </div> */}
              <div>
                <p className="text-black text-lg ">Time Slot</p>
                <ToggleAButton
                  // onClick={()=>{}}
                  onClick={handleEDAToggle}
                  checked={EDTimeSlot}
                />
              </div>
            </>
          ) : (
            <>
              <PrimaryButton
                onClick={() => setAddServices(true)}
                title={"Add Services"}
              />
              <Tooltip content={"Service Settings"}>
                <IoSettings
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (list?.length !== 0) setOCSetting(true);
                  }}
                  size={50}
                  color={
                    list?.length !== 0
                      ? "rgb(153 0 0 / var(--tw-bg-opacity))"
                      : "grey"
                  }
                />
              </Tooltip>
            </>
          )}
        </div>
      </div>
      <div className="flex px-5 h-[calc(70vh)] relative">
        <div className="w-full my-2 overflow-y-auto">
          {OCSetting ? (
            <ServiceSettingIndex tab={tab} setTab={setTab} />
          ) : (
            <>
              <ServiceTable
                services={list}
                setFormData={setServiceName}
                onToggle={handleToggle}
                onEdit={handleEditService}
                onDelete={handleDelete}
                searchText={searchTabs?.searchText}
                totalServices={paginationData?.totalData}
              />

              <div className="mt-auto absolute bottom-0 bg-white p-4 min-w-[95%]">
                {/* Reusable Pagination Component */}
                <Pagination
                  currentPageIndex={paginationData?.currentPage - 1}
                  totalPages={paginationData?.totalPage}
                  handlePageChange={handlePageChange}
                  canNextPage={
                    paginationData?.currentPage != paginationData?.totalPage
                  }
                  canPreviousPage={paginationData?.currentPage > 1}
                />
              </div>
              {/* Pagination Controls */}
              {/* <button
                  onClick={() =>
                    handlePageChange(paginationData?.currentPage - 1)
                  }
                  disabled={paginationData?.currentPage === 1}
                  className="px-4 py-2 bg-gray-300 disabled:bg-gray-200"
                >
                  Previous
                </button>
                <span>
                  Page {paginationData?.currentPage} of{" "}
                  {paginationData?.totalPage}
                </span>
                <button
                  onClick={() =>
                    handlePageChange(paginationData?.currentPage + 1)
                  }
                  disabled={
                    paginationData?.currentPage === paginationData?.totalPage
                  }
                  className="px-4 py-2 bg-gray-300 disabled:bg-gray-200"
                >
                  Next
                </button> */}
            </>
          )}
        </div>
      </div>
      <ParkingLotModal
        info={info}
        setInfo={setInfo}
        setShowHidePL={setShowHidePL}
        showHidePL={showHidePL}
      />
      <Dialog
        open={addServices}
        onClose={() => {
          resetForm();
          setAddServices(false);
        }}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/80 flex w-screen items-center justify-center p-4">
          <Dialog.Panel className="w-full flex justify-center rounded">
            <div className="h-auto w-[400px] xl:w-[500px] relative rounded-3xl bg-white px-5 py-6">
              <div className="">
                <div className="p-2">
                  <img
                    src={icons8crossFilled}
                    onClick={() => {
                      resetForm();
                      setAddServices(false);
                    }}
                    className="w-8 h-8 absolute flex justify-center items-center right-2 top-2 rounded-full cursor-pointer"
                  />
                </div>
                <div className="pt-2 px-3">
                  <div className="p-5 flex flex-col gap-5">
                    <div className="relative my-2">
                      <label className="mb-2 text-base font-medium">
                        Service Name
                      </label>
                      <input
                        className=" border-[1px] w-full h-[49px] mt-1 transition bg-white ease-in-out delay-150 border-[#ccc] truncate leading-[19.07px] text-gray-600 text-lg py-[8px] px-2 lg:pl-3 placeholder:text-base  rounded-md  focus:outline-none focus:border-blue-300"
                        // className="border-[1px] w-full h-[50px] mt-1 transition bg-white ease-in-out delay-150 border-[#ccc] truncate leading-[19.07px] text-gray-600 pr-[50px] text-lg py-[8px] px-2 lg:pl-3 rounded-md focus:outline-none focus:border-blue-300"
                        type="text"
                        value={serviceName}
                        // placeholder="Service Name"
                        onChange={(e) => setServiceName(e.target.value)}
                      />
                      <div className="absolute right-4 sm:right-5 bottom-3 sm:bottom-5">
                        <img src={serviceIcon} className="h-5 w-5" alt="" />
                      </div>
                    </div>
                    <div className="relative my-2">
                      <label className="mb-2 text-base font-medium">
                        Service Price
                      </label>
                      <input
                        className=" border-[1px] w-full h-[49px] mt-1 transition bg-white ease-in-out delay-150 border-[#ccc] truncate leading-[19.07px] text-gray-600 text-lg py-[8px] px-2 lg:pl-3 placeholder:text-base  rounded-md  focus:outline-none focus:border-blue-300"
                        // className="border-[1px] w-full h-[50px] mt-1 transition bg-white ease-in-out delay-150 border-[#ccc] truncate leading-[19.07px] text-gray-600 text-lg py-[8px] px-2 lg:pl-3 rounded-md focus:outline-none focus:border-blue-300"
                        type="number"
                        value={servicePrice}
                        // placeholder="Service Price"
                        onChange={(e) => setServicePrice(e.target.value)}
                        min={0}
                        step={1}
                        onKeyDown={(e) => {
                          if (e.code === "Minus") {
                            e.preventDefault();
                          }
                        }}
                      />
                      <div className="absolute right-4 sm:right-5 bottom-3 sm:bottom-5">
                        <img src={priceIcon} className="h-5 w-5" alt="" />
                      </div>
                    </div>
                    <div className="my-2">
                      <label className="mb-2 text-base font-medium">
                        Estimated time (min)
                      </label>
                      <input
                        className="border-[1px] w-full h-[49px] mt-1 transition bg-white ease-in-out delay-150 border-[#ccc] truncate leading-[19.07px] text-gray-600 text-lg py-[8px] px-2 lg:pl-3 placeholder:text-base  rounded-md  focus:outline-none focus:border-blue-300"
                        type="number"
                        value={estimatedTime}
                        step={15}
                        // placeholder="Estimated time (min)"
                        onChange={(e) =>
                          setEstimatedTime(
                            e.target.value >= 15 ? e.target.value : 15
                          )
                        }
                      />
                    </div>
                    <div className="relative my-0">
                      <div className="flex justify-between">
                        <label className="mb-1 text-base font-medium">
                          Enable for Booking
                        </label>
                        <input
                          type="checkbox"
                          checked={enableBooking}
                          onChange={(e) => setEnableBooking(e.target.checked)}
                          className="mt-1 block w-6 h-6 text-blue-600 border-gray-300 rounded focus:ring-blue-500 focus:border-blue-500"
                        />
                      </div>
                    </div>
                    {/* <div>
                      <div className="relative w-full">
                        <label className="mb-1 text-base font-medium">
                          Available Slots
                        </label>
                        <input
                          className="border-[1px] w-full h-[49px] mt-1 transition bg-white ease-in-out delay-150 border-[#ccc] truncate leading-[19.07px] text-gray-600 text-lg py-[8px] px-2 sm:px-3 lg:pl-3 placeholder:text-base rounded-md focus:outline-none focus:border-blue-300"
                          type="number"
                          value={availableSlots}
                          // placeholder="Available Slots"
                          onChange={(e) =>
                            setAvailableSlots(
                              e.target.value >= 1 ? e.target.value : 1
                            )
                          }
                        />
                        <div className="absolute right-4 sm:right-5 bottom-3 sm:bottom-5">
                          <IoTime size={20} />
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <div className="flex justify-center py-3">
                    <PrimaryButton
                      width="150px"
                      title={isEditMode ? "Update Service" : "Add Service"} // Update button title based on mode
                      onClick={handleAddOrEditService}
                      loading={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </div>
  );
};
