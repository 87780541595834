import React, { useContext, useEffect, useState } from "react";
import {
  HeaderText,
  ServiceSettingContext,
} from "../ServiceSettingIndex";
import PrimaryButton from "../../../utils/buttons/primaryButton";
import { useSelector } from "react-redux";
import { MdOutlineCalendarMonth } from "react-icons/md";

const ServiceListComponent = () => {
  const {
    dateValue,
    services,
    // isLoading,
    onDateChange,
    setServices,
    setAvailableWorkingDays,
    handleSingleUpdate,
    compareTwoDate,
    currentMonth,
    handleMonthDateUpdate,
    compareMonthAndYear,
    CustomDatePicker
  } = useContext(ServiceSettingContext);
  const { list, loading, serviceSettingInfo } = useSelector(
    (state) => state?.services
  );
  const [CUState, setCUState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const tempService = [];
    try {
      if (list) {
        // let allServices = null;
        let dateData = null;
        if (serviceSettingInfo) {
          const { serviceDateSettings } = serviceSettingInfo;

          if (serviceSettingInfo) {
            const { serviceMonthSetting } = serviceSettingInfo;

            const tempMonth = serviceMonthSetting?.find(({ monthYearDate }) => {
              return compareMonthAndYear(monthYearDate, currentMonth);
            });

            if (tempMonth) {
              dateData = tempMonth?.serviceDateSettings?.find(({ date }) => {
                return compareTwoDate(date, dateValue);
              });
              console.log("tempMonth", tempMonth);
            }
          }
        }

        list.forEach((element) => {
          if (element.status === "inactive") {
            return;
          }
          const body = {
            serviceName: element.serviceName,
            status: false,
            service: element._id,
          };
          if (dateData) {
            const state = dateData.services.find(
              ({ service }) => service === element._id
            );
            body.status = state?.status ? state?.status : false;
          }

          tempService.push(body);
        });
      }

      setServices(tempService);
    } catch (error) {
      console.log(error);
    }
  }, [list, dateValue, serviceSettingInfo, currentMonth]);

  useEffect(() => {
    if (services) {
      setAvailableWorkingDays((state) => {
        const newState = state.map((item) => {
          if (compareTwoDate(item.date, dateValue)) {
            item.services = services;
            return item;
          }
          return item;
        });
        // console.log(newState);
        return newState;
      });
    }
  }, [services, setAvailableWorkingDays]);

  const onCheck = (checkAll = true) => {
    if (checkAll) {
      setServices((state) => state.map((item) => ({ ...item, status: true })));
    } else {
      setServices((state) => state.map((item) => ({ ...item, status: false })));
    }
  };

  return (
    <div className=" flex flex-col gap-5  border  border-black py-5  rounded-lg px-10">
      <div className="relative flex justify-between">
        <div className=" flex gap-3 flex-col">
          <HeaderText text={"Service Setting"} />
          {/* <span className="text-2xl">Day Service Setting</span> */}
          {CustomDatePicker()}
        </div>
        <div className="flex gap-2 border py-0 border-gray-400 items-center px-5 flex-row justify-between max-h-14">
          <input
            type="checkbox"
            className="w-6 h-6"
            checked={CUState}
            onChange={(e) => {
              onCheck(!CUState);
              setCUState((state) => !state);
            }}
          />
          <label className="block text-lg text-gray-700 font-bold">
            Select All
          </label>
        </div>
        {/* </div> */}
      </div>
      <div className="element  overflow-y-auto flex flex-col gap-2 min-h-40 max-h-56">
        {services?.map(({ serviceName }, index) => (
          <ServiceCheckBoxComponent
            key={index}
            index={index}
            title={serviceName}
          />
        ))}
      </div>
      <div className="flex  gap-2 w-96 justify-start">
        <PrimaryButton
          width="140px"
          className="m-auto"
          title={"Update Service"}
          onClick={async () => {
            setIsLoading(true);
            try {
              await handleSingleUpdate({
                monthYearDate: new Date(currentMonth),
                services,
                currentDate: dateValue,
              });
            } catch (err) {
              console.log(err);
            } finally {
              setIsLoading(false);
            }
          }}
          loading={isLoading}
        />
        <PrimaryButton
          width="120px"
          className="m-auto"
          title={"Update All"}
          onClick={async () => {
            setIsLoading(true);
            try {
              await handleMonthDateUpdate({
                monthYearDate: new Date(currentMonth),
                services,
              });
            } catch (err) {
              console.log(err);
            } finally {
              setIsLoading(false);
            }
          }}
          // onClick={handleSingleUpdate}
          loading={isLoading}
        />
      </div>
    </div>
  );
};

const ServiceCheckBoxComponent = ({ title, index }) => {
  const { services, setServices } = useContext(ServiceSettingContext);

  return (
    <div className="relative ">
      <div className="flex  flex-row gap-4 items-center ">
        <input
          type="checkbox"
          checked={services[index].status}
          onChange={(e) => {
            setServices((prevState) => {
              const updatedServices = [...prevState]; // Create a copy of the services array
              updatedServices[index] = {
                ...updatedServices[index],
                status: e.target.checked, // Toggle the checkbox state
              };

              // console.log("Updated service:", updatedServices[index]);

              return updatedServices; // Return the updated services array
            });
          }}
          className="cursor-pointer  block w-6 h-6 text-blue-600 focus:ring-blue-500 focus:border-blue-500"
        />
        <label className="block text-lg font-bold text-gray-700">{title}</label>
      </div>
    </div>
  );
};
export default ServiceListComponent;
