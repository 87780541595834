import React from "react";

interface InputFieldInterface {
  onChange?: any;
  title?: any;
  value?: string | number;
  placeholder?: string;
  type?: string;
  label?: string;
  name?:string
}

const InputField = ({
  onChange,
  title,
  value,
  placeholder,
  type,
  label,
  name
}: InputFieldInterface) => {
  return (
    <div className="flex flex-col gap-1">
      <div className="">{label}</div>
      <input
        className=" border-[1px] w-full h-[50px] mt-1 transition bg-white ease-in-out delay-150 border-[#ccc] truncate leading-[19.07px] text-gray-600 text-lg py-[8px] px-2 lg:pl-3 placeholder:text-base  rounded-md  focus:outline-none focus:border-blue-300"
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  );const InputField = ({ type, title, name, placeholder, value, onChange }) => {
    return (
      <div className="flex flex-col">
        <label htmlFor={name} className="mb-2 text-sm font-medium">{title}</label>
        <input
          type={type}
          id={name}
          name={name}
          placeholder={placeholder}
          value={value || ''} // Ensure it is a string
          onChange={onChange}
          className="p-2 border rounded"
        />
      </div>
    );
  };
};

export default InputField;
