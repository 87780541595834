import React, { useEffect, useMemo, useState } from "react";
import {
  createColumnHelper,
  useReactTable,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsedCarsEnquiries } from "../../redux/usedCarsEnquiries/usedCarsEnquiriesSlice";
import {
  disabledLeftIcon,
  disabledRightIcon,
  leftIcon,
  rightIcon,
} from "../../assets";
import { AiOutlineLoading } from "react-icons/ai";
import { Tooltip } from "flowbite-react";
import Pagination from "../Common/Pagination";

export const UsedCarsEnquiriesTabel = () => {
  const dispatch = useDispatch();
  const { enquiries, status, error, meta } = useSelector(
    (state) => state.usedCarsEnquiries
  );

  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [pagination, setPagination] = useState({
    pageIndex: currentPageIndex,
    pageSize: 10,
  });

  // Handle page change
  const handlePageChange = (newPageIndex) => {
    setCurrentPageIndex(newPageIndex);
    setPagination((prev) => ({ ...prev, pageIndex: newPageIndex }));
  };

  // Fetch enquiries when component mounts or pagination changes
  useEffect(() => {
    dispatch(
      fetchUsedCarsEnquiries({
        page: pagination.pageIndex + 1,
        limit: pagination.pageSize,
      })
    );
    console.log(meta, "meta");
  }, [dispatch, pagination]);

  // Handle API response data and map it to the table's format
  const apiData = enquiries?.map((enquiry) => ({
    _id: enquiry?._id,
    name: enquiry?.userDetails?.name,
    email: enquiry?.userDetails?.email,
    phone: enquiry?.userDetails?.phone,
    message: enquiry?.userDetails?.message,
    carModel: `${enquiry?.car?.overview?.carName} ${enquiry?.car?.overview?.carModel}`,
    status: enquiry?.status,
  }));

  const columnHelper = createColumnHelper();

  const columns = useMemo(
    () => [
      columnHelper.accessor("_id", {
        cell: (info) => info.getValue(),
        header: "ID",
      }),
      columnHelper.accessor("name", {
        cell: (info) => <i>{info.getValue()}</i>,
        header: "Name",
      }),
      columnHelper.accessor("email", {
        cell: (info) => <i>{info.getValue()}</i>,
        header: "Email",
      }),
      columnHelper.accessor("phone", {
        cell: (info) => <i>{info.getValue()}</i>,
        header: "Phone",
      }),
      columnHelper.accessor("message", {
        cell: (info) => (
          <Tooltip content={info.getValue()} placement="top">
            <div
              className="text-center overflow-hidden text-ellipsis whitespace-nowrap m-auto"
              style={{ maxWidth: "200px" }}
            >
              {info.getValue()}
            </div>
          </Tooltip>
        ),
        header: "Message",
      }),
      columnHelper.accessor("carModel", {
        cell: (info) => <i>{info.getValue()}</i>,
        header: "Car Model",
      }),
      // columnHelper.accessor("status", {
      //   cell: (info) => <span>{info.getValue()}</span>,
      //   header: "Status",
      // }),
    ],
    []
  );

  const table = useReactTable({
    data: apiData,
    columns,
    state: { pagination },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true, // Enable manual pagination for server-side data
    pageCount: meta?.totalPages, // Use totalPages from the backend
  });

  // if (status == "failed") {
  //   return (
  //     <div className="h-[calc(100vh-50px)] text-center">
  //       <p>Error: {error}</p>;
  //     </div>
  //   );
  // }

  return (
    <div className="flex flex-col justify-between w-full p-4 h-[calc(100vh-50px)]">
      {/* Header */}
      <div className="flex px-2 py-2 h-[80px] justify-between items-center border-b-[2px] border-lightBorderColor">
        <p className="text-black text-2xl">All Used Cars Enquiries</p>
      </div>

      {/* Table Content */}
      <div className="flex-grow overflow-auto">
        {status == "loading" ? (
          <div className="text-center p-2 h-[90%] flex justify-center items-center">
            <AiOutlineLoading className="h-12 w-12 animate-spin" />
          </div>
        ) : enquiries?.length === 0 ? (
          <div className="text-center p-2 h-[90%] flex justify-center items-center">
            <p className="text-black text-2xl">No enquiries yet</p>
          </div>
        ) : (
          <table className="w-full border-borderColor border-y-[1px] text-center">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      className="border-borderColor font-semibold text-base border-x-[1px] py-2 px-1"
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className="border-borderColor text-base font-medium border-[1px] px-1 py-2 m-auto"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      <div className="w-full mt-auto sticky bottom-0 bg-white p-4">
        {/* Reusable Pagination Component */}
        <Pagination
          currentPageIndex={currentPageIndex}
          totalPages={meta?.totalPages}
          handlePageChange={handlePageChange}
          canPreviousPage={table.getCanPreviousPage()}
          canNextPage={table.getCanNextPage()}
        />
      </div>
    </div>
  );
};
