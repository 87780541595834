import { BarChart } from "./BarChart";

const days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const BarCharts = ({ data }) => {
  console.log(data);
  const chartData = {
    labels: data?.map((item) => item.day),
    datasets: [
      {
        label: "Appointments",
        data: data?.map((item) => item.appointments),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
      },
      {
        label: "Cancelled",
        data: data?.map((item) => item.cancelled),
        backgroundColor: "rgba(255, 99, 132, 0.6)",
      },
    ],
  };
  return (
    <div className="">
      <main className="chart-container">
        <BarChart
          data_1={chartData.datasets[0].data}
          data_2={chartData.datasets[1].data}
          title_1="Accepted Orders"
          title_2="Cancelled Orders"
          bgColor_1={`#252525`}
          bgColor_2={`#E3E3E3`}
          labels={chartData?.labels}
        />
      </main>
    </div>
  );
};

export default BarCharts;
