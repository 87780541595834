import React, { useMemo } from "react";
import { calenderIcon, statIcon } from "../../assets";
import { useState, useEffect } from "react";
import BarCharts from "./BarCharts";
import { stats } from "../../utility/api";
import axiosInstance from "../../utility/axiosInstance";
import PieCharts from "./PieCharts";
import useSocket from "../../hooks/useSocket";
import { UsedCarPieChart } from "./UsedCarsPieChart";

const appointmentData = {
  totalAppointments: 50,
  cancelledAppointments: 5,
  appointmentsByWeek: [
    { day: "Monday", appointments: 10, cancelled: 1 },
    { day: "Tuesday", appointments: 8, cancelled: 1 },
    { day: "Wednesday", appointments: 7, cancelled: 0 },
    { day: "Thursday", appointments: 6, cancelled: 1 },
    { day: "Friday", appointments: 9, cancelled: 1 },
    { day: "Saturday", appointments: 5, cancelled: 1 },
    { day: "Sunday", appointments: 5, cancelled: 0 },
  ],
};

export const AppointmentStatistics = () => {
  const [timePeriod, setTimePeriod] = useState("week");
  const [timePeriodForPie, setTimePeriodForPie] = useState("week");
  const [pieData, setPieData] = useState([]);

  const [showUsedCarPieChart, setShowUsedCarPieChart] = useState(false); // Step 1: State to control visibility

  const [totalAppointments, setTotalAppointments] = useState(0);
  const [totalCancelled, setTotalCancelled] = useState(0);

  const [data, setData] = useState(appointmentData);

  const [cache, setCache] = useState({});
  const [cacheTime, setCacheTime] = useState({});

  const [dataIncludesPeriod, setDataIncludesPeriod] = useState();

  const handleTimePeriodChange = (e) => {
    setTimePeriod(e.target.value);
  };

  const handleTimePeriodChange2 = (e) => {
    setTimePeriodForPie(e.target.value);
  };

  const toggleShowMore = () => {
    setShowUsedCarPieChart(!showUsedCarPieChart); // Step 2: Toggle visibility on button click
  };

  const fetchData = async (period, forceUpdate = false, type = "data") => {

    let statsUrl;

    let serviceStatsUrl, orderStatsUrl;

    switch (period) {
      case "week":
        serviceStatsUrl = stats.serviceStatsByWeek;
        orderStatsUrl = stats.orderStatsByWeek;
        break;
      case "month":
        serviceStatsUrl = stats.serviceStatsByMonth;
        orderStatsUrl = stats.orderStatsByMonth;
        break;
      case "year":
        serviceStatsUrl = stats.serviceStatsByYear;
        orderStatsUrl = stats.orderStatsByYear;
        break;
      default:
        return;
    }
    console.log(serviceStatsUrl);
    if (!forceUpdate && cache[period]) {
      if (type == "pieData") {
        setPieData(cache[period]);
      } else {
        setData(cache[period]);
      }
      return;
    }

    try {
      const [serviceResponse, orderResponse] = await Promise.all([
        axiosInstance.get(serviceStatsUrl),
        axiosInstance.get(orderStatsUrl),
      ]);
  
      if (serviceResponse) {
        const finalData =
          serviceResponse?.data?.week ||
          serviceResponse?.data?.month ||
          serviceResponse?.data?.year;
        const finalData2 =
          orderResponse?.data?.week ||
          orderResponse?.data?.month ||
          orderResponse?.data?.year;

        if (type == "pieData") {
          setPieData(finalData2);
        } else {
          setData(finalData);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const mergeData = (serviceData, orderData) => {
    const mergedData = serviceData?.map((serviceItem, index) => {
      const orderItem = orderData[index];
      return {
        day: serviceItem?.day,
        appointments: serviceItem?.appointments + orderItem?.appointments,
        cancelled: serviceItem?.cancelled + orderItem?.cancelled,
      };
    });
    return mergedData;
  };

  useEffect(() => {
    // const CACHE_VALIDITY_PERIOD = 2 * 60 * 1000;
    // const isCacheValid =
    //   cacheTime[timePeriod] &&
    //   Date.now() - cacheTime[timePeriod] < CACHE_VALIDITY_PERIOD;

    // if (!isCacheValid) {
    // } else {
    //   fetchData(timePeriod);
    // }
    fetchData(timePeriod, true, "data");
  }, [timePeriod, cache, cacheTime]);

  useEffect(() => {
    fetchData(timePeriodForPie, false, "pieData");
  }, [timePeriodForPie]);


  useSocket(null, (notification) => {
    fetchData(timePeriod, true, "data");
    fetchData(timePeriod, true, "pieData");
  });

  return (
    <div className="grid grid-cols-1 xl:grid-cols-2 mt-4 gap-10 mx-5 bg-white">
      <div className="min-w-[100%] shadow-xl rounded-xl flex flex-col">
        <div className="flex justify-between pt-2 px-2">
          <div className="flex items-center gap-3 text-2xl font-medium">
            <img src={statIcon} alt="" /> Appointment Statistics
          </div>
          <div className="flex flex-col gap-3 relative">
            <select
              className="border-borderColor cursor-pointer rounded-lg pl-8 border-[2px] focus:outline-none py-2 px-3"
              // name="cars"
              // id="cars"

              name="timePeriod"
              id="timePeriod"
              value={timePeriod}
              onChange={handleTimePeriodChange}
            >
              <option value="week">This Week</option>
              <option value="month">Month</option>
              <option value="year">Year</option>
            </select>
            <div className="pointer-events-none absolute left-0 top-[7px] flex items-center px-2 text-gray-700">
              <img className="h-[20px]" src={calenderIcon} alt="" />
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center px-2 my-3">
          <div className="flex gap-5 text-textSecondary">
            <div className="border-[2px] rounded-md border-lightBorderColor text-lg px-3 py-1">
              Total Appointment
              <p className="flex justify-center font-semibold text-textPrimaryDark">
                {data.totalAppointments}
              </p>
            </div>
            <div className="border-[2px] rounded-md border-lightBorderColor text-lg px-3 py-1">
              Cancelled Appointment
              <p className="flex justify-center font-semibold text-textPrimaryDark">
                {data.cancelledAppointments}
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-3 mt-4 mr-3">
            <div className="flex gap-2 items-center">
              <div className="h-[10px] w-[10px] bg-textPrimaryDark"></div>{" "}
              Appointments
            </div>
            <div className="flex gap-2 items-center">
              <div className="h-[10px] w-[10px] bg-textSecondary"></div>
              Cancelled
            </div>
          </div>
        </div>
        <div className="h-full justify-end">
          <BarCharts
            data={data.appointmentsByWeek || data.appointmentsByMonths}
          />
        </div>
      </div>
{/* 
      <div className="h-[400px] shadow-xl rounded-xl pt-2 px-2 min-w-[100%]">
        <div className="flex justify-between">
          <div className="flex items-center gap-3 text-2xl font-medium">
            <img src={statIcon} alt="" /> Orders
          </div>
          <div className="flex flex-col gap-3 relative">
            <select
              className="border-borderColor cursor-pointer rounded-lg pl-8 border-[2px] focus:outline-none py-2 px-3"
              // name="cars"
              // id="cars"

              name="timePeriodForPie"
              id="timePeriodForPie"
              value={timePeriodForPie}
              onChange={handleTimePeriodChange2}
            >
              <option value="week">This Week</option>
              <option value="month">Month</option>
              <option value="year">Year</option>
            </select>
            <div className="pointer-events-none absolute left-0 top-[7px] flex items-center px-2 text-gray-700">
              <img className="h-[20px]" src={calenderIcon} alt="" />
            </div>
          </div>
        </div>
        <div className="mt-[-100px] flex justify-center">
          <PieCharts
            data={pieData?.appointmentsByWeek || pieData?.appointmentsByMonths}
          />
        </div>
      </div> */}

      {/* <div className="mt-0">
        <p onClick={toggleShowMore} className="text-gray-500 cursor-pointer">
          {showUsedCarPieChart ? "Hide Used Car Chart" : "Show More"}
        </p>
      </div> */}
      {/* <div></div> */}
      {<UsedCarPieChart />}
    </div>
  );
};
