import React, { useContext, useState } from "react";
import { HeaderText } from "../../Service/ServiceSetting/ServiceSettingIndex";
import { useSelector } from "react-redux";
import { UsedCarSettingContext } from "./UsedCarSettingIndex";

export default function CarListView() {
  const { setSelectedCar,setSelectedCars, selectedCars, carList } = useContext(
    UsedCarSettingContext
  );
  const onCheck = (checkAll = true) => {
    setSelectedCar(null);
    if (checkAll) {
      // Select all cars
      setSelectedCars(carList.map((car) => car._id)); // Assuming _id is the unique identifier
    } else {
      // Deselect all cars
      setSelectedCars([]);
    }
  };

  const [CUState, setCUState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  return (
    <div className=" flex flex-col gap-5  border  border-black py-5  rounded-lg px-10">
      <div className="relative flex flex-col gap-5">
        <div className="relative flex justify-between">
          <HeaderText text={"Car List"} />
        </div>
        <div className="flex gap-2 border py-2 border-gray-400 items-center px-5 flex-row  max-h-14 max-w-40">
          <input
            type="checkbox"
            className="w-6 h-6"
            checked={CUState}
            onChange={(e) => {
              onCheck(!CUState);
              setCUState((state) => !state);
            }}
          />
          <label className="block text-lg text-gray-700 font-bold">
            Select All
          </label>
        </div>
      </div>
      <div className="element  overflow-y-auto flex flex-col gap-2 min-h-40 max-h-56">
        {carList?.map((car, index) => (
          <UsedCarCheckBoxComponent key={index} index={index} data={car} />
        ))}
      </div>
    </div>
  );
}
const UsedCarCheckBoxComponent = ({ data, index }) => {
  const {
    _id,
    images,
    overview: { carName, carModel, engineType, makeYear },
  } = data;
  const { setSelectedCars, selectedCars,selectedCar, setSelectedCar } = useContext(UsedCarSettingContext);

  const exist = selectedCars.includes(_id);

  const handleCheckboxChange = () => {
    setSelectedCar(null)
    setSelectedCars((prevState) => {
      if (exist) {
        return prevState.filter((id) => id !== _id);
      }
      return [...prevState, _id];
    });
  };
  const handleRadioBoxChange = () => {
    setSelectedCars([])
    setSelectedCar((prevState) => {
    
      if (prevState==_id) {
        return null
      }
      return _id;
    });
  };
  
  return (
    <div className="relative bg-white rounded-md shadow-sm p-3 mb-2 border border-gray-200 flex gap-3 w-[30rem]">
 
      {images?.front && (
        <div className="w-32 h-32 flex-shrink-0">
          <img
            src={process.env.REACT_APP_API_SOCKET + "/" + images?.front}
            alt={carName}
            className="w-full h-full object-contain rounded-md"
          />
        </div>
      )}
      <div className="w-[1px] bg-gray-400 mx-5" />
      <div className="flex flex-col justify-evenly w-full">
        <div className="flex justify-between items-center">
          {/* Checkbox and Car Name */}
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={exist}
              onChange={handleCheckboxChange}
              className="cursor-pointer w-4 h-4 text-blue-600 focus:ring-blue-500 focus:border-blue-500"
            />
            <label className="text-sm font-bold text-gray-700">{carName}</label>
          </div>
          {/* <div className="flex items-center gap-2">
            <input
              type="radio"
              checked={selectedCar==_id}
              onClick={handleRadioBoxChange}
              className="cursor-pointer w-4 h-4 text-blue-600 focus:ring-blue-500 focus:border-blue-500"
            />
            <label className="text-sm font-bold text-gray-700">Edit</label>
          </div> */}
        </div>


        <div className="flex gap-5 flex-wrap text-base">
          <div>
            <span className="font-bold text-gray-600 text-sm">Model:</span>
            <p className="text-gray-700">{carModel}</p>
          </div>
          <div>
            <span className="font-bold text-gray-600 text-sm">Engine:</span>
            <p className="text-gray-700">{engineType}</p>
          </div>
          <div>
            <span className="font-bold text-gray-600 text-sm">Year:</span>
            <p className="text-gray-700">{makeYear}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
