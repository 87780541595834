import React, { useContext, useEffect, useRef, useState } from "react";
import { HeaderText, ServiceSettingContext } from "../../Service/ServiceSetting/ServiceSettingIndex";
import PrimaryButton from "../../utils/buttons/primaryButton";
import { MdOutlineCalendarMonth } from "react-icons/md";

const TimeSlotComponent = ({
  SettingContextData,
  handleMonthDateUpdate,
  handleSingleUpdate,
}) => {
  const {
    workingDayTime,
    setAvailableWorkingDays,
    dateValue,
    availableWorkingDays,
    setWorkingDayTime,
    setTempTimeSave,
    compareTwoDate,
    CustomDatePicker,
  } = SettingContextData;
  const [isLoading, setIsLoading] = useState(false);

  // console.log("workingDayTime", workingDayTime);
  return (
    <div className="flex flex-col flex-wrap gap-6 border border-black px-10 py-10 rounded-lg">
      <div className="flex flex-col gap-9  ">
        <div className="flex flex-col  gap-6">
          <HeaderText text={"Time Setting"} />
          {/* <span className="text-2xl text-center font-bold ">Time Settings</span> */}
          {CustomDatePicker()}
        </div>
        <div
          className="bg-[#e5e7eb] p-[15px] rounded-[12px] border-none shadow-md w-full flex gap-8 flex-wrap overflow-y-auto 
       max-h-[25.2rem]"
        >
          {workingDayTime?.map(({ time, status }, i) => (
            <span
              key={i}
              onClick={() => {
                const find = availableWorkingDays.find(({ date }) => {
                  return compareTwoDate(date, dateValue);
                });

                if (find) {
                  find.timeSlots.map((item) => {
                    if (item.time === time) {
                      item.status = !item.status;
                    }
                    return item;
                  });

                  setAvailableWorkingDays((state) => {
                    const newState = state.map((item) => {
                      if (item.date === find.date) {
                        return find;
                      }
                      return item;
                    });
                    return newState;
                  });
                  setWorkingDayTime(() => [...find.timeSlots]);

                  setTempTimeSave((prevState) => {
                    const existIndex = prevState.findIndex(({ date }) =>
                      compareTwoDate(date, dateValue)
                    );
                    if (existIndex >= 0) {
                      const updatedState = [...prevState];
                      updatedState[existIndex] = {
                        ...updatedState[existIndex],
                        timeSlots: find.timeSlots,
                      };
                      return updatedState;
                    }

                    return [
                      ...prevState,
                      { ...find, date: dateValue, timeSlots: find.timeSlots },
                    ];
                  });
                }
              }}
              className={`color-[#111827] cursor-pointer text-lg p-[7px] text-center rounded-[8px] `}
              style={{ backgroundColor: status ? "rgb(84 207 84)" : "#d1d5db" }}
            >
              {time}
            </span>
          ))}
        </div>
      </div>

      <div className="flex justify-start gap-4">
        <PrimaryButton
          width="130px"
          title={"Update Time"}
          // onClick={handleUpdate}
          onClick={async () => {
            setIsLoading(true);
            try {
              await handleSingleUpdate();
            } catch (err) {
              console.log(err);
            } finally {
              setIsLoading(false);
            }
          }}
          loading={isLoading}
        />

        <PrimaryButton
          width="120px"
          title={"Update All"}
          onClick={async () => {
            setIsLoading(true);
            try {
              await handleMonthDateUpdate();
            } catch (err) {
              console.log(err);
            } finally {
              setIsLoading(false);
            }
          }}
          // onClick={handleUpdate}
          loading={isLoading}
        />
      </div>
    </div>
  );
};

export default TimeSlotComponent;
